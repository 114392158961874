import { entityDescriptors } from "@crispico/foundation-react/entity_crud/entityCrudConstants";
import { ReduxReusableComponents, RRCProps } from "@crispico/foundation-react/reduxReusableComponents/ReduxReusableComponents";
import _ from "lodash";
import React from "react";
import { Button, Dropdown, DropdownItemProps } from "semantic-ui-react";
import { AbstractGantt, AbstractGanttProps, AbstractGanttReducers, AbstractGanttState, GanttData, GanttGroup } from "./AbstractGantt";
import { getLayerStyle } from "./ganttLayerStyles";
import { humanResourceScheduleEntityDescriptor, missionEntityDescriptor } from "AppEntityDescriptors";
import { GanttUtils } from "./GanttUtils";
import { GanttMessages, GanttMessagesRRC } from "./components/GanttMessages";
import { equipmentResourceEntityDescriptor } from "pages/EquipmentResource/equipmentResourceEntityDescriptor";
import { ColumnDefinition, EntityDescriptor } from "@crispico/foundation-react";
import { MissionTotalTimeFieldDescriptor } from "pages/CommonFieldDescriptors";
import { FieldType } from "@crispico/foundation-react/entity_crud/FieldType";

const NONE_ENTITY_NAME = "None";
const NONE_ID = 0;
const NONE_ENTITY_UID = GanttUtils.toEntityUid(NONE_ENTITY_NAME, NONE_ID);

export enum Sorting {
    MISSIONS_TOTAL_TIME = "MISSIONS_TOTAL_TIME",
    SHEDULE_START_AND_END_TIME = "SHEDULE_START_AND_END_TIME"
}

export interface GanttResourcesOptions {
    sortBy: Sorting,
    groupBy: "HumanResourceSchedule" | "EquipmentResource",
    hideGroupBy: boolean,
    tableFields?: string[],
    showMessageButton: boolean
}

class GanttResourcesState extends AbstractGanttState {
    selectedGroupBy: "HumanResourceSchedule" | "EquipmentResource" | typeof NONE_ENTITY_NAME = NONE_ENTITY_NAME;
    selectedSortBy: Sorting = Sorting.MISSIONS_TOTAL_TIME;
    showMessageButton: boolean = true;
}
class GanttResourcesReducers<S extends GanttResourcesState = GanttResourcesState> extends AbstractGanttReducers<S> { }

type GanttResourcesProps = AbstractGanttProps & { hideGroupBy?: boolean, hideSortBy?: boolean };
type Props = RRCProps<GanttResourcesState, GanttResourcesReducers> & GanttResourcesProps;

export class GanttResources extends AbstractGantt<GanttResourcesProps, GanttResourcesReducers, GanttResourcesState> {

    private ganttMessagesRef = React.createRef<GanttMessages>();

    constructor(props: Props) {
        super(props);
    }

    public getResourcesWithTheLeastTimeUsed(endNumber: number): number[] {
        return this.props.s.data.groups.filter((g: GanttGroup) => g.visible && g.entityId).slice(endNumber).map(g => g.entityId);
    }

    private getPendingMissions(entities: { [entityName: string]: { [id: number]: any } }) {
        let missions = entities?.["Mission2"] ? Object.keys(entities?.["Mission2"]).map(key => GanttUtils.findByUid(GanttUtils.toEntityUid("Mission2", Number(key)), entities)) : [];

        if (this.props.s.selectedGroupBy != NONE_ENTITY_NAME) {
            const field = this.props.s.selectedGroupBy == "EquipmentResource" ? "equipmentResource" : "humanResource";
            missions = missions.filter(m => !m[field]);
        }
        return missions;
    }

    private getMissionsTotalTime(entity: any) {
        if (!entity || !entity.missions) {
            return 0;
        }
        return entity.missions.reduce((missionsTotalTime: number, mission: any) => {
            return (mission.endTime - mission.startTime) + missionsTotalTime;
        }, 0)
    }

    protected sortData(data: { [key: number]: any }, type: Sorting, entities: { [entityName: string]: { [id: number]: any } }): any[] {
        if (this.props.s.selectedGroupBy === "EquipmentResource") {
            return Object.keys(data).map(key => GanttUtils.findByUid(GanttUtils.toEntityUid("EquipmentResource", Number(key)), entities)).sort((a, b) => {
                if (type === Sorting.MISSIONS_TOTAL_TIME) {
                    if (!a.identifier) {
                        return 1;
                    } else if (!b.identifier) {
                        return -1;
                    }
                    const diff = this.getMissionsTotalTime(b) - this.getMissionsTotalTime(a);
                    if (diff !== 0) {
                        return diff;
                    }
                }
                const valA = a.identifier?.toLocaleLowerCase();
                const valB = b.identifier?.toLocaleLowerCase();
                return valA.localeCompare(valB);
            });
        }
        return Object.keys(data).map(key => GanttUtils.findByUid(GanttUtils.toEntityUid("HumanResourceSchedule", Number(key)), entities)).sort((a, b) => {
            if (type === Sorting.MISSIONS_TOTAL_TIME && this.props.s.selectedGroupBy === "HumanResourceSchedule") {
                const missionsTotalTimeA = this.getMissionsTotalTime(a), missionsTotalTimeB = this.getMissionsTotalTime(b);
                if (missionsTotalTimeA == missionsTotalTimeB) {
                    return a.startTime - b.startTime;
                }
                return missionsTotalTimeB - missionsTotalTimeA;
            } else if (type === Sorting.SHEDULE_START_AND_END_TIME && this.props.s.selectedGroupBy === "HumanResourceSchedule") {
                if (b.startTime == a.startTime) {
                    return this.getMissionsTotalTime(b) - this.getMissionsTotalTime(a);
                }
                return a.startTime - b.startTime;
            }
            return 0;
        });
    }

    protected processData(newEntities: any) {
        const perType = this.props.s.selectedGroupBy;
        const groupData: { [key: number]: any } = perType ? (newEntities?.[perType] || {}) : {};
        const data: GanttData = { layers: [], items: [], groups: [] };
        const sortedLines = this.sortData(groupData, this.props.s.selectedSortBy as Sorting, newEntities);
        var indexGroup = -1;

        sortedLines.push({ id: NONE_ID });

        sortedLines.forEach(r => {
            let addToGantt = true;
            if (perType && this.props.hideResources?.[perType] && this.props.hideResources[perType]?.findIndex(id => id === r.id) !== -1) {
                addToGantt = false;
            }
            indexGroup++;
            const isNoneRow = r.id == NONE_ID;
            let group: GanttGroup = {
                id: indexGroup,
                entityUid: isNoneRow ? NONE_ENTITY_UID : GanttUtils.toEntityUid(perType, r.id),
                key: isNoneRow ? NONE_ENTITY_UID : GanttUtils.toEntityUid(perType, r.id),
                visible: addToGantt,
                ganttId: this.constructor.name,
            }
            data.groups.push(group);

            perType && (isNoneRow ? this.getPendingMissions(newEntities) : (GanttUtils.findByUid(GanttUtils.toEntityUid(perType!, r.id), newEntities)?.missions || [])).forEach((mission: any) => {
                data.items.push({
                    key: GanttUtils.toEntityUid(missionEntityDescriptor.name, mission.id),
                    row: indexGroup,
                    start: mission.startTime,
                    end: mission.endTime,
                    entityUid: GanttUtils.toEntityUid(missionEntityDescriptor.name, mission.id),//GanttUtils.denormalizeEntity("Mission2", mission, newEntities),
                    ganttId: this.constructor.name,
                    visible: addToGantt,
                });
            })

            if (this.props.s.selectedGroupBy == "HumanResourceSchedule" && !isNoneRow) {
                data.layers.push({
                    rowNumber: indexGroup,
                    start: r.startTime,
                    end: r.endTime,
                    style: getLayerStyle("HumanResourceSchedule", GanttUtils.findByUid(GanttUtils.toEntityUid("HumanResourceSchedule", r.id), newEntities)),
                    visible: addToGantt
                });
            }
        });
        this.props.r.setInReduxState({ data });
    }

    async componentDidMount() {
        let selectedGroupBy = window.sessionStorage.getItem(GANTT_RESOURCES_GROUP_BY) as "HumanResourceSchedule" | "EquipmentResource" | typeof NONE_ENTITY_NAME;
        let selectedSortBy = window.sessionStorage.getItem(GANTT_RESOURCES_SORT_BY) as Sorting;
        if (selectedGroupBy === null) {
            selectedGroupBy = "HumanResourceSchedule";
        }
        if (selectedSortBy === null) {
            selectedSortBy = Sorting.MISSIONS_TOTAL_TIME;
        }
        await this.props.r.setInReduxState({ selectedGroupBy: selectedGroupBy, selectedSortBy: selectedSortBy });

        super.componentDidMount();
    }

    async componentDidUpdateInternal(prevProps?: Props) {
        super.componentDidUpdateInternal(prevProps);
        let shouldProcessData: boolean = false;
        if (prevProps && prevProps.s.selectedSortBy !== this.props.s.selectedSortBy) {
            window.sessionStorage.setItem(GANTT_RESOURCES_SORT_BY, this.props.s.selectedSortBy as Sorting);
            shouldProcessData = true;
        }
        if (!prevProps || prevProps.s.selectedGroupBy !== this.props.s.selectedGroupBy) {
            window.sessionStorage.setItem(GANTT_RESOURCES_GROUP_BY, this.props.s.selectedGroupBy as string);
            shouldProcessData = true;
        }
        if (shouldProcessData) {
            this.processData(this.props.entities);
        }
    }

    protected entitiesChangedHandler(newEntities: any) {
        this.processData(newEntities);
    }

    protected getAcceptedType() {
        return "Mission2";
    }
    protected getContextProps() {
        return {
            ...super.getContextProps(), showGanttMessageModal: (driverId: number) => {
                // if popup is open, close it
                this.props.s.popupEntityUid && this.props.r.setInReduxState({ popupEntityUid: undefined });
                this.ganttMessagesRef.current?.props.r.setInReduxState({ showTableModal: true, driverId });
            }
        };
    }
    protected getEntityAt(index: number) {
        const group = this.props.s.data.groups[index];
        return group?.entityUid == NONE_ENTITY_UID ? { id: NONE_ID, missions: this.getPendingMissions(this.props.entities!) } : super.getEntityAt(index);
    }

    protected getEntityDescriptor() {
        if (this.props.s.selectedGroupBy === "HumanResourceSchedule") {
            return humanResourceScheduleEntityDescriptor;

        } else if (this.props.s.selectedGroupBy === "EquipmentResource") {
            return equipmentResourceEntityDescriptor;
        }
        return noneEntityDescriptor;
    }

    protected getTableColumns(): ColumnDefinition[] | undefined {
        let columns = super.getTableColumns();
        if (columns) {
            return columns;
        }
        if (this.props.s.selectedGroupBy === "HumanResourceSchedule") {
            const fields = ["humanResource", "humanResource.vehicle", "startTime", "endTime", "regulator", "mole", "teamName", "missionsTotalTime", "humanResource.ganttMessages"];
            let cc: ColumnDefinition[] = [];
            fields.forEach(f => cc.push({ width: 120, name: f }));
            return cc;
        } else if (this.props.s.selectedGroupBy === "EquipmentResource") {
            return [{ name: "identifier", width: this.getTableWidth() * 2 / 3 }, { name: "missionsTotalTime", width: this.getTableWidth() / 3 }];
        }
        return [{ name: "missionsTotalTime", width: this.getTableWidth() }];
    }

    protected renderTopBar() {
        const optionsGroup: DropdownItemProps[] = [{ value: "HumanResourceSchedule", text: entityDescriptors["HumanResourceSchedule"].getLabel() },
        { value: "EquipmentResource", text: entityDescriptors["EquipmentResource"].getLabel() },
        { value: NONE_ENTITY_NAME, text: _msg("GanttResources.none") }];
        const optionsSort: DropdownItemProps[] = [{ value: Sorting.MISSIONS_TOTAL_TIME, text: _msg("GanttResources.sortBy.missionTime") },
        { value: Sorting.SHEDULE_START_AND_END_TIME, text: _msg("GanttResources.sortBy.sheduleTime") }];
        const numberOfUnreadMessages = this.ganttMessagesRef?.current?.getNumberUnreadMessages() || 0;
        return <>{!this.props.hideSortBy ? <>
            <div className="flex-container">
                {_msg("GanttResources.sortBy")}
                <Dropdown selection options={optionsSort} value={this.props.s.selectedSortBy} onChange={(e, data) =>
                    this.props.r.setInReduxState({ selectedSortBy: data.value as Sorting })} />
            </div>
            {this.props.s.showMessageButton ? <Button color={!numberOfUnreadMessages ? "blue" : "violet"} content={`${_msg("GanttMessages.unreadMessages")}: ${numberOfUnreadMessages}`} icon="envelope"
                onClick={() => { this.ganttMessagesRef.current?.props.r.setInReduxState({ showTableModal: true, driverId: undefined }); }} /> : null}
        </> : <></>}{!this.props.hideGroupBy ? <>
            <div className="flex-container">
                {_msg("GanttResources.groupBy")}
                <Dropdown selection options={optionsGroup} value={this.props.s.selectedGroupBy} onChange={(e, data) => {
                    this.props.r.setInReduxState({ selectedGroupBy: data.value as "HumanResourceSchedule" | "EquipmentResource" | typeof NONE_ENTITY_NAME });
                }
                } />
            </div>
        </> : <></>}</>;
    }

    render() {
        return <>
            {super.render()}
            <GanttMessagesRRC id="GanttMessagesRRC" ref={this.ganttMessagesRef} entities={this.props.entities} />
        </>
    }
}

export const GanttResourcesRRC = ReduxReusableComponents.connectRRC(GanttResourcesState, GanttResourcesReducers, GanttResources);

const GANTT_RESOURCES_SORT_BY = 'ganttResources.sortBy';
const GANTT_RESOURCES_GROUP_BY = 'ganttResources.groupBy';

export const noneEntityDescriptor = new EntityDescriptor({ name: "None" })
    .addFieldDescriptor(new MissionTotalTimeFieldDescriptor())
    .addFieldDescriptor({ name: "missions", type: FieldType.oneToMany("Mission2") });
