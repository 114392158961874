/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum AggregateFunctionType {
  AVG = "AVG",
  COUNT = "COUNT",
  MAX = "MAX",
  MIN = "MIN",
  SUM = "SUM",
}

export enum AlgorithmType {
  DYNAMIC_PROGRAMMING = "DYNAMIC_PROGRAMMING",
  PYTHON = "PYTHON",
}

export enum AuthenticationStatus {
  CANNOT_RELOGIN = "CANNOT_RELOGIN",
  FAILURE = "FAILURE",
  FAILURE_TWO_MANY_FAILED_LOGINS = "FAILURE_TWO_MANY_FAILED_LOGINS",
  SUCCESS = "SUCCESS",
  SUCCESS_WITH_EXPIRED_PASSWORD = "SUCCESS_WITH_EXPIRED_PASSWORD",
}

export enum EnergySource {
  BATTERY_LEAD_ACID = "BATTERY_LEAD_ACID",
  BATTERY_LITHIUM_IBS = "BATTERY_LITHIUM_IBS",
  BATTERY_LITHIUM_OTHER = "BATTERY_LITHIUM_OTHER",
  FUEL = "FUEL",
  HYBRID = "HYBRID",
}

export enum EngineState {
  IDLE = "IDLE",
  OFF = "OFF",
  RUNNING = "RUNNING",
}

export enum EquipmentResourceStatus {
  NOT_AVAILABLE = "NOT_AVAILABLE",
  OFF = "OFF",
  ON_NOT_OPERATING = "ON_NOT_OPERATING",
  ON_OPERATING = "ON_OPERATING",
}

export enum EventGenerationSourceTypeEnum {
  AUTO_GEOLOCATION = "AUTO_GEOLOCATION",
  MANUAL_CHAUFF = "MANUAL_CHAUFF",
  MANUAL_REG = "MANUAL_REG",
}

export enum EventMonitoringStatusEnum {
  ERROR = "ERROR",
  NONE = "NONE",
  WARNING = "WARNING",
}

export enum LoadModeEnum {
  DOCKING = "DOCKING",
  NONE = "NONE",
}

export enum MissionWorkflowElementType {
  BUTTON = "BUTTON",
  MISSION_STATE = "MISSION_STATE",
  OBJECT_STATE = "OBJECT_STATE",
}

export interface ActionTypeInput {
  id?: any | null;
  version?: number | null;
  code?: string | null;
  label?: string | null;
  requiredForValidation?: boolean | null;
  duration?: number | null;
  startAction?: boolean | null;
  endAction?: boolean | null;
  noValidationAlertTime?: number | null;
  forDepartures?: boolean | null;
  forArrivals?: boolean | null;
  taskType?: TaskTypeInput | null;
}

export interface AddressInput {
  id?: any | null;
  version?: number | null;
  name?: string | null;
  isParking?: boolean | null;
  contact?: boolean | null;
  parkingType?: string | null;
  terminal?: string | null;
  airport?: AirportInput | null;
  hotspot?: boolean | null;
  code?: string | null;
  zone?: ZoneInput | null;
  pits?: (PitInput | null)[] | null;
  tags?: (EntityToTagInput | null)[] | null;
  latitude?: number | null;
  longitude?: number | null;
  archived: boolean;
}

export interface AggregateFunctionInput {
  field?: string | null;
  type?: AggregateFunctionType | null;
}

export interface AirportInput {
  id?: any | null;
  version?: number | null;
  code?: string | null;
  name?: string | null;
  ifubs?: boolean | null;
  isSchengen?: boolean | null;
  latitude?: number | null;
  longitude?: number | null;
  timeZone?: string | null;
}

export interface CopyInputDataFromDbConfigInput {
  deleteDataBeforeImport: boolean;
  entityName: string;
  filter?: FilterInput | null;
  importData: boolean;
}

export interface DayTemplateDayTemplateGroupAssociationInput {
  id?: any | null;
  version?: number | null;
  dayIndex?: number | null;
  dayTemplate?: DayTemplateInput | null;
  dayTemplateGroup?: DayTemplateGroupInput | null;
}

export interface DayTemplateGroupInput {
  id?: any | null;
  version?: number | null;
  name?: string | null;
  dayTemplateDayTemplateGroupAssociations?: (DayTemplateDayTemplateGroupAssociationInput | null)[] | null;
}

export interface DayTemplateInput {
  id?: any | null;
  version?: number | null;
  name?: string | null;
  basedOnDate?: any | null;
  lastGenerations?: string | null;
  unit?: UnitInput | null;
  dayTemplateDayTemplateGroupAssociations?: (DayTemplateDayTemplateGroupAssociationInput | null)[] | null;
  organization?: OrganizationInput | null;
}

export interface EmissionRatingInput {
  id?: any | null;
  version?: number | null;
  name?: string | null;
}

export interface EntityToTagInput {
  id?: any | null;
  version?: number | null;
  entityId?: any | null;
  tag?: TagInput | null;
  entityName?: string | null;
}

export interface EquipmentModelFamilyInput {
  id?: any | null;
  version?: number | null;
  name?: string | null;
  externalId?: any | null;
  equipmentProductType?: EquipmentProductTypeInput | null;
}

export interface EquipmentModelInput {
  id?: any | null;
  version?: number | null;
  name?: string | null;
  image?: string | null;
  externalId?: any | null;
  equipmentModelFamily?: EquipmentModelFamilyInput | null;
}

export interface EquipmentProductTypeInput {
  id?: any | null;
  version?: number | null;
  name?: string | null;
  externalId?: any | null;
}

export interface EquipmentResourceInput {
  id?: any | null;
  version?: number | null;
  dataString?: string | null;
  dataVersion?: number | null;
  identifier?: string | null;
  available?: boolean | null;
  unitId?: any | null;
  unit?: UnitInput | null;
  tags?: (EntityToTagInput | null)[] | null;
  organization?: OrganizationInput | null;
  astusId?: string | null;
  imei?: string | null;
  vehicleId?: string | null;
  comment?: string | null;
  closestAddressId?: any | null;
  gpsSystemName?: string | null;
  geofenceZones?: string | null;
  hasFault?: boolean | null;
  hasBlockingFault?: boolean | null;
  longitude?: number | null;
  latitude?: number | null;
  attachmentId?: any | null;
  carosery?: string | null;
  weight?: number | null;
  engineHours?: number | null;
  odometer?: number | null;
  plateNumber?: string | null;
  tachy?: boolean | null;
  registration?: string | null;
  batteryLevel?: string | null;
  territoriesName?: string | null;
  territories?: string | null;
  enteredTerritories?: string | null;
  exitedTerritories?: string | null;
  isAstusDown?: boolean | null;
  lastDetectionDate?: any | null;
  attachment?: EquipmentResourceInput | null;
  attachmentParentId?: any | null;
  equipmentType?: EquipmentTypeInput | null;
  equipmentModel?: EquipmentModelInput | null;
  detectedBy?: EquipmentResourceInput | null;
  closestAddress?: AddressInput | null;
  initBusParking?: AddressInput | null;
  humanResources?: (HumanResourceInput | null)[] | null;
  revisions?: (RevisionInput | null)[] | null;
  faults?: (FaultInput | null)[] | null;
  updated?: any | null;
  lastPointLatitude?: number | null;
  lastPointLongitude?: number | null;
  auditDateOverride?: any | null;
  engineState?: EngineState | null;
  operating: boolean;
  status?: EquipmentResourceStatus | null;
  videoRecorderId?: string | null;
  hasMissionInProgress?: boolean | null;
  archived: boolean;
  energySource?: EnergySource | null;
  emissionRating?: EmissionRatingInput | null;
  airportInferredFromGps?: AirportInput | null;
  astusProvidedDriver?: string | null;
  closestAddressAsString?: string | null;
  detectedByAsString?: string | null;
  fleetName?: string | null;
  fleetOwner?: string | null;
}

export interface EquipmentTypeInput {
  id?: any | null;
  version?: number | null;
  name?: string | null;
  transportPositions?: number | null;
  color?: number | null;
  isNonMotorizedEquipment?: boolean | null;
  assignable?: boolean | null;
  icon?: string | null;
  showOnMissionSegment?: boolean | null;
  isAttachable?: boolean | null;
  qualificationType?: QualificationTypeInput | null;
  equipmentTypeTasks?: (EquipmentTypeTaskInput | null)[] | null;
}

export interface EquipmentTypeTaskInput {
  id?: any | null;
  version?: number | null;
  quantity?: number | null;
  exclusive?: boolean | null;
  cumulative?: boolean | null;
  equipmentType?: EquipmentTypeInput | null;
  taskType?: TaskTypeInput | null;
}

export interface FaultInput {
  id?: any | null;
  version?: number | null;
  createDate?: any | null;
  validationDate?: any | null;
  closeDate?: any | null;
  status?: string | null;
  createUser?: string | null;
  validationUser?: string | null;
  closingUser?: string | null;
  comments?: string | null;
  immobilisationPlace?: string | null;
  equipmentResource?: EquipmentResourceInput | null;
  faultReason?: FaultReasonInput | null;
  faultType?: FaultTypeInput | null;
  maintenance?: MaintenanceInput | null;
}

export interface FaultReasonInput {
  id?: any | null;
  version?: number | null;
  message?: string | null;
}

export interface FaultTypeInput {
  id?: any | null;
  version?: number | null;
  message?: string | null;
  blocking?: boolean | null;
}

export interface FilterInput {
  cast?: string | null;
  field?: string | null;
  filters?: (FilterInput | null)[] | null;
  operator?: string | null;
  timezone?: string | null;
  value?: string | null;
}

export interface FindByFilterParamsInput {
  aggregateFunctions?: (AggregateFunctionInput | null)[] | null;
  countMode?: boolean | null;
  filter?: FilterInput | null;
  pageSize: number;
  sorts?: (SortInput | null)[] | null;
  startIndex: number;
}

export interface FindByStringParamsInput {
  aggregateFunctions?: (AggregateFunctionInput | null)[] | null;
  countMode?: boolean | null;
  filter?: FilterInput | null;
  pageSize: number;
  sorts?: (SortInput | null)[] | null;
  startIndex: number;
  string?: string | null;
}

export interface FlightInput {
  id?: any | null;
  version?: number | null;
  dataString?: string | null;
  dataVersion?: number | null;
  name?: string | null;
  date?: any | null;
  day?: string | null;
  tasks?: TaskInput[] | null;
  airline?: string | null;
  destination?: string | null;
  origin?: string | null;
  initialDate?: any | null;
  planeIdentifier?: string | null;
  defaultTerminal?: string | null;
  sariaPort?: string | null;
  startParkingDate?: any | null;
  endParkingDate?: any | null;
  terminal?: string | null;
  status?: string | null;
  irgCode?: string | null;
  lastKnownDate?: any | null;
  lastKnownDateType?: string | null;
  departure?: boolean | null;
  showFlightInGantt?: boolean | null;
  showFlightInList?: boolean | null;
  defaultShowFlightInGantt?: any | null;
  number?: string | null;
  ifubs?: boolean | null;
  departureDate?: any | null;
  passengersNo?: number | null;
  priority?: boolean | null;
  treated?: boolean | null;
  comment?: string | null;
  flightType?: string | null;
  schengen?: boolean | null;
  ifubsForEditor?: boolean | null;
  flightVersion?: string | null;
  is3N?: boolean | null;
  rotationFlightId?: any | null;
  rotationIdSetFromFlex?: any | null;
  ganttStart?: any | null;
  ganttEnd?: any | null;
  personnelOnboard?: string | null;
  passengerWithDisabilities?: string | null;
  orderDate?: any | null;
  canceled?: boolean | null;
  importedBy?: string | null;
  sariaPaxUpdateDisable?: boolean | null;
  sariaParkingUpdateDisable?: boolean | null;
  sariaPaxParkingUpdateDisable?: boolean | null;
  sariaShowFlightInGanttUpdateDisable?: boolean | null;
  layover2Name?: string | null;
  layover2Date?: any | null;
  layover1ArrivalDate?: any | null;
  layover1DepartureDate?: any | null;
  season?: string | null;
  airport?: AirportInput | null;
  timeSlot?: string | null;
  groundAgent?: string | null;
  towedEstimatedStartTime?: any | null;
  towedRealStartTime?: any | null;
  towedRealEndTime?: any | null;
  towedStatus?: string | null;
  departParkingTime?: any | null;
  rotationName?: string | null;
  verified?: boolean | null;
  cycleDepartureOrder?: number | null;
  originalDate?: any | null;
  customObjectCode?: string | null;
  crewShuttle?: boolean | null;
  onHold?: boolean | null;
  inHangar?: boolean | null;
  parking?: AddressInput | null;
  paxParking?: AddressInput | null;
  planeType?: PlaneTypeInput | null;
  galery?: AddressInput | null;
  oilGroup?: OilGroupInput | null;
  invoiceAccount?: InvoiceAccountInput | null;
  unit?: UnitInput | null;
  towedAddress?: AddressInput | null;
  towedInitialAddress?: AddressInput | null;
  dayTemplate?: DayTemplateInput | null;
  flightSchedule?: FlightScheduleInput | null;
  blockAutoUpdates?: boolean | null;
  staffNo?: number | null;
  organization?: OrganizationInput | null;
  estimatedDate?: any | null;
  rotationFlight?: FlightInput | null;
  blockDate?: any | null;
  lockedFields?: string | null;
  arrivalAirline?: string | null;
  arrivalNumber?: string | null;
  arrivalDate?: any | null;
  arrivalInitialDate?: any | null;
  arrivalOrigin?: string | null;
  arrivalPlaneIdentifier?: string | null;
  departureAirline?: string | null;
  departureNumber?: string | null;
  departureDepartureDate?: any | null;
  departureInitialDate?: any | null;
  departureDestination?: string | null;
  departurePlaneIdentifier?: string | null;
  agentParking?: string | null;
  alertCreateDefaultObjectsNoReset?: boolean | null;
  alreadyEngaged?: boolean | null;
  averageVolume?: any | null;
  blockObjectCreation?: boolean | null;
  busType?: string | null;
  color?: number | null;
  crewShuttleModified?: boolean | null;
  degradedMode?: boolean | null;
  delayedFlight?: boolean | null;
  deltaEventCode?: string | null;
  dhcCTime?: string | null;
  duration?: number | null;
  eco?: number | null;
  efuelDate?: string | null;
  efuelQuantity?: number | null;
  efuelState?: string | null;
  hasArrived?: boolean | null;
  hc?: number | null;
  lastModifiedDate?: string | null;
  lastModifiedDestination?: string | null;
  lastModifiedOrigin?: string | null;
  lastModifiedParking?: string | null;
  lastModifiedPlaneIdentifier?: string | null;
  lastModifiedPlaneType?: string | null;
  lastModifiedUpdateDate?: string | null;
  modifiedFlight?: boolean | null;
  modifiedObjects?: boolean | null;
  modifiedPlaneIdentifier?: boolean | null;
  offloadStatus?: string | null;
  offset?: number | null;
  parkingChanged?: string | null;
  parkingType?: string | null;
  passaerelle?: string | null;
  recurrentComment?: boolean | null;
  temporaryStorage?: string | null;
  uaeventName?: string | null;
}

export interface FlightScheduleInput {
  id?: any | null;
  version?: number | null;
  airline?: string | null;
  flightNumber?: string | null;
  startDate?: any | null;
  endDate?: any | null;
  deactivatedAt?: any | null;
  daysOfTheWeek?: string | null;
  rotation?: FlightScheduleInput | null;
  targetForThisException?: FlightScheduleInput | null;
  rotationDuration?: number | null;
  airport?: AirportInput | null;
  departure?: boolean | null;
  properties?: string | null;
  diffToken?: string | null;
}

export interface HumanResourceInput {
  id?: any | null;
  version?: number | null;
  dataString?: string | null;
  dataVersion?: number | null;
  identifier?: string | null;
  available?: boolean | null;
  unitId?: any | null;
  unit?: UnitInput | null;
  tags?: (EntityToTagInput | null)[] | null;
  organization?: OrganizationInput | null;
  firstName?: string | null;
  lastName?: string | null;
  todayStartTime?: any | null;
  todayEndTime?: any | null;
  todayBreakStartTime?: any | null;
  todayBreakEndTime?: any | null;
  comment?: string | null;
  pdaIdentifier?: string | null;
  shiftId?: any | null;
  rfidIdentifier?: string | null;
  partialInactivity?: string | null;
  vehicleId?: any | null;
  mobileDeviceId?: any | null;
  nrOfCurrentMissions?: number | null;
  nrOfCurrentMissionsAndOtherActivities?: number | null;
  lastMission?: any | null;
  radioId?: any | null;
  mobileNumber?: string | null;
  vehicle?: EquipmentResourceInput | null;
  mobileDevice?: MobileDeviceInput | null;
  shift?: HumanResourceShiftInput | null;
  radio?: RadioInput | null;
  qualifications?: (QualificationInput | null)[] | null;
  lastEquipmentResourceAssignment?: any | null;
  lastEquipmentResourceAssignmentViaRfid?: any | null;
  borrowedToUnit?: UnitInput | null;
  userId?: any | null;
  archived: boolean;
  correspondingAstusName?: string | null;
}

export interface HumanResourceShiftInput {
  id?: any | null;
  version?: number | null;
  name?: string | null;
  startTime?: any | null;
  endTime?: any | null;
  startBreakTime?: any | null;
  endBreakTime?: any | null;
  startBreakTime2?: any | null;
  endBreakTime2?: any | null;
  unit?: UnitInput | null;
  organization?: OrganizationInput | null;
}

export interface InactivityTypeInput {
  id?: any | null;
  version?: number | null;
  name?: string | null;
  duration?: number | null;
  isBillable?: boolean | null;
  color?: number | null;
  isPartial?: boolean | null;
  isSpecial?: boolean | null;
  filterSchedulesByIt?: boolean | null;
  hasEvents?: boolean | null;
  hideInGantt: boolean;
  allowOverlapping: boolean;
  vraIndex?: number | null;
  archived: boolean;
}

export interface InvoiceAccountInput {
  id?: any | null;
  version?: number | null;
  name?: string | null;
  code?: string | null;
  details?: string | null;
  contractNumber?: string | null;
  startDate?: any | null;
  endDate?: any | null;
  payType?: string | null;
}

export interface LoginParamsInput {
  data?: any | null;
  loginType?: string | null;
  password: string;
  rememberMe: boolean;
  username: string;
}

export interface LogoutParamsInput {
  data?: any | null;
  oktaUserId?: string | null;
}

export interface MaintenanceInput {
  id?: any | null;
  version?: number | null;
  name?: string | null;
  address?: string | null;
  telephone?: string | null;
  email?: string | null;
  faxNumber?: string | null;
}

export interface Mission2Input {
  id?: any | null;
  version?: number | null;
  dataString?: string | null;
  dataVersion?: number | null;
  startTime?: any | null;
  endTime?: any | null;
  creationDate?: any | null;
  isSpecial?: boolean | null;
  day?: string | null;
  status?: string | null;
  humanResourceId?: any | null;
  equipmentResourceId?: any | null;
  initialStartTime?: any | null;
  initialEndTime?: any | null;
  availableEventTypes?: (string | null)[] | null;
  startTimeManual?: boolean | null;
  endTimeManual?: boolean | null;
  inactivityTypeId?: any | null;
  unitName?: string | null;
  priority?: any | null;
  comment?: string | null;
  comment2?: string | null;
  creationMode?: string | null;
  billingDate?: any | null;
  mobileDeviceId?: any | null;
  driverAssistant1?: string | null;
  driverAssistant2?: string | null;
  mole?: string | null;
  eventMonitoringExpectedNumberOfEvents?: number | null;
  eventMonitoringActualNumberOfEvents?: number | null;
  eventMonitoringAutoNumberOfEvents?: number | null;
  eventMonitoringStatus?: EventMonitoringStatusEnum | null;
  color?: number | null;
  startAddress?: AddressInput | null;
  endAddress?: AddressInput | null;
  humanResource?: HumanResourceInput | null;
  equipmentResource?: EquipmentResourceInput | null;
  equipmentType?: EquipmentTypeInput | null;
  inactivityType?: InactivityTypeInput | null;
  mobileDevice?: MobileDeviceInput | null;
  stops?: (Stop2Input | null)[] | null;
  events: MissionEvent2Input[];
  validationMessages?: (ValidationMessageInput | null)[] | null;
  requestStatus?: string | null;
  type?: MissionTypeInput | null;
  currentState?: MissionWorkflowElementInput | null;
  currentStopId?: any | null;
  organization?: OrganizationInput | null;
  tasks: TaskInput[];
  activatedFromFlex?: boolean | null;
  additionalStatus?: string | null;
  endOfMission?: boolean | null;
  endVolumeIndex?: string | null;
  finishedByUser?: string | null;
  flightPriorityComment?: string | null;
  flightPriorityType?: string | null;
  hasNewDriver?: boolean | null;
  hasNoFuelEvent?: boolean | null;
  initialObjectsNumber?: number | null;
  oilGroupBonNumber?: string | null;
  oilGroupName?: string | null;
  oldDriverId?: any | null;
  orderOfFlightsLocked?: boolean | null;
  previousEndVolumeIndex?: string | null;
  specialMissionFlightString?: string | null;
  specialMissionOverlap?: string | null;
  startVolumeIndex?: string | null;
  userName?: string | null;
  validationErrors?: number | null;
  vehicleAttachment?: string | null;
  vehicleCapacity?: number | null;
  volume?: any | null;
}

export interface MissionEvent2Input {
  id?: any | null;
  version?: number | null;
  dataString?: string | null;
  dataVersion?: number | null;
  missionId?: any | null;
  missionIdOld?: any | null;
  uidForTimeout?: string | null;
  stopId?: any | null;
  taskId?: any | null;
  creationdate?: any | null;
  deleted?: boolean | null;
  descriptorType?: string | null;
  userId?: any | null;
  status?: string | null;
  severity?: string | null;
  parentId?: any | null;
  driverId?: any | null;
  oagId?: any | null;
  generationSource?: EventGenerationSourceTypeEnum | null;
  flight?: string | null;
  index?: string | null;
  objectActionInfo?: string | null;
  objectName?: string | null;
  observation?: string | null;
  statusChangedAt?: any | null;
  stopAddress?: string | null;
  stopInfo?: string | null;
}

export interface MissionTypeInput {
  id?: any | null;
  version?: number | null;
  name?: string | null;
  color?: string | null;
}

export interface MissionWorkflowElementInput {
  id?: any | null;
  version?: number | null;
  name?: string | null;
  type?: MissionWorkflowElementType | null;
  label?: string | null;
}

export interface MobileDeviceInput {
  id?: any | null;
  version?: number | null;
  identifier?: string | null;
  phoneNumber?: string | null;
  status?: number | null;
  lastVersion?: string | null;
  lastUpdate?: any | null;
  serial?: string | null;
  equipmentResource?: EquipmentResourceInput | null;
  humanResources?: (HumanResourceInput | null)[] | null;
  unit?: UnitInput | null;
  organization?: OrganizationInput | null;
  lastPointLatitude?: number | null;
  lastPointLongitude?: number | null;
  updated?: any | null;
  archived: boolean;
  almostUniqueDeviceId?: string | null;
  requiredEquipmentResource?: EquipmentResourceInput | null;
}

export interface ObjectActionGroupInput {
  id?: any | null;
  version?: number | null;
  dataString?: string | null;
  dataVersion?: number | null;
  missionId?: any | null;
  missionSubsetId?: any | null;
  objectId?: any | null;
  objectActionIds?: (any | null)[] | null;
  ganttStart?: any | null;
  ganttEnd?: any | null;
  ganttStartManual?: boolean | null;
  ganttEndManual?: boolean | null;
  missionSubsetIdIndex?: number | null;
  treated?: boolean | null;
  mission: Mission2Input;
  object: TaskInput;
  objectActions?: (ObjectActionInput | null)[] | null;
}

export interface ObjectActionInput {
  id?: any | null;
  version?: number | null;
  dataString?: string | null;
  dataVersion?: number | null;
  stopId?: any | null;
  objectActionGroupId?: any | null;
  type?: string | null;
  validated?: boolean | null;
  objectActionGroupIdIndex?: number | null;
  availableEventTypes?: (string | null)[] | null;
  stopIdIndex?: number | null;
  objectActionGroup?: ObjectActionGroupInput | null;
  stop?: Stop2Input | null;
}

export interface OilGroupInput {
  id?: any | null;
  version?: number | null;
  name?: string | null;
  description?: string | null;
  prefix?: string | null;
  lastBonNumber?: any | null;
}

export interface OrganizationInput {
  id?: any | null;
  version?: number | null;
  name?: string | null;
  qualifiedName?: string | null;
  parent?: OrganizationInput | null;
  children?: (OrganizationInput | null)[] | null;
  airport?: AirportInput | null;
}

export interface PFTInput {
  id?: any | null;
  version?: number | null;
  name?: string | null;
  jette?: number | null;
  indexTrieur?: any | null;
  sideTrieur?: any | null;
  trieur?: AddressInput | null;
}

export interface Pair_Double_DoubleInput {
  a?: number | null;
  b?: number | null;
}

export interface ParamsInput {
  assignToUnit?: boolean | null;
  assignWithNoEquipment?: boolean | null;
  attachmentUid?: string | null;
  comment?: string | null;
  createTruckObjectsForFlightUid?: string | null;
  creationMode?: string | null;
  degradedMode?: boolean | null;
  doNotCreateEventOnMissionSave?: boolean | null;
  equipmentResourceUid?: string | null;
  equipmentTypeUid?: string | null;
  forcedMissionEndDate?: any | null;
  forcedMissionStartDate?: any | null;
  formerMissionUid?: string | null;
  humanResourceUid?: string | null;
  missionCreationDate?: any | null;
  missionUid?: string | null;
  objectQuantitites?: any | null;
  objectToSave?: (TaskInput | null)[] | null;
  objectToSaveAndAssign?: (TaskInput | null)[] | null;
  objectUids?: (string | null)[] | null;
  pendingMissionsNumber?: number | null;
  preObjectActions?: (PreObjectActionInput | null)[] | null;
  temporaryStorageAddressName?: string | null;
  useCurrentDateForMissionStartDate?: boolean | null;
}

export interface PitInput {
  id?: any | null;
  version?: number | null;
  name?: string | null;
  description?: string | null;
  address?: AddressInput | null;
}

export interface PlaneTypeInput {
  id?: any | null;
  version?: number | null;
  name?: string | null;
  flightType?: string | null;
  observations?: string | null;
  description?: string | null;
}

export interface PreObjectActionInput {
  address?: AddressInput | null;
  capacity1: number;
  capacity2: number;
  endAddress?: AddressInput | null;
  maxTime?: any | null;
  minTime?: any | null;
  quantity?: number | null;
  type?: string | null;
}

export interface QualificationInput {
  id?: any | null;
  version?: number | null;
  qualificationType?: QualificationTypeInput | null;
  humanResource?: HumanResourceInput | null;
  startDate?: any | null;
  endDate?: any | null;
}

export interface QualificationTypeInput {
  id?: any | null;
  version?: number | null;
  name?: string | null;
  description?: string | null;
  airline?: string | null;
  unit?: UnitInput | null;
  planeType?: PlaneTypeInput | null;
  planeTypeGroup?: ValuesGroupInput | null;
  customBadge?: boolean | null;
  organization?: OrganizationInput | null;
}

export interface RadioInput {
  id?: any | null;
  version?: number | null;
  identifier?: string | null;
  humanResources?: (HumanResourceInput | null)[] | null;
}

export interface RequiredEquipmentInput {
  id?: any | null;
  version?: number | null;
  requiredNumber?: number | null;
  taskType?: TaskTypeInput | null;
  equipmentType?: EquipmentTypeInput | null;
}

export interface RequiredQualificationInput {
  id?: any | null;
  version?: number | null;
  requiredNumber?: number | null;
  taskType?: TaskTypeInput | null;
  qualificationType?: QualificationTypeInput | null;
}

export interface RevisionInput {
  id?: any | null;
  version?: number | null;
  date?: any | null;
  plannedDate?: any | null;
  equipmentResource?: EquipmentResourceInput | null;
  revisionType?: RevisionTypeInput | null;
}

export interface RevisionTypeInput {
  id?: any | null;
  version?: number | null;
  name?: string | null;
  description?: string | null;
  runningHours?: number | null;
  revisionInterval?: number | null;
  equipmentType?: EquipmentTypeInput | null;
}

export interface SaveParams_LongInput {
  duplicateFromId?: any | null;
  fieldsAndValues?: any | null;
  id?: any | null;
}

export interface SortInput {
  direction?: string | null;
  field?: string | null;
}

export interface Stop2Input {
  id?: any | null;
  version?: number | null;
  dataString?: string | null;
  dataVersion?: number | null;
  missionId?: any | null;
  missionIdIndex?: number | null;
  tripStartTime?: any | null;
  tripDuration?: number | null;
  stopDuration?: number | null;
  initialTripStartTime?: any | null;
  initialTripDuration?: number | null;
  initialStopDuration?: number | null;
  tripEndTimeMin?: any | null;
  tripEndTimeMax?: any | null;
  capacity1?: number | null;
  capacity2?: number | null;
  status?: string | null;
  tripStartTimeManual?: boolean | null;
  nbTurns?: number | null;
  availableEventTypes?: (string | null)[] | null;
  mission?: Mission2Input | null;
  address?: AddressInput | null;
  objectActions?: (ObjectActionInput | null)[] | null;
  type?: StopTypeInput | null;
  index?: string | null;
}

export interface StopTypeInput {
  id?: any | null;
  version?: number | null;
  name?: string | null;
}

export interface TagInput {
  id?: any | null;
  version?: number | null;
  name?: string | null;
  color?: number | null;
  colorRegistryColor?: string | null;
  description?: string | null;
}

export interface TaskInput {
  id?: any | null;
  version?: number | null;
  dataString?: string | null;
  dataVersion?: number | null;
  name?: string | null;
  flightId?: any | null;
  showInGME?: boolean | null;
  taskGroupData?: any | null;
  comment?: string | null;
  taskParameter?: number | null;
  priority?: string | null;
  debarqued?: boolean | null;
  day?: string | null;
  taskGroup: FlightInput;
  startAddress?: AddressInput | null;
  endAddress?: AddressInput | null;
  taskType?: TaskTypeInput | null;
  startAddresses?: (AddressInput | null)[] | null;
  endAddresses?: (AddressInput | null)[] | null;
  missionType?: MissionTypeInput | null;
  currentState?: MissionWorkflowElementInput | null;
  connectionFlight?: FlightInput | null;
  startTime?: any | null;
  endTime?: any | null;
  quantity?: number | null;
  leftOverlap?: boolean | null;
  rightOverlap?: boolean | null;
  position?: string | null;
  weight?: number | null;
  particularity?: string | null;
  processedBaggages?: number | null;
  connectionFlights?: string | null;
  uldStatus?: string | null;
  uldTime?: any | null;
  shcCode?: string | null;
  transferObjectName?: string | null;
  galley?: string | null;
  approachNumber?: number | null;
  messageCreation?: boolean | null;
  vraIndex?: number | null;
  exceptionEvent?: boolean | null;
  finishMissionEvent?: boolean | null;
  eventMonitoringRulesJson?: string | null;
  eventMonitoringExpectedNumberOfEvents?: number | null;
  eventMonitoringActualNumberOfEvents?: number | null;
  eventMonitoringAutoNumberOfEvents?: number | null;
  requiredEquipmentResourceQualificationType?: string | null;
  equipmentResourceFillPercentage?: number | null;
  eventMonitoringStatus?: EventMonitoringStatusEnum | null;
  extraDemand?: boolean | null;
  extraDemandValidated?: boolean | null;
  pft?: PFTInput | null;
  emrjUseRotationFlightAsReference?: boolean | null;
  loadMode?: LoadModeEnum | null;
  connectionFlightId?: any | null;
  archived: boolean;
  offset?: number | null;
  duration?: number | null;
  lockedFields?: string | null;
  mission?: Mission2Input | null;
  actualStartTime?: any | null;
  actualEndTime?: any | null;
  actualStartTimeFixed?: boolean | null;
  actualEndTimeFixed?: boolean | null;
  baggagesLimitControled?: boolean | null;
  createdBy?: string | null;
  createdByEventId?: any | null;
  crewBaggageVersion?: number | null;
  endAddressIsTemporaryStorage?: boolean | null;
  flightDateUpdatedAt?: string | null;
  hasSpecialColor?: boolean | null;
  lastAgentIdentifier?: string | null;
  lastScanDate?: any | null;
  lastScannerIdentifier?: string | null;
  offloadStatus?: string | null;
  offsetForRotationFlight?: number | null;
  resetCitePNBaggages?: string | null;
  sentBaggageUpdate?: boolean | null;
  startAddressIsTemporaryStorage?: boolean | null;
  temporaryStoragePairObject?: any | null;
}

export interface TaskTypeInput {
  id?: any | null;
  version?: number | null;
  name?: string | null;
  color?: number | null;
  createAtFlightIntegration?: boolean | null;
  canBeSplit?: boolean | null;
  ocupiedPositions?: number | null;
  noTaskGroup?: boolean | null;
  untreatable?: boolean | null;
  isSpecial?: boolean | null;
  showRotationInfo?: boolean | null;
  blockMissionTime?: boolean | null;
  requiredEquipments?: (RequiredEquipmentInput | null)[] | null;
  requiredQualifications?: (RequiredQualificationInput | null)[] | null;
  actionTypes?: (ActionTypeInput | null)[] | null;
}

export interface UnitInput {
  id?: any | null;
  version?: number | null;
  dataString?: string | null;
  dataVersion?: number | null;
  name?: string | null;
  color?: number | null;
  airport?: AirportInput | null;
  location?: string | null;
  territoryName?: string | null;
  telephone?: string | null;
  email?: string | null;
  tagsForResources?: string | null;
  label?: string | null;
}

export interface ValidationMessageInput {
  id?: any | null;
  version?: number | null;
  missionId?: any | null;
  type?: string | null;
  message?: string | null;
  messageParameters?: string | null;
  otherMissionId?: any | null;
  creationdate?: any | null;
}

export interface ValuesGroupInput {
  id?: any | null;
  version?: number | null;
  name?: string | null;
  type?: string | null;
  values?: string | null;
  unit?: UnitInput | null;
  organization?: OrganizationInput | null;
}

export interface ZoneInput {
  id?: any | null;
  version?: number | null;
  name?: string | null;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
