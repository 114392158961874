import { EntityDescriptor, EntityTableSimpleRRC, FieldDescriptor, Organization, Utils } from "@crispico/foundation-react";
import { apolloClient } from "@crispico/foundation-react/apolloClient";
import { AppMetaTempGlobals } from "@crispico/foundation-react/AppMetaTempGlobals";
import { Optional } from "@crispico/foundation-react/CompMeta";
import { ClientCustomQuery } from "@crispico/foundation-react/components/CustomQuery/ClientCustomQuery";
import { SplitPaneExt } from "@crispico/foundation-react/components/ReactSplitPaneExt/ReactSplitPaneExt";
import { entityDescriptors } from "@crispico/foundation-react/entity_crud/entityCrudConstants";
import { FieldRendererProps } from "@crispico/foundation-react/entity_crud/fieldRenderersEditors";
import DateFieldRenderer from "@crispico/foundation-react/entity_crud/fieldRenderers/DateFieldRenderer";
import { FieldType } from "@crispico/foundation-react/entity_crud/FieldType";
import { Reducers, ReduxReusableComponents, RRCProps, State } from "@crispico/foundation-react/reduxReusableComponents/ReduxReusableComponents"
import { ResponsiveBar } from "@nivo/bar";
import { Progress } from "antd";
import { missionEntityDescriptor } from "AppEntityDescriptors";
import _ from "lodash";
import moment from "moment";
import React, { ReactNode } from "react";
import { NavLink } from "react-router-dom";
import { Button, Dropdown, DropdownItemProps, DropdownProps, Header, Icon, Label, Loader, Popup, Segment } from "semantic-ui-react";
import { LOAD_REPORT_DATA_FOR_KPI_PAGE } from "./queries";
import Measure from "react-measure";
import { PieDatum, ResponsivePieExt } from "@crispico/foundation-react/components/nivoExt";
import { EntityDescriptorForServerUtils } from "@crispico/foundation-react/flower/entityDescriptorsForServer/EntityDescriptorForServerUtils";
import { Datum } from "@nivo/line";
import { FieldInterval, FieldIntervalColoring } from "@crispico/foundation-react/entity_crud/CrudSettings";
import { isIframe, XopsAppMeta } from "app";
import { getDropdownItemLabel } from "@crispico/foundation-react/entity_crud/fieldRenderers/DropdownFieldRenderer";
import { prepareKpiReport_taskService_prepareKpiReport as TaskKPIPageData, prepareKpiReport_taskService_prepareKpiReport_tasksForExport as TaskForExport } from "apollo-gen/prepareKpiReport";
import { PeriodPicker, PeriodPickerRRC, PeriodType } from "@crispico/foundation-react/components/periodPicker/PeriodPicker";

const ALL_ORGS = "ALL_ORGS";
const APU_OFF_COLOR = "#9740c9";
const OPERATION_DONE_COLOR = "limegreen";
const OPPORTUNITY_LOST_COLOR = "red";
const DEFAULT_BAR_CHART_COLOR = "#a6cee3";
const DEFAULT_BAR_CHART_SECOND_COLOR = "#ffc766";
const NO_REASON = "noReason";
const REASON_FIELD_NAME = "reason";
const DATE_FORMATS = { hour: Utils.dateTimeFormat, day: Utils.dateFormat, month: 'MM/YYYY', year: 'YYYY', fullPeriod: "" }
const taskTableEntityDescriptor = new EntityDescriptor({ name: "TaskKPIPageTaskExport" }, false)
    .addFieldDescriptor(getFieldDescriptorForDate("startTime"))
    .addFieldDescriptor(getFieldDescriptorForDate("endTime"))
    .addFieldDescriptor(getFieldDescriptorForDate("actualStartTime"))
    .addFieldDescriptor(getFieldDescriptorForDate("actualEndTime"))
    .addFieldDescriptor(getFieldDescriptorForDate("firstGaStartTime"))
    .addFieldDescriptor(getFieldDescriptorForDate("firstGaEndTime"))
    .addFieldDescriptor(getFieldDescriptorForDate("connectedTime"))
    .addFieldDescriptor(getFieldDescriptorForDate("disconnectedTime"))
    .addFieldDescriptor(getFieldDescriptorForDate("plannedConnectedTime"))
    .addFieldDescriptor(getFieldDescriptorForDate("plannedDisconnectedTime"))
    .addFieldDescriptor(getFieldDescriptorForDate("flightDate"))
    .addFieldDescriptor(getFieldDescriptorOverridingMeasurementUnitLabel("taskDuration", "m"))
    .addFieldDescriptor(getFieldDescriptorOverridingMeasurementUnitLabel("firstGaTaskDuration", "m"))
    .addFieldDescriptor(getFieldDescriptorOverridingMeasurementUnitLabel("operationTimeDone", "m"))
    .addFieldDescriptor(getFieldDescriptorOverridingMeasurementUnitLabel("opportunityLost", "%"))
    .addFieldDescriptor(getFieldDescriptorOverridingMeasurementUnitLabel("timeToConnect", "m"))
    .addFieldDescriptor({ name: "status", type: FieldType.string })
    .addFieldDescriptor({ name: REASON_FIELD_NAME, type: FieldType.string })
    .addFieldDescriptor({ name: "registration", type: FieldType.string })
    .addFieldDescriptor(getFieldDescriptorForLink("name", "id", "Task"))
    .addFieldDescriptor(getFieldDescriptorForLink("flightName", "flightId", "Flight"))
    .addFieldDescriptor(getFieldDescriptorForLink("plateNumber", "equipmentResourceId", "EquipmentResource"))
    .addFieldDescriptor(getFieldDescriptorForLink("ganttAssignmentUsed", "ganttAssignmentUsed", "GanttAssignment", false, "/gantt"))
    // next fields are displayed just in export CSV file
    .addFieldDescriptor(getFieldDescriptorForLink("id", "id", "Task", true))
    .addFieldDescriptor(getFieldDescriptorForLink("flightId", "flightId", "Flight", true))
    .addFieldDescriptor(getFieldDescriptorForLink("equipmentResourceId", "equipmentResourceId", "EquipmentResource", true))


function getFieldDescriptorForDate(fieldName: string): FieldDescriptor {
    return { name: fieldName, type: FieldType.date, additionalFieldRendererProps: FieldDescriptor.castAdditionalFieldRendererProps(DateFieldRenderer, { format: Utils.dateTimeWithSecFormat }) } as FieldDescriptor;
}

function getFieldDescriptorForLink(fieldName: string, entityIdField: string, entityDescriptorName: string, justForExport: boolean = false, additionalPath?: string): FieldDescriptor {
    return new class extends FieldDescriptor {
        constructor() {
            super();
            this.name = fieldName;
            this.type = FieldType.string;
            this.entityDescriptorForLink = entityDescriptorName;
            this.additionalPath = additionalPath;
            this.entityIdField = entityIdField;
            this.clientOnly = justForExport;
        }

        protected renderFieldInternal(RendererClass: any, props: FieldRendererProps, entity: any): ReactNode {
            const link = <NavLink to={entityDescriptors[entityDescriptorName].getEntityEditorUrl(entity[entityIdField]) + (additionalPath ? additionalPath : "")}>{entity[fieldName]}</NavLink>;
            return this.wrapComponent(entity, link);
        }
    }
}

function getFieldDescriptorOverridingMeasurementUnitLabel(name: string, measurementUnitSymbol: string): FieldDescriptor {
    return new class extends FieldDescriptor {
        constructor() {
            super();
            this.name = name;
            this.type = FieldType.string;
        }

        getMeasurementUnitLabel(getDefaultMeasurementUnitSymbol?: boolean | undefined): string | undefined {
            return measurementUnitSymbol;
        }
    }
}

enum BarChartPeriodType { HOUR = "hour", DAY = "day", MONTH = "month", YEAR = "year", FULL_PERIOD = "fullPeriod" }
enum KPIIds {
    APUOFF = "apuOff", RESIDUAL_APU_ON = "residualApuOn", OPPORTUNITY_LOST = "opportunityLost", OPERATION_DONE = "operationDone", WORST_ORG = "worstOrg",
    BEST_ORG = "bestOrg", TIME_TO_CONNECT = "timeToConnect", OPPORTUNITY_TIME = "opportunityTime", USE_TIME = "useTime", AVG_TOTAL_OF_USE_PER_MACHINE = "averageTotalOfUsePerMachine",
    AVG_TOTAL_OF_OPPORTUNITY_PER_MACHINE = "averageTotalOfOpportunityPerMachine", EQUIPMENT_RESOURCES_AVAILABLE = "equipmentResourcesAvailable", NB_OF_CALCULATION_DAYS_FULL_PERIOD = "numberOfCalcuationDaysForFullPeriod",
    COVERAGE = "coverage", TOTAL_TASKS_DURATION = "totalTasksDuration", TOTAL_OF_USE_TIME = "totalOfUseTime",
}

interface KpiField {
    rawValue: number;
    secondRawValue?: number;
    displayedValue: any;
    title: string;
    asProgress?: boolean;
    color?: string;
    measurementSymbol?: string;
    mainKpi?: boolean;
    hide?: boolean;
}

interface Sum {
    value: number;
    operandsNr: number;
}

type KpiSums = {
    sumOperationTimeDone: Sum,
    sumTasksDuration: Sum,
    sumFirstGanttAssignmentTasksDuration: Sum,
    sumEquipmentResourceConnectDuration: Sum,
    sumTreatedFlights: Sum,
    sumAllFlights: Sum,
}
type KpiSumsPerTimePeriod = { [key: string]: KpiSums }
type KpiSumsPerPrganization = { [key: string]: KpiSumsPerTimePeriod }

type KpiData = {
    apuOff: KpiField,
    operationDone: KpiField,
    opportunityLost: KpiField,
    residualApuOn: KpiField,
    timeToConnect: KpiField,
    opportunityTime: KpiField,
    totalOfUseTime: KpiField,
    useTime: KpiField,
    equipmentResourcesAvailable: KpiField,
    numberOfCalcuationDaysForFullPeriod: KpiField,
    coverage: KpiField,
    averageTotalOfUsePerMachine: KpiField,
    averageTotalOfOpportunityPerMachine: KpiField,
    totalTasksDuration: KpiField,
    worstOrg: KpiField,
    bestOrg: KpiField,
}
type KpiDataPerTimePeriod = { [key: string]: KpiData }
type KpiDataPerOrganization = { [key: string]: KpiDataPerTimePeriod }

type KPIBarChartData = { date: string, value: number, label?: string, measurementSymbol?: string, secondValue?: number, secondLabel?: string }
type OperationBarChartData = { org: string, operationDone: number, opportunityLost: number }

export class TaskKPIPageState extends State {
    kpiDataPerOrgs = {} as KpiDataPerOrganization;
    kpiSumsPerOrganization = {} as KpiSumsPerPrganization;
    operationTimeLostPerReason = {} as { [key: string]: Sum };
    tasksForExport = [] as TaskForExport[];
    equipmentsCountPerOrgs = {} as { [key: string]: number };
    numberOfCalculationDaysPerMonth = {} as { [key: string]: number };
    loading = false as boolean;
    needsRefresh = false as boolean;
    startDate = moment().startOf("month").valueOf() as number;
    endDate = moment().add(-1, "hour").valueOf() as number;
}

export class TaskKPIPageReducers extends Reducers<TaskKPIPageState> {
    resetNeededStateWhenRefresh() {
        this.s.kpiDataPerOrgs = {};
        this.s.kpiSumsPerOrganization = {};
        this.s.operationTimeLostPerReason = {};
        this.s.tasksForExport = [];
        this.s.equipmentsCountPerOrgs = {};
        this.s.numberOfCalculationDaysPerMonth = {};
        this.s.loading = true;
        this.s.needsRefresh = false;
    }
}

type PropsNotFromState = {
    customQuery: Optional<ClientCustomQuery>
};

type LocalState = {
    selectedPeriodForBarChart: BarChartPeriodType,
    selectedKpiId: keyof KpiData,
    showTable: boolean,
    measuredWidthFirstChart: number | undefined, measuredHeightSecondChart: number | undefined,
    compareKpiId: keyof KpiData | undefined,
    barChartIsGrouped: boolean,
}
export type Props = RRCProps<TaskKPIPageState, TaskKPIPageReducers> & PropsNotFromState;
export class TaskKPIPage extends React.Component<Props, LocalState> {
    
    private periodPickerRef = React.createRef<PeriodPicker>(); 

    constructor(props: Props) {
        super(props);
        this.state = {
            selectedKpiId: KPIIds.OPERATION_DONE,
            selectedPeriodForBarChart: BarChartPeriodType.MONTH,
            showTable: false,
            measuredWidthFirstChart: 0,
            measuredHeightSecondChart: 0,
            compareKpiId: undefined,
            barChartIsGrouped: true
        }
        this.refresh = this.refresh.bind(this);
    }

    async componentDidMount() {
        let displayForSas = (AppMetaTempGlobals.appMetaInstance as XopsAppMeta).getDisplayForSas();
        if ((isIframe() && displayForSas)) {
            // @ts-ignore
            await import("xops6-flex-mode.css");
        }

        this.refresh();
    }

    async componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<LocalState>): Promise<void> {
        if (!this.periodPickerRef.current?.props.s.startDate && !this.periodPickerRef.current?.props.s.endDate) {
            this.periodPickerRef.current?.props.r.setInReduxState({ periodType: PeriodType.CUSTOM, startDate: moment(this.props.s.startDate).toISOString(), endDate: moment(this.props.s.endDate).toISOString() });
        }
        
        // if period picker dates from state are initialized and onChange is executed the page needs refresh
        if (this.periodPickerRef.current?.props.s.startDate && this.periodPickerRef.current?.props.s.endDate &&
            (moment(this.periodPickerRef.current?.props.s.startDate).valueOf() != prevProps.s.startDate || moment(this.periodPickerRef.current?.props.s.endDate).valueOf() != prevProps.s.endDate)) {
            this.props.r.setInReduxState({ needsRefresh: true });
        }
    }

    protected async refresh() {
        this.props.r.resetNeededStateWhenRefresh();
        // Add try/catch because in the past existed a problem with the task query during GA recalculation, keep it to be sure we don't block the refresh in case of an unexpected error
        try {
            const reportData: TaskKPIPageData = (await apolloClient.query({
                query: LOAD_REPORT_DATA_FOR_KPI_PAGE,
                variables: { start: this.props.s.startDate, end: this.props.s.endDate, timezone: Utils.getTimeZone() },
                context: { showSpinner: false }
            })).data["taskService_prepareKpiReport"];
            this.props.r.setInReduxState({ ...reportData, tasksForExport: this.adjustTasksForExport(reportData.tasksForExport) });
            this.calculateKpiData();
        }
        catch (e) {
            // report error or calculate data error
        } finally {
            this.props.r.setInReduxState({ loading: false });
        }
    }

    protected adjustTasksForExport(tasksForExport: TaskForExport[]): TaskForExport[] {
        const tasksForExportResult = [];
        for (const t of tasksForExport) {
            // remove __typename from object 
            let { __typename, ...t_new } = t as any
            tasksForExportResult.push({
                ...t_new,
                startTime: moment(t.startTime).toISOString(),
                endTime: moment(t.endTime).toISOString(),
                actualStartTime: t.actualStartTime ? moment(t.actualStartTime).toISOString() : "",
                actualEndTime: t.actualEndTime ? moment(t.actualEndTime).toISOString() : "",
                firstGaStartTime: t.firstGaStartTime ? moment(t.firstGaStartTime).toISOString() : "",
                firstGaEndTime: t.firstGaEndTime ? moment(t.firstGaEndTime).toISOString() : "",
                flightDate: moment(t.flightDate).toISOString(),
                taskDuration: Math.round(moment.duration(t.taskDuration).as("minutes")).toString(),
                firstGaTaskDuration: Math.round(moment.duration(t.firstGaTaskDuration).as("minutes")).toString(),
                operationTimeDone: t.operationTimeDone ? Math.round(moment.duration(t.operationTimeDone).asMinutes()).toString() : "0",
                opportunityLost: t.opportunityLost ? Math.round(t.opportunityLost * 100) : 0,
                timeToConnect: t.timeToConnect ? Math.round(moment.duration(t.timeToConnect).as("minutes")).toString() : "0",
                status: _msg("Mission2.status." + (t.status ? t.status : "")),
                reason: this.getReasonLabel(t.reason ? t.reason : ""),
            } as TaskForExport);
        }

        return tasksForExportResult;
    }

    protected addToSum(sum: Sum, value?: number) {
        if (value) {
            sum.value += value;
            sum.operandsNr++;
        }
    }

    protected addSumToSum(sum: Sum, sum1: Sum) {
        sum.value += sum1.value;
        sum.operandsNr += sum1.operandsNr;
    }

    protected getInitialSums(): KpiSums {
        return {
            sumOperationTimeDone: { value: 0, operandsNr: 0 },
            sumTasksDuration: { value: 0, operandsNr: 0 },
            sumFirstGanttAssignmentTasksDuration: { value: 0, operandsNr: 0 },
            sumEquipmentResourceConnectDuration: { value: 0, operandsNr: 0 },
            sumTreatedFlights: { value: 0, operandsNr: 0 },
            sumAllFlights: { value: 0, operandsNr: 0 },
        }
    }

    protected addToKpiSums(sum: KpiSums, sum1: KpiSums) {
        this.addSumToSum(sum.sumOperationTimeDone, sum1.sumOperationTimeDone);
        this.addSumToSum(sum.sumTasksDuration, sum1.sumTasksDuration);
        this.addSumToSum(sum.sumFirstGanttAssignmentTasksDuration, sum1.sumFirstGanttAssignmentTasksDuration);
        this.addSumToSum(sum.sumEquipmentResourceConnectDuration, sum1.sumEquipmentResourceConnectDuration);
        this.addSumToSum(sum.sumTreatedFlights, sum1.sumTreatedFlights);
        this.addSumToSum(sum.sumAllFlights, sum1.sumAllFlights);
    }

    protected formatMilis(milis: number, format: "hour" | "minute") {
        // useGrouping is for not adding ',' after 3 digits values, e.g 5405h.20m -> 5,405h.20s with useGrouping as true
        // this format is important because can be converted to a number and use in chart
        return moment.duration(milis, "milliseconds").format(format == "hour" ? "h.m" : "m.s", { trim: false, useGrouping: false });
    }

    protected getKpiField(kpiId: string, sums: Optional<KpiSums>, orgQN: string, numberOfCalculationDays?: number): KpiField {
        if (!sums) {
            return { rawValue: 0, displayedValue: "", title: "" };
        }
        switch (kpiId) {
            case KPIIds.APUOFF: {
                const value = sums.sumTasksDuration.value > 0 ? sums.sumOperationTimeDone.value / sums.sumTasksDuration.value : 0;
                return { rawValue: Number(value.toFixed(2)), displayedValue: this.getPercentValue(value), title: _msg("TaskKPIPage.KPI.ApuOff"), asProgress: true, color: APU_OFF_COLOR, mainKpi: true };
            }
            case KPIIds.OPERATION_DONE: {
                const value = sums.sumFirstGanttAssignmentTasksDuration.value > 0 ? sums.sumOperationTimeDone.value / sums.sumFirstGanttAssignmentTasksDuration.value : 0;
                return { rawValue: value, secondRawValue: sums.sumOperationTimeDone.value, displayedValue: this.getPercentValue(value), title: _msg("TaskKPIPage.KPI.OperationDone"), asProgress: true, color: OPERATION_DONE_COLOR, mainKpi: true };
            }
            case KPIIds.OPPORTUNITY_LOST: {
                const rawValueInMillis = sums.sumFirstGanttAssignmentTasksDuration.value - sums.sumOperationTimeDone.value;
                const value = sums.sumFirstGanttAssignmentTasksDuration.value > 0 ? rawValueInMillis / sums.sumFirstGanttAssignmentTasksDuration.value : 0;
                return { rawValue: value, secondRawValue: rawValueInMillis, displayedValue: this.getPercentValue(value), title: _msg("TaskKPIPage.KPI.OpportunityLost"), asProgress: true, color: OPPORTUNITY_LOST_COLOR, mainKpi: true };
            }
            case KPIIds.RESIDUAL_APU_ON: {
                const value = sums.sumTasksDuration.value - sums.sumOperationTimeDone.value;
                return { rawValue: Number(value.toFixed(2)), displayedValue: this.formatMilis(value, "hour"), title: _msg("TaskKPIPage.KPI.ResidualApuOn"), measurementSymbol: 'h', mainKpi: true };
            }
            case KPIIds.TIME_TO_CONNECT: {
                const value = sums.sumEquipmentResourceConnectDuration.operandsNr > 0 ? sums.sumEquipmentResourceConnectDuration.value / sums.sumEquipmentResourceConnectDuration.operandsNr : 0;
                return { rawValue: Number(value.toFixed(2)), displayedValue: this.formatMilis(value, "minute"), title: _msg("TaskKPIPage.KPI.TimeToConnect"), measurementSymbol: 'm', mainKpi: true };
            }
            case KPIIds.OPPORTUNITY_TIME: {
                return { rawValue: sums.sumFirstGanttAssignmentTasksDuration.value, displayedValue: this.formatMilis(sums.sumFirstGanttAssignmentTasksDuration.value, "hour"), title: _msg("TaskKPIPage.KPI.OpportunityTime"), measurementSymbol: 'h' };
            }
            case KPIIds.USE_TIME: {
                const value = sums.sumOperationTimeDone.operandsNr > 0 ? sums.sumOperationTimeDone.value / sums.sumOperationTimeDone.operandsNr : 0;
                return { rawValue: Number(value.toFixed(2)), displayedValue: this.formatMilis(value, "hour"), title: _msg("TaskKPIPage.KPI.UseTime"), measurementSymbol: 'h' };
            }
            case KPIIds.EQUIPMENT_RESOURCES_AVAILABLE: {
                const value = this.props.s.equipmentsCountPerOrgs[orgQN] ? this.props.s.equipmentsCountPerOrgs[orgQN] : 0;
                return { rawValue: value, displayedValue: value, title: _msg("TaskKPIPage.KPI.EquipmentResourcesAvailable"), hide: true };
            }
            case KPIIds.NB_OF_CALCULATION_DAYS_FULL_PERIOD: {
                const value = numberOfCalculationDays ? numberOfCalculationDays : 0;
                return { rawValue: value, displayedValue: value, title: _msg("TaskKPIPage.KPI.NumberOfCalculationDays"), hide: true };
            }
            case KPIIds.COVERAGE: {
                const value = sums.sumAllFlights.value > 0 ? sums.sumTreatedFlights.value / sums.sumAllFlights.value : 0;
                return { rawValue: Number(value.toFixed(2)), displayedValue: sums.sumTreatedFlights.value + "/" + sums.sumAllFlights.value, title: _msg("TaskKPIPage.KPI.Coverage") };
            }
            case KPIIds.AVG_TOTAL_OF_USE_PER_MACHINE: {
                const value = this.props.s.equipmentsCountPerOrgs[orgQN] && this.props.s.equipmentsCountPerOrgs[orgQN] > 0 ? (sums.sumOperationTimeDone.value / this.props.s.equipmentsCountPerOrgs[orgQN]) / (numberOfCalculationDays ? numberOfCalculationDays : 1) : 0;
                return { rawValue: Number(value.toFixed(2)), displayedValue: this.formatMilis(value, "hour"), title: _msg("TaskKPIPage.KPI.AverageTotalOfUsePerMachine"), measurementSymbol: 'h' };
            }
            case KPIIds.AVG_TOTAL_OF_OPPORTUNITY_PER_MACHINE: {
                const value = this.props.s.equipmentsCountPerOrgs[orgQN] && this.props.s.equipmentsCountPerOrgs[orgQN] > 0 ? (sums.sumFirstGanttAssignmentTasksDuration.value / this.props.s.equipmentsCountPerOrgs[orgQN]) / (numberOfCalculationDays ? numberOfCalculationDays : 1) : 0;
                return { rawValue: Number(value.toFixed(2)), displayedValue: this.formatMilis(value, "hour"), title: _msg("TaskKPIPage.KPI.AverageTotalOfOpportunityPerMachine"), measurementSymbol: 'h' };
            }
            case KPIIds.TOTAL_TASKS_DURATION: {
                return { rawValue: sums.sumTasksDuration.value, displayedValue: this.formatMilis(sums.sumTasksDuration.value, "hour"), title: _msg("TaskKPIPage.KPI.TotalTasksDuration"), measurementSymbol: 'h', hide: true };
            }
            case KPIIds.TOTAL_OF_USE_TIME: {
                return { rawValue: sums.sumOperationTimeDone.value, displayedValue: this.formatMilis(sums.sumOperationTimeDone.value, "hour"), title: _msg("TaskKPIPage.KPI.TotalOfUseTime"), measurementSymbol: 'h' };
            }
            default : {
                return { rawValue: 0, displayedValue: "", title: "" };
            }
        }
    }

    // Change KPI-s dropdown/export order from here
    protected getInitialKpiData(): KpiData {
        return {
            // main kpi-s from left
            apuOff: this.getKpiField("", undefined, ""),
            residualApuOn: this.getKpiField("", undefined, ""),
            opportunityLost: this.getKpiField("", undefined, ""),
            operationDone: this.getKpiField("", undefined, ""),
            worstOrg: { rawValue: Number.MAX_SAFE_INTEGER, secondRawValue: Number.MAX_SAFE_INTEGER, displayedValue: "UNKNOWN", title: _msg("TaskKPIPage.KPI.WorstOrganization"), mainKpi: true },
            bestOrg: { rawValue: Number.MIN_SAFE_INTEGER, secondRawValue: Number.MIN_SAFE_INTEGER, displayedValue: "UNKNOWN", title: _msg("TaskKPIPage.KPI.BestOrganization"), mainKpi: true },
            // detail kpi-s from right
            timeToConnect: this.getKpiField("", undefined, ""),
            opportunityTime: this.getKpiField("", undefined, ""),
            totalOfUseTime: this.getKpiField("", undefined, ""),
            useTime: this.getKpiField("", undefined, ""),
            averageTotalOfUsePerMachine: this.getKpiField("", undefined, "", 1),
            averageTotalOfOpportunityPerMachine: this.getKpiField("", undefined, "", 1),
            equipmentResourcesAvailable: this.getKpiField("", undefined, ""),
            coverage: this.getKpiField("", undefined, ""),
            numberOfCalcuationDaysForFullPeriod: this.getKpiField("", undefined, ""),
            totalTasksDuration: this.getKpiField("", undefined, ""),
        }
    }

    protected calculateKpiData() {
        const kpiSumsPerOrgs: KpiSumsPerPrganization = this.props.s.kpiSumsPerOrganization;
        const numberOfCalculationDaysPerMonth: { [key: string]: number } = this.props.s.numberOfCalculationDaysPerMonth;
        let worstOperationDone: { [key: string]: number } = {}
        let bestOperationDone: { [key: string]: number } = {}
        const kpiDataPerOrgs: KpiDataPerOrganization = {};
        for (const org of Object.keys(kpiSumsPerOrgs)) {
            const sumsPerPeriod = kpiSumsPerOrgs[org];
            for (const period of Object.keys(sumsPerPeriod)) {
                const sums = sumsPerPeriod[period];
                kpiDataPerOrgs[org] = kpiDataPerOrgs[org] ? kpiDataPerOrgs[org] : {};
                kpiDataPerOrgs[org][period] = kpiDataPerOrgs[org][period] ? kpiDataPerOrgs[org][period] : this.getInitialKpiData();
                kpiDataPerOrgs[org][period].apuOff = this.getKpiField(KPIIds.APUOFF, sums, org);
                kpiDataPerOrgs[org][period].operationDone = this.getKpiField(KPIIds.OPERATION_DONE, sums, org);
                kpiDataPerOrgs[org][period].opportunityLost = this.getKpiField(KPIIds.OPPORTUNITY_LOST, sums, org);
                kpiDataPerOrgs[org][period].residualApuOn = this.getKpiField(KPIIds.RESIDUAL_APU_ON, sums, org);
                kpiDataPerOrgs[org][period].timeToConnect = this.getKpiField(KPIIds.TIME_TO_CONNECT, sums, org);
                kpiDataPerOrgs[org][period].opportunityTime = this.getKpiField(KPIIds.OPPORTUNITY_TIME, sums, org);
                kpiDataPerOrgs[org][period].totalOfUseTime = this.getKpiField(KPIIds.TOTAL_OF_USE_TIME, sums, org);
                kpiDataPerOrgs[org][period].useTime = this.getKpiField(KPIIds.USE_TIME, sums, org);
                kpiDataPerOrgs[org][period].equipmentResourcesAvailable = this.getKpiField(KPIIds.EQUIPMENT_RESOURCES_AVAILABLE, sums, org);
                kpiDataPerOrgs[org][period].coverage = this.getKpiField(KPIIds.COVERAGE, sums, org);
                kpiDataPerOrgs[org][period].averageTotalOfUsePerMachine = this.getKpiField(KPIIds.AVG_TOTAL_OF_USE_PER_MACHINE, sums, org, period == BarChartPeriodType.FULL_PERIOD ? numberOfCalculationDaysPerMonth[BarChartPeriodType.FULL_PERIOD] : 1);
                kpiDataPerOrgs[org][period].averageTotalOfOpportunityPerMachine = this.getKpiField(KPIIds.AVG_TOTAL_OF_OPPORTUNITY_PER_MACHINE, sums, org, period == BarChartPeriodType.FULL_PERIOD ? numberOfCalculationDaysPerMonth[BarChartPeriodType.FULL_PERIOD] : 1);
                kpiDataPerOrgs[org][period].totalTasksDuration = this.getKpiField(KPIIds.TOTAL_TASKS_DURATION, sums, org);

                // set worst/best org for ALL_ORGS per period
                if (org != ALL_ORGS) {
                    kpiDataPerOrgs[ALL_ORGS] = kpiDataPerOrgs[ALL_ORGS] ? kpiDataPerOrgs[ALL_ORGS] : {};
                    kpiDataPerOrgs[ALL_ORGS][period] = kpiDataPerOrgs[ALL_ORGS][period] ? kpiDataPerOrgs[ALL_ORGS][period] : this.getInitialKpiData();
                    kpiDataPerOrgs[org][period].worstOrg = { rawValue: kpiDataPerOrgs[org][period].operationDone.displayedValue, secondRawValue: kpiDataPerOrgs[org][period].operationDone.secondRawValue, displayedValue: org, title: _msg("TaskKPIPage.KPI.WorstOrganization"), mainKpi: true }
                    kpiDataPerOrgs[org][period].bestOrg = { rawValue: kpiDataPerOrgs[org][period].operationDone.displayedValue, secondRawValue: kpiDataPerOrgs[org][period].operationDone.secondRawValue, displayedValue: org, title: _msg("TaskKPIPage.KPI.WorstOrganization"), mainKpi: true }
                    if (kpiDataPerOrgs[org][period].operationDone.secondRawValue! < kpiDataPerOrgs[ALL_ORGS][period].worstOrg.secondRawValue!) {
                        kpiDataPerOrgs[ALL_ORGS][period].worstOrg = { rawValue: kpiDataPerOrgs[org][period].operationDone.displayedValue, secondRawValue: kpiDataPerOrgs[org][period].operationDone.secondRawValue, displayedValue: org, title: _msg("TaskKPIPage.KPI.WorstOrganization"), mainKpi: true }
                    }
                    if (kpiDataPerOrgs[org][period].operationDone.secondRawValue! > kpiDataPerOrgs[ALL_ORGS][period].bestOrg.secondRawValue!) {
                        kpiDataPerOrgs[ALL_ORGS][period].bestOrg = { rawValue: kpiDataPerOrgs[org][period].operationDone.displayedValue, secondRawValue: kpiDataPerOrgs[org][period].operationDone.secondRawValue, displayedValue: org, title: _msg("TaskKPIPage.KPI.BestOrganization"), mainKpi: true };
                    }
                }
            }
        }

        // calculate worst/best operation done for each organization
        for (const period of Object.keys(kpiDataPerOrgs[ALL_ORGS])) {
            const kpiFieldWorstOrg = kpiDataPerOrgs[ALL_ORGS][period].worstOrg;
            const kpiFieldBestOrg = kpiDataPerOrgs[ALL_ORGS][period].bestOrg;
            const worstOrgQN = kpiFieldWorstOrg.displayedValue as string;
            const bestOrgQN = kpiFieldBestOrg.displayedValue as string;
            worstOperationDone[worstOrgQN] = worstOperationDone[worstOrgQN] ? worstOperationDone[worstOrgQN] : 0;
            worstOperationDone[worstOrgQN] += kpiFieldWorstOrg.secondRawValue!;
            bestOperationDone[bestOrgQN] = bestOperationDone[bestOrgQN] ? bestOperationDone[bestOrgQN] : 0;
            bestOperationDone[bestOrgQN] += kpiFieldBestOrg.secondRawValue!;
        }

        // get max or min operation done and set for ALL_ORGS and FULL_PERIOD
        const worstOperationDoneValue = Object.entries(worstOperationDone).find(orgAndOperationDone => orgAndOperationDone[1] == _.minBy(Object.values(worstOperationDone)));
        const bestOperationDoneValue = Object.entries(bestOperationDone).find(orgAndOperationDone => orgAndOperationDone[1] == _.maxBy(Object.values(bestOperationDone)));
        kpiDataPerOrgs[ALL_ORGS][BarChartPeriodType.FULL_PERIOD].worstOrg = { ...kpiDataPerOrgs[ALL_ORGS][BarChartPeriodType.FULL_PERIOD].worstOrg, rawValue: worstOperationDoneValue![1], displayedValue: worstOperationDoneValue![0], mainKpi: true };
        kpiDataPerOrgs[ALL_ORGS][BarChartPeriodType.FULL_PERIOD].bestOrg = { ...kpiDataPerOrgs[ALL_ORGS][BarChartPeriodType.FULL_PERIOD].bestOrg, rawValue: bestOperationDoneValue![1], displayedValue: bestOperationDoneValue![0], mainKpi: true };
        kpiDataPerOrgs[ALL_ORGS][BarChartPeriodType.FULL_PERIOD].numberOfCalcuationDaysForFullPeriod = this.getKpiField("numberOfCalcuationDaysForFullPeriod", kpiSumsPerOrgs[ALL_ORGS][BarChartPeriodType.FULL_PERIOD], ALL_ORGS, numberOfCalculationDaysPerMonth[BarChartPeriodType.FULL_PERIOD]);
        
        this.props.r.setInReduxState({ kpiDataPerOrgs });
    }

    protected exportTasks() {
        const tasksForExport = this.props.s.tasksForExport;
        if (tasksForExport.length > 0) {
            let rows: any[] = [];
            rows.push(taskTableEntityDescriptor.orderedFields.map(key => {
                const measurementUnit = taskTableEntityDescriptor.getField(key).getMeasurementUnitLabel();
                const label = _msg("TaskKPIPageTaskExport." + key + ".label") + (measurementUnit ? " (" + measurementUnit + ")" : "");
                return label;
            }));
            tasksForExport.forEach(task => {
                rows.push(taskTableEntityDescriptor.orderedFields.map(key => {
                    const value = task[(key as keyof TaskForExport)];
                    const fd = taskTableEntityDescriptor.getField(key);
                    // export id-s which are hided from table as full link
                    if (fd.entityDescriptorForLink && fd.entityIdField == fd.name) {
                        return value ? window.location.origin + window.location.pathname + "#" + entityDescriptors[fd.entityDescriptorForLink].getEntityEditorUrl(value) + (fd.additionalPath ? fd.additionalPath : "") : "";
                    }
                    return value
                }));
            });
            Utils.exportToCsv("KPI_tasks", rows);
        }
    }

    protected exportKPIValues() {
        let rows: any[] = [];
        const kpiData = this.props.s.kpiDataPerOrgs[ALL_ORGS][BarChartPeriodType.FULL_PERIOD];
        if (Object.entries(kpiData).length > 0) {
            // displayed values as in UI
            Object.entries(kpiData).forEach(entry => {
                const key = entry[0];
                const value = entry[1];
                const measurementSymbol = value.measurementSymbol || (value.asProgress ? "%" : "");
                const displayedValue = this.getLabelValue(value.displayedValue, measurementSymbol);
                rows.push([value.title, displayedValue]);
                if (key == "operationDone" || key == "opportunityLost") {
                    const milis = Number(value.secondRawValue);
                    rows.push([value.title, this.formatMilis(milis, "hour") + "h"]);
                }
            })
            Utils.exportToCsv("KPI_values", rows);
        }
    }

    protected renderTopBar() {
        return <Segment className="less-padding less-margin-bottom flex-center flex-wrap justify-content-space-between gap3">             
            <div className="flex-container-row flex-center flex-wrap">
                <PeriodPickerRRC id="TaskKpiPagePeriodPicker" ref={this.periodPickerRef} initialValues={{ startDate: moment(this.props.s.startDate).toISOString(), endDate: moment(this.props.s.endDate).toISOString() }}
                    onChange={(startDate, endDate) => {
                        this.props.r.setInReduxState({ startDate: moment(startDate).valueOf(), endDate: moment(endDate).valueOf() });
                    }}></PeriodPickerRRC>  
            </div>           
            <div className="flex-container-row flex-center flex-wrap">
                <Popup trigger={<Button color={this.props.s.needsRefresh ? "purple" : "green"} size="small" icon="refresh" content={_msg("general.refresh")} disabled={this.props.s.loading} onClick={this.refresh} />}
                    content={_msg("TaskKPIPage.refresh.popup")} flowing position="top left" />
                <Button size="small" content={_msg("entityCrud.editor.table")} icon="database" primary={!this.state.showTable} onClick={() => this.setState({ showTable: !this.state.showTable })}></Button>
                <Button size="small" content={_msg("TaskKPIPage.exportKpisData.button.title")} icon="download" primary disabled={Object.keys(this.props.s.kpiDataPerOrgs).length == 0 || this.props.s.loading} onClick={() => this.exportKPIValues()}></Button>
                <Button size="small" content={_msg("dto_crud.export") + " " + _msg("Task.labels").toLowerCase()} icon="download" primary disabled={this.props.s.tasksForExport.length == 0 || this.props.s.loading} onClick={() => this.exportTasks()}></Button>
            </div>          
        </Segment>
    }

    protected renderTable() {
        return <EntityTableSimpleRRC id={"entityTableSimple_taskKPIPageTasks"} entityDescriptor={taskTableEntityDescriptor} entitiesAsParams={this.props.s.tasksForExport} />
           
    }

    protected renderKPI(kpi: KpiField): JSX.Element {
        return <Segment key={kpi.title} className={"flex-container flex-center flex-justify-content-end less-padding TaskKPIPage_KPI_element_segment no-margin "}>
            <div className={"flex-container flex-grow flex-justify-content-center"}>
                {kpi.asProgress
                    ? <Progress type={"dashboard"} percent={kpi.displayedValue}
                        status="active" strokeColor={kpi.color} size="small" width={70}
                        format={(percent?: number) => { return <Header size="medium">{percent + "%"}</Header> }}>
                    </Progress>
                    : <Popup trigger={<Header size="large" className="flex-container flex-center">
                        {this.getLabelValue(kpi.displayedValue, kpi.measurementSymbol)}</Header>}
                        content={this.getLabelValue(kpi.displayedValue, kpi.measurementSymbol)} position="top center" />
                }
            </div>
            <Label size="small" basic style={{textAlign: "center", width: "100%"}}>{kpi.title}</Label>
        </Segment>
    }

    protected getReasonColor(reason: string): string {
        const reasonFdChain: FieldDescriptor[] = missionEntityDescriptor.getFieldDescriptorChain(REASON_FIELD_NAME);
        if (!reasonFdChain[0]) {
            return "red";
        }
        const fieldColor = reasonFdChain[0].getFieldColors(reason);
        return fieldColor.backgroundColor || fieldColor.color || fieldColor.bulletColor || "gray";
    }

    protected getReasonFieldIntervals(): FieldInterval[] {
        const reasonFdChain: FieldDescriptor[] = missionEntityDescriptor.getFieldDescriptorChain(REASON_FIELD_NAME);
        if (reasonFdChain[0] && reasonFdChain[0].fieldDescriptorSettings && reasonFdChain[0].fieldDescriptorSettings.fieldIntervals) {
            return reasonFdChain[0].fieldDescriptorSettings.fieldIntervals.concat([{ from: NO_REASON, color: "gray", applyColorTo: FieldIntervalColoring.BACKGROUND } as FieldInterval]);
        }
        return [];
    }

    protected getReasonLabel(value: string): string {
        if (value == NO_REASON) {
            return "Unknown reason";
        }
        const reasonFd = EntityDescriptorForServerUtils.getFieldDescriptor(missionEntityDescriptor.name, REASON_FIELD_NAME);
        if (!reasonFd) {
            return value;
        }
        const fieldInterval = reasonFd?.getFieldInterval(value);
        if (!fieldInterval) {
            return value;
        }
        return getDropdownItemLabel(reasonFd, fieldInterval);
    }

    protected renderOperationLostKPI(): JSX.Element {
        let operationTimeLostForAllReasons: number = 0;
        const operationTimeLostPerReason = this.props.s.operationTimeLostPerReason;
        Object.values(operationTimeLostPerReason).forEach((sum: Sum) => operationTimeLostForAllReasons += sum.value);
        const data: PieDatum[] = Object.entries(operationTimeLostPerReason).map((entry, index) => {
            const reason = entry[0];
            const sumTime = entry[1];
            const value = operationTimeLostForAllReasons > 0 ? sumTime.value / operationTimeLostForAllReasons : 0;
            return {
                id: index,
                label: this.getReasonLabel(reason),
                value: this.getPercentValue(value),
                color: this.getReasonColor(reason),
            } as PieDatum
        });

        return <Segment className="PieCountByCriteriaWidgetContainer TaskKpiPage_Pie_segment flex-container flex-justify-content-end less-padding margin-auto">
            <div style={{ height: "95%" }}>
                <ResponsivePieExt data={data} animate={false} activeOuterRadiusOffset={0}
                    margin={{ left: 10, right: 10, top: 10, bottom: 90 }} enableArcLinkLabels={false}
                    arcLabel={(datum: Datum) => datum.value + "%"}
                    colors={(datum: Datum) => datum.data.color}
                    tooltip={({ datum: { label, value, color } }) => <div className='TaskKPIPage_Pie_tooltip'>
                        <div style={{ backgroundColor: color, width: '12px', height: '12px', borderRadius: '20px', display: 'inline-block' }}></div> {label + ":" + value + "%"}
                    </div>
                    }
                    legends={[{
                        anchor: "bottom-left", direction: "column", symbolShape: "circle", symbolSize: 12, itemWidth: 0, itemHeight: 0, translateY: 80, itemsSpacing: 14,
                        data: this.getReasonFieldIntervals().map((fi, index) => { return { id: index, label: this.getReasonLabel(fi.from), color: this.getReasonColor(fi.from) } }),
                    }]}
                />
            </div>
            <Label size="small" basic style={{ textAlign: "center", width: "100%" }}>{_msg("TaskKPIPage.KPI.LostOperation")}</Label>
        </Segment>
    }

    protected getLabelValue(value: string | number, measurementSymbol?: string) {
        return (value ? value : 0) + (measurementSymbol || "")
    }

    protected getPercentValue(value: number) {
        return Math.round(value * 100);
    }

    protected getKPIsBarChartData(): KPIBarChartData[] {
        const kpiId = this.state.selectedKpiId;
        const compareKpiId = this.state.compareKpiId;
        // e.g. period = 13/12/2011 08:00 -> hour = 13/12/2011 08:00, day = 13/12/2011, month = 12/2011, year = 2011
        const dateFormat = DATE_FORMATS[this.state.selectedPeriodForBarChart];
        if (kpiId == KPIIds.WORST_ORG || kpiId == KPIIds.BEST_ORG) {
            const kpiDataPerTimePeriod: KpiDataPerTimePeriod = this.props.s.kpiDataPerOrgs[ALL_ORGS];
            let worstOperationDonePerPeriodAndOrg: { [key: string]: { [key: string]: number } } = {}
            let bestOperationDonePerPeriodAndOrg: { [key: string]: { [key: string]: number } } = {}
            for (const period of Object.keys(kpiDataPerTimePeriod).sort((a,b) => a.localeCompare(b))) {
                const kpiFieldWorstOrg = kpiDataPerTimePeriod[period].worstOrg;
                const kpiFieldBestOrg = kpiDataPerTimePeriod[period].bestOrg;
                const worstOrgQN = kpiFieldWorstOrg.displayedValue as string;
                const bestOrgQN = kpiFieldBestOrg.displayedValue as string;
                if (period == BarChartPeriodType.FULL_PERIOD) {
                    continue;
                }
                const periodFormated = moment(period, Utils.dateTimeFormat).format(dateFormat);
                worstOperationDonePerPeriodAndOrg[periodFormated] = worstOperationDonePerPeriodAndOrg[periodFormated] ? worstOperationDonePerPeriodAndOrg[periodFormated] : {};
                bestOperationDonePerPeriodAndOrg[periodFormated] = bestOperationDonePerPeriodAndOrg[periodFormated] ? bestOperationDonePerPeriodAndOrg[periodFormated] : {};
                worstOperationDonePerPeriodAndOrg[periodFormated][worstOrgQN] = worstOperationDonePerPeriodAndOrg[periodFormated][worstOrgQN] ? worstOperationDonePerPeriodAndOrg[periodFormated][worstOrgQN] : 0;
                worstOperationDonePerPeriodAndOrg[periodFormated][worstOrgQN] += kpiFieldWorstOrg.secondRawValue!;
                bestOperationDonePerPeriodAndOrg[periodFormated][bestOrgQN] = bestOperationDonePerPeriodAndOrg[periodFormated][bestOrgQN] ? bestOperationDonePerPeriodAndOrg[periodFormated][bestOrgQN] : 0;
                bestOperationDonePerPeriodAndOrg[periodFormated][bestOrgQN] += kpiFieldBestOrg.secondRawValue!;
            }

            return Object.entries(kpiId == KPIIds.WORST_ORG ? worstOperationDonePerPeriodAndOrg : bestOperationDonePerPeriodAndOrg).map(periodAndOrgMap => {
                const orgAndValueInMillis: [string, number] = Object.entries(periodAndOrgMap[1]).find(orgAndOperationDone => orgAndOperationDone[1] == (kpiId == KPIIds.WORST_ORG ? _.minBy(Object.values(periodAndOrgMap[1])) : _.maxBy(Object.values(periodAndOrgMap[1]))))!;
                const data = { date: periodAndOrgMap[0], value: Number(this.formatMilis(orgAndValueInMillis[1], "hour")), label: orgAndValueInMillis[0], measurementSymbol: 'h' }
                if (!compareKpiId) {
                    return data;
                }
                const secondCompareOperationDonePerOrg = compareKpiId == KPIIds.BEST_ORG ? bestOperationDonePerPeriodAndOrg[periodAndOrgMap[0]] : worstOperationDonePerPeriodAndOrg[periodAndOrgMap[0]];
                const secondOrgAndValueInMillis: [string, number] = Object.entries(secondCompareOperationDonePerOrg).find(orgAndOperationDone => orgAndOperationDone[1] == (compareKpiId == KPIIds.WORST_ORG ? _.minBy(Object.values(secondCompareOperationDonePerOrg)) : _.maxBy(Object.values(secondCompareOperationDonePerOrg))))!;
                return { ...data, secondValue: Number(this.formatMilis(secondOrgAndValueInMillis[1], "hour")), secondLabel: secondOrgAndValueInMillis[0] };
            })
        }

        const numberOfCalculationDaysPerMonth = this.props.s.numberOfCalculationDaysPerMonth;
        const numberOfCalculationDaysPerYear: { [key: string]: number } = {};
        Object.keys(numberOfCalculationDaysPerMonth).forEach(monthAndYear => {
            const year = monthAndYear.split("/")[1];
            numberOfCalculationDaysPerYear[year] = numberOfCalculationDaysPerYear[year] ? numberOfCalculationDaysPerYear[year] : 0;
            numberOfCalculationDaysPerYear[year] += numberOfCalculationDaysPerMonth[monthAndYear];
        });
        const kpiSumsPerTimePeriod: KpiSumsPerTimePeriod = this.props.s.kpiSumsPerOrganization[ALL_ORGS];
        const kpiSums: { [key: string]: KpiSums } = {};
        for (const sumPeriod of Object.entries(kpiSumsPerTimePeriod).sort((a,b) => a[0].localeCompare(b[0]))) {
            const period = sumPeriod[0];
            if (period == BarChartPeriodType.FULL_PERIOD) {
                continue;
            }
            const periodFormated = moment(period, Utils.dateTimeFormat).format(dateFormat);
            kpiSums[periodFormated] = kpiSums[periodFormated] ? kpiSums[periodFormated] : this.getInitialSums();
            this.addToKpiSums(kpiSums[periodFormated], sumPeriod[1]);
        }

        return Object.entries(kpiSums).map(periodSum => {
            const period = periodSum[0];
            let numberOfCalcuationDays = 1;
            if (this.state.selectedPeriodForBarChart == BarChartPeriodType.MONTH) {
                numberOfCalcuationDays = numberOfCalculationDaysPerMonth[period];
            } else if (this.state.selectedPeriodForBarChart == BarChartPeriodType.YEAR) {
                numberOfCalcuationDays = numberOfCalculationDaysPerYear[period];
            }
            const kpiField = this.getKpiField(kpiId, periodSum[1], ALL_ORGS, numberOfCalcuationDays);
            const value: number = kpiId == "coverage" ? this.getPercentValue(kpiField.rawValue) : kpiField.displayedValue;
            const measurementSymbol = kpiId == "coverage" ? "%" : kpiField.asProgress ? "%" : kpiField.measurementSymbol;
            const data = { date: period, value: value, measurementSymbol: measurementSymbol, label: kpiField.displayedValue };
            if (!compareKpiId) {
                return data
            }
            const compareKpiField = this.getKpiField(compareKpiId, periodSum[1], ALL_ORGS, numberOfCalcuationDays);
            const compareValue: number = compareKpiId == "coverage" ? this.getPercentValue(compareKpiField.rawValue) : compareKpiField.displayedValue;
            return { ...data, secondValue: compareValue, secondLabel: compareKpiField.displayedValue }
        });
    }

    protected getSecondBarChartData(): OperationBarChartData[] {
        const currentOrganization: Optional<Organization> = AppMetaTempGlobals.appMetaInstance.getCurrentOrganization();
        // 1/ org = AZUL.GRU data just from GRU airport 2/ org = AZUL data from all airports 3/ org = null org means data for all first level organizations from all airports
        const searchedOrg = currentOrganization ? currentOrganization?.qualifiedName : undefined;
        const orgKeys = searchedOrg ? Object.keys(this.props.s.kpiDataPerOrgs).filter(org => org.startsWith(searchedOrg)) : Object.keys(this.props.s.kpiDataPerOrgs);
        if (orgKeys.indexOf(ALL_ORGS) != -1) {
            orgKeys.splice(orgKeys.indexOf(ALL_ORGS), 1);
        }
        const barChartData: { [key: string]: { totalTime: Sum, operationDone: Sum, opportunityLost: Sum } } = {};
        for (const org of orgKeys) {
            const orgFirstLevel = org.split(".")[0];
            const kpiData: KpiData = this.props.s.kpiDataPerOrgs[org][BarChartPeriodType.FULL_PERIOD];
            const opportunityTime = kpiData[KPIIds.OPPORTUNITY_TIME];
            const operationDone = kpiData[KPIIds.OPERATION_DONE];
            const opportunityLost = kpiData[KPIIds.OPPORTUNITY_LOST]
            barChartData[orgFirstLevel] = barChartData[orgFirstLevel] ? barChartData[orgFirstLevel] : { totalTime: { value: 0, operandsNr: 0 }, operationDone: { value: 0, operandsNr: 0 }, opportunityLost: { value: 0, operandsNr: 0 } };
            this.addToSum(barChartData[orgFirstLevel].totalTime, opportunityTime.rawValue);
            this.addToSum(barChartData[orgFirstLevel].operationDone, operationDone.secondRawValue);
            this.addToSum(barChartData[orgFirstLevel].opportunityLost, opportunityLost.secondRawValue);
        }

        return Object.entries(barChartData).map(data => {
            return {
                org: data[0],
                operationDonePercent: this.getPercentValue(data[1].totalTime.value > 0 ? data[1].operationDone.value / data[1].totalTime.value : 0),
                opportunityLostPercent: this.getPercentValue(data[1].totalTime.value > 0 ? data[1].opportunityLost.value / data[1].totalTime.value : 0),
                operationDone: data[1].operationDone.value, opportunityLost: data[1].opportunityLost.value
            }
        })
    }

    protected getFormattedDateForBarChart(dateAsString: string): string {
        if (this.state.selectedPeriodForBarChart == BarChartPeriodType.HOUR) {
            return moment(dateAsString, DATE_FORMATS[this.state.selectedPeriodForBarChart]).format(Utils.timeFormat);
        } else if (this.state.selectedPeriodForBarChart == BarChartPeriodType.DAY) {
            return moment(dateAsString, DATE_FORMATS[this.state.selectedPeriodForBarChart]).format(Utils.dateFormatShorter);
        } else return dateAsString;
    }

    protected renderBarCharts(data: KpiData) {
        const options: DropdownItemProps[] = [];
        const compareOptions: DropdownItemProps[] = [];
        const progressKpi = data[this.state.selectedKpiId].asProgress;
        const measurementSymbol = data[this.state.selectedKpiId].measurementSymbol;
        (_.sortBy(Object.keys(data), "mainKpi") as (keyof KpiData)[]).filter(key => !data[key as keyof KpiData].hide).forEach(key => {
            if (this.state.selectedKpiId == KPIIds.BEST_ORG && compareOptions.length == 0) {
                compareOptions.push({ text: data[KPIIds.WORST_ORG].title, value: KPIIds.WORST_ORG })
            } else if (this.state.selectedKpiId == KPIIds.WORST_ORG && compareOptions.length == 0) {
                compareOptions.push({ text: data[KPIIds.BEST_ORG].title, value: KPIIds.BEST_ORG })
            } else if (this.state.selectedKpiId != key && ((progressKpi && data[key].asProgress) || (measurementSymbol && measurementSymbol == data[key].measurementSymbol))) {
                compareOptions.push({ text: data[key].title, value: key })
            }
            options.push({ text: data[key].title, value: key });
        })
        const firstBarChartData = this.getKPIsBarChartData();
        const secondBarChartData = this.getSecondBarChartData();
        const margin = 50;        
        const width = (this.state.barChartIsGrouped && this.state.compareKpiId ? 2 : 1) * (35 * firstBarChartData.length) + margin;
        const height = (35 * secondBarChartData.length) + margin;
        const firstStyle = this.state.measuredWidthFirstChart && this.state.measuredWidthFirstChart >= width ? { maxWidth: width + 20 } : { minWidth: width };
        const secondStyle = this.state.measuredHeightSecondChart && this.state.measuredHeightSecondChart >= height ? { maxHeight: height } : { minHeight: height };
        return <Segment className="flex-container flex-grow no-padding no-margin" style={{ minWidth: "600px", minHeight: "500px" }} basic>
            <Segment className="flex-container less-padding less-margin-top-bottom" style={{ height: "70%" }} compact size="small">
                <div className="flex-container-row less-padding less-margin-top-bottom flex-center flex-wrap justify-content-space-between gap3">
                    <div className="flex-container-row flex-center gap5">
                        <div className="flex-container-row flex-center gap3">
                            {_msg("TaskKPIPage.selectedKpiId")}:
                            <Dropdown as={Button} defaultValue={this.state.selectedKpiId} selection options={options}
                                onChange={(event: React.SyntheticEvent<HTMLElement>, data: DropdownProps) => this.setState({ selectedKpiId: data.value as keyof KpiData, compareKpiId: undefined })} />
                        </div>
                        <div className="flex-container-row flex-center gap3">
                            {_msg("TaskKPIPage.compareKpiId")}: 
                            <Dropdown as={Button} value={this.state.compareKpiId || []} selection options={compareOptions} clearable
                                onChange={(event: React.SyntheticEvent<HTMLElement>, data: DropdownProps) => this.setState({ compareKpiId: data.value as keyof KpiData })} />
                        </div>
                    </div>
                    <Button.Group>
                        <Button primary={this.state.selectedPeriodForBarChart == BarChartPeriodType.YEAR} onClick={() => this.setState({ selectedPeriodForBarChart: BarChartPeriodType.YEAR })}>{_msg("TaskKPIPage.yearly")}</Button>
                        <Button primary={this.state.selectedPeriodForBarChart == BarChartPeriodType.MONTH} onClick={() => this.setState({ selectedPeriodForBarChart: BarChartPeriodType.MONTH })}>{_msg("TaskKPIPage.monthly")}</Button>
                        <Button primary={this.state.selectedPeriodForBarChart == BarChartPeriodType.DAY} onClick={() => this.setState({ selectedPeriodForBarChart: BarChartPeriodType.DAY })}>{_msg("TaskKPIPage.daily")}</Button>
                        <Button primary={this.state.selectedPeriodForBarChart == BarChartPeriodType.HOUR} onClick={() => this.setState({ selectedPeriodForBarChart: BarChartPeriodType.HOUR })}>{_msg("TaskKPIPage.hourly")}</Button>
                    </Button.Group>                    
                    <Button.Group>
                        <Button primary={this.state.barChartIsGrouped} onClick={() => this.setState({ barChartIsGrouped: true })}>{_msg("HistoryTrackReport.grouped")}</Button>
                        <Button primary={!this.state.barChartIsGrouped} onClick={() => this.setState({ barChartIsGrouped: false })}>{_msg("HistoryTrackReport.stacked")}</Button>
                    </Button.Group>   
                </div>
                {/* first bar chart with details for selected KPI */}
                <Measure bounds onResize={contentRect => this.setState({ measuredWidthFirstChart: contentRect.bounds?.width })}>
                    {({ measureRef }) => (
                        <div className="HistogramWithDetails_pieContainer" style={{ overflowX: 'auto' }} ref={measureRef}>
                            <div className="wh100" style={firstStyle}>
                                <ResponsiveBar
                                    data={firstBarChartData} indexBy="date" keys={["value"].concat(this.state.compareKpiId ? ["secondValue"] : [])} 
                                    groupMode={this.state.barChartIsGrouped ? "grouped" : "stacked"} innerPadding={1} padding={0.2}
                                    margin={{ top: 20, right: 20, left: margin, bottom: 40 }} borderRadius={3} borderWidth={1}
                                    colors={[data[this.state.selectedKpiId].color ? data[this.state.selectedKpiId].color! : DEFAULT_BAR_CHART_COLOR,
                                        this.state.compareKpiId && data[this.state.compareKpiId].color ? data[this.state.compareKpiId].color! : DEFAULT_BAR_CHART_SECOND_COLOR]} 
                                    borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}                                   
                                    axisBottom={{ tickSize: 5, tickPadding: 5, tickRotation: 0, format: (value: any) => this.getFormattedDateForBarChart(value) }}
                                    axisLeft={{ tickSize: 5, tickPadding: 10, tickRotation: 0, format: (value: any) => value + (progressKpi ? "%" : (measurementSymbol ? measurementSymbol : "")) }}
                                    tooltip={(value: any) => <>
                                        <div style={{ transform: value.x <= 250 ? "translate(0, 0)" : "translate(-200px, 0)" }} >
                                            <div style={{ position: 'absolute', background: 'white', padding: '5px 9px', borderRadius: '2px', boxShadow: 'rgba(0, 0, 0, 0.25) 0px 1px 2px' }}>
                                                <div style={{ display: "flex", alignItems: 'center', whiteSpace: 'pre' }} key={value.id}>
                                                    <span style={{ color: value.color as any }}><strong>{this.getLabelValue(value.data[value.id], value.data.measurementSymbol)}</strong></span>
                                                    <span> [{data[value.id == "value" ? this.state.selectedKpiId : this.state.compareKpiId || this.state.selectedKpiId].title} - {value.data.date}]</span>
                                                </div>
                                            </div></div>
                                    </>}
                                    label={(value: any) => this.getLabelValue(value.data[value.id == "value" ? "label" : "secondLabel"], !["worstOrg", "bestOrg", "coverage"].includes(this.state.selectedKpiId) ? value.data.measurementSymbol : "")}
                                    labelSkipWidth={12} labelSkipHeight={12} labelTextColor={{ from: 'color', modifiers: [['darker', 8]] }}
                                    animate={false}
                                />
                            </div>
                        </div>
                    )}
                </Measure>
            </Segment>
            {/* second bar chart per organization for OperationTimeDone and OpportunityLost*/}
            <Measure bounds onResize={contentRect => this.setState({ measuredHeightSecondChart: contentRect.bounds?.height })}>
                {({ measureRef }) => (
                    <Segment className="less-padding less-margin-bottom no-margin-top" style={{ height: "30%", minHeight: "100px" }}>
                        <div className="HistogramWithDetails_pieContaine wh100" style={{ overflowY: 'auto' }} ref={measureRef}>
                            <div className="wh100" style={secondStyle}>
                                <ResponsiveBar
                                    data={secondBarChartData} keys={['operationDonePercent', 'opportunityLostPercent']} indexBy="org" groupMode={"stacked"} layout="horizontal"
                                    margin={{ right: 20, left: 50, top: margin }} innerPadding={1} padding={0.2} borderRadius={3} borderWidth={1} minValue={0} maxValue={100}
                                    colors={[OPERATION_DONE_COLOR, OPPORTUNITY_LOST_COLOR]} borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
                                    axisTop={{ tickSize: 5, tickPadding: 5, tickRotation: 0, format: (value: any) => value + "%" }} axisBottom={null}
                                    axisLeft={{ tickSize: 5, tickPadding: 10, tickRotation: 0 }}
                                    labelSkipWidth={12} labelSkipHeight={12} labelTextColor={{ from: 'color', modifiers: [['darker', 8]] }}
                                    label={(value: any) => value.data[value.id] + "%"}
                                    tooltip={(value: any) => <>
                                        <div style={{ transform: value.x <= 250 ? "translate(0, 0)" : "translate(-200px, 0)" }} >
                                            <div style={{ position: 'absolute', background: 'white', padding: '5px 9px', borderRadius: '2px', boxShadow: 'rgba(0, 0, 0, 0.25) 0px 1px 2px' }}>
                                                <div style={{ display: "flex", alignItems: 'center', whiteSpace: 'pre' }} key={value.id}>
                                                    <span style={{ color: value.color as any }}><strong>{moment.duration(value.data[value.id.split("Percent")[0]]).asHours().toFixed(1) + "h "}</strong></span>
                                                    <span> [{_msg("TaskKPIPage.KPI." + _.upperFirst(value.id.split("Percent")[0]))} - {value.indexValue}]</span>
                                                </div>
                                            </div></div>
                                    </>}
                                    animate={false}
                                />
                            </div>
                        </div>
                    </Segment>
                )}
            </Measure>
        </Segment>
    }

    render() {
        const kpiData = this.props.s.kpiDataPerOrgs[ALL_ORGS] && Object.keys(this.props.s.kpiDataPerOrgs[ALL_ORGS]).length > 0 ? this.props.s.kpiDataPerOrgs[ALL_ORGS][BarChartPeriodType.FULL_PERIOD] : undefined
        const kpiContent = kpiData
            ? <div className="flex-container-row flex-grow gap5">
                <div className="flex-container less-margin-top-bottom gap5">
                    <div className="flex-container-row gap5">
                        {this.renderKPI(kpiData[KPIIds.APUOFF])}
                        {this.renderKPI(kpiData[KPIIds.RESIDUAL_APU_ON])}
                    </div>
                    <div className="flex-container-row gap5">
                        {this.renderKPI(kpiData[KPIIds.OPPORTUNITY_LOST])}
                        {this.renderKPI(kpiData[KPIIds.WORST_ORG])}
                    </div>
                    <div className="flex-container-row gap5">
                        {this.renderKPI(kpiData[KPIIds.OPERATION_DONE])}
                        {this.renderKPI(kpiData[KPIIds.BEST_ORG])}
                    </div>
                    {this.renderOperationLostKPI()}
                </div>
                {this.renderBarCharts(kpiData)}
                <div className="flex-container less-margin-top-bottom gap5">
                    {this.renderKPI(kpiData[KPIIds.TIME_TO_CONNECT])}
                    {this.renderKPI(kpiData[KPIIds.OPPORTUNITY_TIME])}
                    {this.renderKPI(kpiData[KPIIds.TOTAL_OF_USE_TIME])}
                    {this.renderKPI(kpiData[KPIIds.USE_TIME])}
                    {this.renderKPI(kpiData[KPIIds.AVG_TOTAL_OF_USE_PER_MACHINE])}
                    {this.renderKPI(kpiData[KPIIds.AVG_TOTAL_OF_OPPORTUNITY_PER_MACHINE])}
                    {this.renderKPI(kpiData[KPIIds.EQUIPMENT_RESOURCES_AVAILABLE])}
                    {this.renderKPI(kpiData[KPIIds.NB_OF_CALCULATION_DAYS_FULL_PERIOD])}
                    {this.renderKPI(kpiData[KPIIds.COVERAGE])}
                </div>
            </div>
            : <Segment className="HistoryCompare_noData" size="big" inverted color="orange" compact ><Icon name="exclamation triangle" /> {this.props.s.needsRefresh ? _msg("TaskKPIPage.refresh.popup") : _msg("TaskKPIPage.noData") }</Segment>
        return <div className="flex-container flex-grow less-padding">
                    {this.renderTopBar()}
                    {this.props.s.loading
                        ? <Loader active size="big">{_msg("general.loading")}</Loader>
                        : this.state.showTable ?
                            <SplitPaneExt size="50%" split="vertical" >
                                {kpiContent}                               
                                {this.renderTable()}
                            </SplitPaneExt> : kpiContent
                    }
                </div>
    }
}

export const TaskKPIPageRRC = ReduxReusableComponents.connectRRC(TaskKPIPageState, TaskKPIPageReducers, TaskKPIPage);