import { addEntityDescriptor, EntityDescriptor, Utils } from "@crispico/foundation-react";
import { Filter } from "@crispico/foundation-react/components/CustomQuery/Filter";
import { FilterOperators } from "@crispico/foundation-gwt-js";
import { FieldType } from "@crispico/foundation-react/entity_crud/FieldType";

export const flightScheduleEntityDescriptor = addEntityDescriptor(new EntityDescriptor({
    name: "FlightSchedule",
    miniFields: ["airline", "flightNumber", "airport.code", "startDate", "endDate"],
    icon: "plane"
})
    .addFieldDescriptor({ name: "id", type: FieldType.number, enabled: false })
    .addFieldDescriptor({ name: "airline", type: FieldType.string })
    .addFieldDescriptor({ name: "flightNumber", type: FieldType.string })
    .addFieldDescriptor({ name: "startDate", type: FieldType.string })
    .addFieldDescriptor({ name: "endDate", type: FieldType.string })
    .addFieldDescriptor({ name: "airport", type: "Airport" })
    .addFieldDescriptor({ name: "departure", type: FieldType.boolean })
    .addFieldDescriptor({ name: "daysOfTheWeek", type: FieldType.string })
    .addFieldDescriptor({ name: "rotation", type: "FlightSchedule" })
    .addFieldDescriptor({ name: "targetForThisException", type: "FlightSchedule" })
    .addFieldDescriptor({ name: "rotationDuration", type: FieldType.number })
    .addFieldDescriptor({ name: "deactivatedAt", type: FieldType.date })
    .addFieldDescriptor({ name: "properties", type: FieldType.text, sortable: false, enabled: false })
    .addTabDescriptor({
        oneToManyEntityName: "Flight", oneToManyOppositeField: "flightSchedule", oneToManyDefaultFilter: Filter.createComposedForClient(FilterOperators.forComposedFilter.and, []), oneToManyDefaultSort: { field: "date", direction: "DESC" }
    })
);
