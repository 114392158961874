import { FilterOperators } from "@crispico/foundation-gwt-js";
import { EditMode, EntityEditorFormSimple, EntityEditorPage, EntityEditorPageProps, EntityTablePage, EntityTablePageProps } from "@crispico/foundation-react";
import { Filter } from "@crispico/foundation-react/components/CustomQuery/Filter";
import { TabRouterPane } from "@crispico/foundation-react/components/TabbedPage/TabbedPage";
import { entityDescriptors } from "@crispico/foundation-react/entity_crud/entityCrudConstants";
import { EntityDescriptor, FieldDescriptor } from "@crispico/foundation-react/entity_crud/EntityDescriptor";
import { FieldEditorProps, FieldRendererProps } from "@crispico/foundation-react/entity_crud/fieldRenderersEditors";
import StringFieldRenderer from "@crispico/foundation-react/entity_crud/fieldRenderers/StringFieldRenderer";
import { FieldType } from "@crispico/foundation-react/entity_crud/FieldType";
import { ShareLinkLogic } from "@crispico/foundation-react/entity_crud/ShareLinkLogic";
import { FormikProps } from "formik";
import _, { clone } from "lodash";
import { default as React, ReactNode } from "react";
import { NavLink } from "react-router-dom";
import { Form, Checkbox, Grid, Label, Segment } from "semantic-ui-react";
import ColorFieldRenderer from "@crispico/foundation-react/entity_crud/fieldRenderers/ColorFieldRenderer";

export const conditionFieldsKeys = ["airport", "airline", "number", "startTime", "endTime", "daysOfTheWeek", "origin", "destination", "departure", "planeType",
    "terminal", "schengen", "parkingIsContact", "flightType", "canceled", "sariaPort", "paxType", "parking", "busType", "degradedModeCondition", "unit", "registrations",
    "groundTimeLower", "groundTimeHigher", "groundAgent", "originGroup", "destinationGroup", "planeTypeGroup", "planeTypeList", "flightAndRotationSameUnit", "nightStop",
    "overlapEscaleGroup", "overlapEscale", "matriculationGroup", "withRotation", "cycleDepartureOrder", "customObjectCode", "crewShuttle", "parkingGroup", "lowerStaffNumber",
    "upperStaffNumber", "flightVersion"];
export const modificationFieldsKeys = ["showFlightInList", "showFlightInGantt", "oilGroup", "is3N", "flightOffset", "color", "invoiceAccount", "flightComment",
    "degradedModeModification", "flightUnit", "flightPriority", "blockObjectCreation", "flightSchengen"];
export const objcreationFieldsKeys = ["useObjectCreation", "taskType", "quantity", "objectName", "objectPriority", "objectOffset", "duration", "objectAlternateParking", "objectParking", "objectGalley", "objectApproachNumber", "objectMessageCreation", "vraIndex", "objectExceptionEvent", "objectFinishMissionEvent",
    "eventMonitoringRulesJson", "requiredEquipmentResourceQualificationType", "equipmentResourceFillPercentage", "emrjUseRotationFlightAsReference", "objectOffsetForRotationFlight"];

const CLIENT_COLUMNS = ["conditions", "modification", "objectCreation"];
const FOA_TABLE_DEFAULT_ROW_HEIGHT = 105;
const FOA_TABLE_DEFAULT_CUSTOM_COLUMN_WIDTH = 300;

export class FlightAndObjectActionEntityDescriptor extends EntityDescriptor {

    public getGraphQlFieldsToRequest(fields?: string[]) {
        let fieldsToRequest = super.getGraphQlFieldsToRequest([...conditionFieldsKeys, ...modificationFieldsKeys, ...objcreationFieldsKeys,
            "id", "name", "enabled", "usedModificationFields", "organization"]);
        fieldsToRequest = fieldsToRequest.replace("usedModificationFields", "usedModificationFields children { " + fieldsToRequest + " } parent { " + fieldsToRequest + " }");
        return fieldsToRequest;
    }

    getDefaultColumnConfig() {
        let defaultColumnConfig = _.cloneDeep(super.getDefaultColumnConfig());
        defaultColumnConfig.configObject.columns?.map((column) => {
            if (CLIENT_COLUMNS.includes(column.name)) {
                column.width = FOA_TABLE_DEFAULT_CUSTOM_COLUMN_WIDTH;
            }
        });
        return defaultColumnConfig;
    }

    protected customize() {
        const that = this;
        this.defaultFilter = Filter.createForClient("parent", FilterOperators.forEntityManyToOne.isEmpty);

        this.addFieldDescriptor(new class extends FieldDescriptor {
            name = "conditions";
            clientOnly = true;

            getAppearsInUi(): boolean {
                return true;
            }

            protected renderFieldInternal(RendererClass: any, props: FieldRendererProps, entity: any): ReactNode {
                let conditionFields: any[] = [];

                conditionFieldsKeys.forEach(key => {
                    if (entity[key]) {
                        conditionFields.push(<>
                            {_msg("FlightAndObjectAction." + key + ".label") + ":"}
                            {that.getField(key).renderField(entity, FieldDescriptor.castAdditionalFieldRendererProps(StringFieldRenderer, { asLabel: true }))}
                        </>);
                    }
                });

                return <div className="FlightAndObjectAction_customColumnContainer">{conditionFields}</div>;
            }
        })
            .addFieldDescriptor(new class extends FieldDescriptor {
                name = "modification";
                clientOnly = true;

                getAppearsInUi(): boolean {
                    return true;
                }

                protected renderFieldInternal(RendererClass: any, props: FieldRendererProps, entity: any): ReactNode {
                    let modificationFields: any[] = [];

                    modificationFieldsKeys.forEach(key => {
                        if (entity.usedModificationFields?.indexOf(key) > -1) {
                            modificationFields.push(<>
                                {_msg("FlightAndObjectAction." + key + ".label") + ":"}
                                {that.getField(key).renderField(entity, FieldDescriptor.castAdditionalFieldRendererProps(StringFieldRenderer, { asLabel: true }))}
                            </>);
                        }
                    });

                    return <div className="FlightAndObjectAction_customColumnContainer">{modificationFields}</div>;
                }
            })
            .addFieldDescriptor(new class extends FieldDescriptor {
                name = "objectCreation";
                clientOnly = true;

                getAppearsInUi(): boolean {
                    return true;
                }

                protected renderFieldInternal(RendererClass: any, props: FieldRendererProps, entity: any): ReactNode {
                    let objCreationFields: any[] = [];
                    let objCreation = <></>;

                    for (let i = 0; i < entity.children.length + 1; i++) {
                        let child = i === 0 ? entity : entity.children[i - 1];
                        objCreationFields = [];
                        objcreationFieldsKeys.forEach(key => {
                            if (key == "useObjectCreation" || (child.useObjectCreation && child[key])) {
                                objCreationFields.push(<>
                                    {_msg("FlightAndObjectAction." + key + ".label") + ":"}
                                    {that.getField(key).renderField(child, FieldDescriptor.castAdditionalFieldRendererProps(StringFieldRenderer, { asLabel: true }))}
                                </>);
                            }
                        });
                        objCreation = <>
                            {objCreation}
                            <Segment className="FlightAndObjectAction_objectCreationContainer less-padding">
                                <Label color="blue">
                                    <NavLink to={new ShareLinkLogic().createLink(false, entityDescriptors["FlightAndObjectAction"], Filter.enableAllFilters(Filter.create("id", FilterOperators.forNumber.equals, child.id)))}>
                                        {i + 1}{i === 0 ? _msg("FlightAndObjectAction.objectCreation.current.label") : null}
                                    </NavLink>
                                </Label>
                                {objCreationFields}
                            </Segment>
                        </>;
                    }
                    return objCreation;
                }
            })
            .addFieldDescriptor({ name: "color", type: FieldType.color, colorType: "number", additionalFieldRendererProps: FieldDescriptor.castAdditionalFieldRendererProps(ColorFieldRenderer, { style: { width: "auto", display: "inline-block" } }) })
            .addFieldDescriptor({ name: "departure", type: FieldType.dropdown, fieldDescriptorSettings: { fieldIntervals: [{ from: 0, label: _msg("global.button.ignore") }, { from: 1, label: _msg("global.button.yes") }, { from: 2, label: _msg("global.button.no") }] } })
            .addFieldDescriptor({ name: "schengen", type: FieldType.dropdown, fieldDescriptorSettings: { fieldIntervals: [{ from: 0, label: _msg("global.button.ignore") }, { from: 1, label: _msg("global.button.yes") }, { from: 2, label: _msg("global.button.no") }] } })
            .addFieldDescriptor({ name: "parkingIsContact", type: FieldType.dropdown, fieldDescriptorSettings: { fieldIntervals: [{ from: 0, label: _msg("global.button.ignore") }, { from: 1, label: _msg("global.button.yes") }, { from: 2, label: _msg("global.button.no") }] } })
            .addFieldDescriptor({ name: "canceled", type: FieldType.dropdown, fieldDescriptorSettings: { fieldIntervals: [{ from: 0, label: _msg("global.button.ignore") }, { from: 1, label: _msg("global.button.yes") }, { from: 2, label: _msg("global.button.no") }] } })
            .addFieldDescriptor({ name: "registrations", type: FieldType.dropdown, fieldDescriptorSettings: { fieldIntervals: [{ from: 0, label: _msg("global.button.ignore") }, { from: 1, label: _msg("global.button.yes") }, { from: 2, label: _msg("global.button.no") }] } })
            .addFieldDescriptor({ name: "flightAndRotationSameUnit", type: FieldType.dropdown, fieldDescriptorSettings: { fieldIntervals: [{ from: 0, label: _msg("global.button.ignore") }, { from: 1, label: _msg("global.button.yes") }, { from: 2, label: _msg("global.button.no") }] } })
            .addFieldDescriptor({ name: "nightStop", type: FieldType.dropdown, fieldDescriptorSettings: { fieldIntervals: [{ from: 0, label: _msg("global.button.ignore") }, { from: 1, label: _msg("global.button.yes") }, { from: 2, label: _msg("global.button.no") }] } })
            .addFieldDescriptor({ name: "withMatriculationGroup", type: FieldType.dropdown, fieldDescriptorSettings: { fieldIntervals: [{ from: 0, label: _msg("global.button.ignore") }, { from: 1, label: _msg("global.button.yes") }, { from: 2, label: _msg("global.button.no") }] } })
            .addFieldDescriptor({ name: "withRotation", type: FieldType.dropdown, fieldDescriptorSettings: { fieldIntervals: [{ from: 0, label: _msg("global.button.ignore") }, { from: 1, label: _msg("global.button.yes") }, { from: 2, label: _msg("global.button.no") }] } })
            .addFieldDescriptor({ name: "crewShuttle", type: FieldType.dropdown, fieldDescriptorSettings: { fieldIntervals: [{ from: 0, label: _msg("global.button.ignore") }, { from: 1, label: _msg("global.button.yes") }, { from: 2, label: _msg("global.button.no") }] } })
            .addFieldDescriptor({ name: "showFlightInGantt", type: FieldType.dropdown, fieldDescriptorSettings: { fieldIntervals: [{ from: 0, label: _msg("flight.defaultShowFlightInGantt.leaveUnset") }, { from: 1, label: _msg("flight.defaultShowFlightInGantt.doNotShow") }, { from: 2, label: _msg("flight.defaultShowFlightInGantt.showInGantt") }] } })
            .addFieldDescriptor({ name: "showFlightInList", type: FieldType.dropdown, fieldDescriptorSettings: { fieldIntervals: [{ from: 0, label: _msg("flight.defaultShowFlightInGantt.leaveUnset") }, { from: 1, label: _msg("flight.defaultShowFlightInGantt.doNotShow") }, { from: 2, label: _msg("flight.defaultShowFlightInGantt.showInGantt") }] } })
            .addFieldDescriptor({ name: "startTime", type: FieldType.date })
            .addFieldDescriptor({ name: "endTime", type: FieldType.date })
             .addTabDescriptor({ oneToManyEntityName: "FlightAndObjectAction", oneToManyOppositeField: "parent", title: _msg("FlightAndObjectAction.multiple.object.creation.title"), oneToManyDefaultFilter: Filter.createComposedForClient(FilterOperators.forComposedFilter.and, []) })
            .isInDefaultColumnConfig(true, "id", "name", "enabled", "conditions", "modification", "objectCreation");

        this.doForFields(CLIENT_COLUMNS, fd => fd.filterable = false);
        this.doForFields(CLIENT_COLUMNS, fd => fd.sortable = false);

        this.infoEditor.wrappedComponentClass = class extends EntityEditorPage<EntityEditorPageProps> {

            entityEditorForm = React.createRef<EntityEditorFormSimple>();

            protected getSpecificTabPanes(): (TabRouterPane | null)[] {
                const props = this.props;

                let conditionDescriptor: EntityDescriptor = clone(props.dispatchers.getSlice().entityDescriptor);
                let conditionFields: any = {};
                Object.keys(conditionDescriptor.fields).forEach(key => {
                    if (conditionFieldsKeys.includes(key)) {
                        conditionFields[key] = conditionDescriptor.fields[key];
                        conditionFields[key].enabled = true;
                    }
                });
                conditionDescriptor.fields = conditionFields;

                let modificationDescriptor: EntityDescriptor = clone(props.dispatchers.getSlice().entityDescriptor);
                let modificationFields: any = {};
                Object.keys(modificationDescriptor.fields).forEach(key => {
                    if (modificationFieldsKeys.includes(key)) {
                        modificationFields[key] = modificationDescriptor.fields[key];
                    }
                });
                modificationDescriptor.fields = modificationFields;

                let objcreationDescriptor: EntityDescriptor = clone(props.dispatchers.getSlice().entityDescriptor);
                let objcreationFields: any = {};
                Object.keys(objcreationDescriptor.fields).forEach(key => {
                    if (objcreationFieldsKeys.includes(key)) {
                        objcreationFields[key] = objcreationDescriptor.fields[key];
                    }
                });
                objcreationDescriptor.fields = objcreationFields;

                return [
                    {
                        routeProps: { path: "/conditions" },
                        menuItemProps: { content: _msg("FlightAndObjectAction.conditions.label") },
                        render: this.props.entity ? () => {
                            return <div className="ui fluid container EntityEditorPage_container">
                                <div className="ui segment EntityEditorPage_segment">
                                    <EntityEditorFormSimple
                                        ref={this.entityEditorForm} entity={props.entity}
                                        entityDescriptor={conditionDescriptor}
                                        onSubmitHandler={(values) => {
                                            props.dispatchers.setInReduxState({ entity: values });
                                            this.onSave();
                                        }} />
                                </div>
                            </div>
                        } : undefined
                    },
                    {
                        routeProps: { path: "/flightModifications" },
                        menuItemProps: { content: _msg("FlightAndObjectAction.modification.title") },
                        render: this.props.entity ? () => {
                            return <div className="ui fluid container EntityEditorPage_container">
                                <div className="ui segment EntityEditorPage_segment">
                                    <FlightAndObjectActionModificationsEntityEditorFormSimple
                                        entity={props.entity}
                                        entityDescriptor={modificationDescriptor}
                                        onSubmitHandler={(values) => {
                                            props.dispatchers.setInReduxState({ entity: values });
                                            this.onSave();
                                        }} />
                                </div>
                            </div>
                        } : undefined
                    },
                    {
                        routeProps: { path: "/objectCreation" },
                        menuItemProps: { content: _msg("FlightAndObjectAction.object.creation.title") },
                        render: this.props.entity ? () => {
                            return <div className="ui fluid container EntityEditorPage_container">
                                <div className="ui segment EntityEditorPage_segment">
                                    <EntityEditorFormSimple
                                        ref={this.entityEditorForm} entity={props.entity}
                                        entityDescriptor={objcreationDescriptor}
                                        onSubmitHandler={(values) => {
                                            props.dispatchers.setInReduxState({ entity: values });
                                            this.onSave();
                                        }} />
                                </div>
                            </div>
                        } : undefined
                    }
                ];
            }

            protected getExtraTabPanes(): (TabRouterPane | null)[] {
                let result: (TabRouterPane | null)[] = [...super.getExtraTabPanes()];
                // change tab orders
                let differentObjectCreationTab: TabRouterPane | null = result.pop() || null;
                result = result.concat(this.getSpecificTabPanes())
                if (differentObjectCreationTab) {
                    result.push(differentObjectCreationTab);
                }
                return result;
            }

            protected getExtraTabPanesInternal() {
                if (this.props.mode === EditMode.ADD) {
                    return this.getSpecificTabPanes();
                } else {
                    return super.getExtraTabPanesInternal()?.concat(this.getAuditTabPanes());
                }
            }
        }

        this.infoTable.wrappedComponentClass = class extends EntityTablePage<EntityTablePageProps> {

            protected getTableProps() {
                return { rowHeight: FOA_TABLE_DEFAULT_ROW_HEIGHT };
            }
        }
    }

}

class FlightAndObjectActionModificationsEntityEditorFormSimple extends EntityEditorFormSimple {

    renderFieldEditor(field: string, formikProps: FormikProps<any>) {
        let usedModificationFields: string = formikProps.values?.usedModificationFields;
        const { entityDescriptor } = this.props;
        const fieldDescriptor = entityDescriptor.getField(field)

        return <Grid.Column key={field} className="EntityEditorPage_grid_row_column">
            <Form.Field key={fieldDescriptor.getFieldName()} disabled={!this.isFieldEnabled(fieldDescriptor)} data-cy={"field" + field}>
                <label>{fieldDescriptor.getIcon()}{fieldDescriptor.getLabel(true)}</label>
                <div className="flex-container-row">
                    <Grid.Column className="FlightAndObjectAction_modificationEditor">
                        {fieldDescriptor.renderFieldEditor(formikProps, (this.props.autoFocusOnField ? { autoFocus: field === this.props.autoFocusOnField } : undefined) as FieldEditorProps)}
                    </Grid.Column>
                    <Grid.Column className="FlightAndObjectAction_modificationEnable">
                        {React.createElement(Checkbox, { onChange: function () { formikProps.setFieldValue("usedModificationFields", usedModificationFields?.indexOf(field) > -1 ? usedModificationFields.replace(field + ";", "") : (usedModificationFields + field + ";")) }, checked: usedModificationFields?.indexOf(field) > -1 ? true : false })}
                    </Grid.Column>
                </div>
            </Form.Field>
        </Grid.Column>
    }

    renderHeaderEditor(formikProps: FormikProps<any>) {
        return <Grid.Row key="header" className="EntityEditorPage_grid_row">
            <Grid.Column className="EntityEditorPage_grid_header_column"></Grid.Column>
            <Grid.Column width={3} textAlign="center" className="EntityEditorPage_grid_header_column"><Form.Field label={"Enable"} /></Grid.Column>
        </Grid.Row>
    }
}