import { apolloClientHolder, FieldDescriptor, Utils } from "@crispico/foundation-react";
import { ModalExt } from "@crispico/foundation-react/components/ModalExt/ModalExt";
import { entityDescriptors } from "@crispico/foundation-react/entity_crud/entityCrudConstants";
import { FieldRendererProps } from "@crispico/foundation-react/entity_crud/fieldRenderersEditors";
import { GalleryMedia } from "components/LiveVideo/GalleryMedia";
import gql from "graphql-tag";
import moment from "moment";
import { FILES_PATH, Video } from "pages/EquipmentResource/VideoTab";
import React, { ReactNode } from "react";
import { Button, Icon, Modal, Divider } from "semantic-ui-react";
import { AppMetaTempGlobals } from "@crispico/foundation-react/AppMetaTempGlobals";
import { VIDEO_ALLOW_VIEW_BLURRED, VIDEO_ALLOW_VIEW_ORIGINAL } from "app";

export type DrivingEvent = {
    id: Number,
    type: String,
    date: String,
    longitude: Number,
    latitude: Number,
    equipmentResource: any;
}

export class VideoFieldRenderer extends FieldDescriptor {

    constructor(name: string) {
        super();
        this.name = name;
        this.icon = "film";
        this.clientOnly = true;
        this.filterable = false;
        this.sortable = false;
        this.isInDefaultColumnConfigForEditor = false;
    }

    getAppearsInUi(): boolean {
        return true;
    }

    protected renderFieldInternal(RendererClass: any, props: FieldRendererProps): ReactNode {
        return <>
            <VideoFieldRendererComponent {...props} />
        </>
    }
}

class VideoFieldRendererComponent extends React.Component<FieldRendererProps, { videoModalOpen: boolean, videos?: { [key: string]: string; }, buttonsModalOpen: boolean }> {

    constructor(props: FieldRendererProps) {
        super(props);
        this.state = { videoModalOpen: false, buttonsModalOpen: false };
    }

    protected async getVideos(entity: DrivingEvent, blurred: boolean) {
        const loadedVideos = {} as { [key: string]: string; }

        // maybe DE is created manual
        if (!entity.id || !entity.date || !entity.longitude || !entity.longitude || !entity.equipmentResource) {
            return;
        }
        // date from client comes with milis "2022-04-13T11:55:06.101Z" and I need to remove ".101"
        // date after process "2022-04-13T11:55:06Z"
        const dateAndMilis = entity.date.split(".");
        var dateStr = dateAndMilis[0];

        // check if data comes with milis, if not I can add another "Z" which will be a mistake
        if (dateAndMilis.length == 2) {
            dateStr = dateAndMilis[0] + "Z";
        }

        const query = gql(`query getVideos($equipmentResourceId: Long!, $startDate: Date, $endDate: Date){
            smartwitnessService_media(
                equipmentResourceId: $equipmentResourceId
                startDate: $startDate
                endDate: $endDate
            ) {date, thumbnailPath, videoPath, eventType, cameraChannel, entityId, latitude, longitude, duration}
          } `);

        const results: Video[] = await ((await apolloClientHolder.apolloClient.query(
            { query, variables: { equipmentResourceId: entity.equipmentResource.id, startDate: dateStr, endDate: dateStr } }))).data.smartwitnessService_media;

        // filter after DE id, maybe exists driving event with same information, but id must be unique
        results.forEach((result, index) => {
            result.thumbnailPath += "?blurred=" + (blurred ? "true" : "false")
            result.videoPath += "?blurred=" + (blurred ? "true" : "false")
            if (result.entityId != entity.id.toString()) {
                results.slice(index, 1);
            } else {
                loadedVideos[result.cameraChannel] = FILES_PATH + result.videoPath;
            }
        })

        this.setState({ videos: loadedVideos });
    }

    render() {
        return <>
            <ModalExt open={this.state.buttonsModalOpen} size='mini' onClose={() => { this.setState({ buttonsModalOpen: false }) }} >
                <Modal.Header as="h3">{_msg("Video.chooseToPlay")}</Modal.Header>
                <Modal.Content className="wh100">
                    <Button icon="play" circular size="small" content={_msg("Video.playOriginal")} color="green" style={{ transform: 'translate(50%, -10%)' }} onClick={() => { this.setState({ videoModalOpen: true, buttonsModalOpen: false }); this.getVideos(this.props.entity, false); }}>
                    </Button>
                    <Divider />
                    <Button icon="play" circular size="small" content={_msg("Video.playBlurred")} color="blue" style={{ transform: 'translate(50%, -10%)' }} onClick={() => { this.setState({ videoModalOpen: true, buttonsModalOpen: false }); this.getVideos(this.props.entity, true); }}>
                    </Button>
                </Modal.Content>
            </ModalExt >
            <ModalExt open={this.state.videoModalOpen} style={{ width: '95%', height: '95%' }} onClose={() => { this.setState({ videoModalOpen: false }) }} >
                <Modal.Content className="wh100">
                    <GalleryMedia data={this.state.videos} loading={false} video={true} extraInfo={entityDescriptors["EquipmentResource"].getField(this.props.entity.type).getLabel() + " " + moment(this.props.entity.date).format(Utils.dateTimeWithSecFormat)} />
                </Modal.Content>
            </ModalExt>
            <Button icon circular size="small" style={{ transform: 'translate(50%, -10%)' }}
                onClick={() => {
                    if (AppMetaTempGlobals.appMetaInstance.hasPermission(VIDEO_ALLOW_VIEW_ORIGINAL) || AppMetaTempGlobals.appMetaInstance.hasPermission(VIDEO_ALLOW_VIEW_BLURRED, true)) {
                        if (AppMetaTempGlobals.appMetaInstance.hasPermission(VIDEO_ALLOW_VIEW_BLURRED) && AppMetaTempGlobals.appMetaInstance.hasPermission(VIDEO_ALLOW_VIEW_ORIGINAL)) {
                            this.setState({ buttonsModalOpen: true })
                        }
                        else {
                            this.setState({ videoModalOpen: true });
                            this.getVideos(this.props.entity, AppMetaTempGlobals.appMetaInstance.hasPermission(VIDEO_ALLOW_VIEW_BLURRED));
                        }
                    }
                }}
            >
                <Icon name="play" color="blue" />
            </Button>

        </>
    }
}