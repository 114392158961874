import { EntityDescriptor, apolloClient, Utils, FieldDescriptor, Organization } from "@crispico/foundation-react";
import React from "react";
import { CUSTOM_FIELDS } from "@crispico/foundation-react/entity_crud/FieldType";
import { PREPARE_HISTORY_TRACK_REPORT } from "./queries";
import { prepareHistoryTrackReport } from "apollo-gen/prepareHistoryTrackReport";
import { Segment, Button, Dropdown, Label, ButtonGroup, Pagination, Input, Popup, Form, FormField } from "semantic-ui-react";
import { Cell, Column, Table } from "fixed-data-table-2";
import 'fixed-data-table-2/dist/fixed-data-table.css';
import { entityDescriptors, getOrganizationFilter } from "@crispico/foundation-react/entity_crud/entityCrudConstants";
import moment, { Moment } from "moment";
import Measure from "react-measure";
import { Reducers, ReduxReusableComponents, RRCProps, State } from "@crispico/foundation-react/reduxReusableComponents/ReduxReusableComponents";
import { Chart } from "../ChartTab";
import { DatePickerReactCalendar } from "@crispico/foundation-react/components/DatePicker/DatePickerReactCalendar/DatePickerReactCalendar";
import _ from "lodash";
import { SemanticICONS } from "semantic-ui-react/dist/commonjs/generic";
import lodash from "lodash";
import { FieldDescriptorSettings } from "@crispico/foundation-react/entity_crud/CrudSettings"
import { ResponsiveBar } from "@nivo/bar"; 
import { EngineState } from "apollo-gen/globalTypes";
import { DatePickerFieldEditor } from "@crispico/foundation-react/entity_crud/fieldEditors/DatePickerFieldEditor";
import { AssociationFieldEditor } from "@crispico/foundation-react/entity_crud/fieldEditors/AssociationFieldEditor";
import { Filter } from "@crispico/foundation-react/components/CustomQuery/Filter";
import { FilterOperators } from "@crispico/foundation-gwt-js";

interface Config {
    date: string;
    groupDataBy: string;
    selectedEquipments: string;
    selectedEquipmentTypes: string;
}

type HistoryTrackReportChartData = {
    movingDuration: number;
    movingDuration_value: number;
    idleDuration: number;
    idleDuration_value: number;
    stopDuration: number;
    stopDuration_value: number;
    identifier: string;
}

const LEGEND_MAPPING: { [key: string]: string } = { movingDuration: EngineState.RUNNING, idleDuration: EngineState.IDLE, stopDuration: EngineState.OFF }
const erFd = new class extends FieldDescriptor { constructor() { super(); this.name = "dummyEquipmentResourceField"; this.type = "EquipmentResource" } }
const eqFd = new class extends FieldDescriptor { constructor() { super(); this.name = "dummyEquipmentTypeField"; this.type = "EquipmentType" } }
const DEFAULT_BAR_CHART_ROWS_NUMBER = 100;

export const historyTrackReportDescriptor = new EntityDescriptor({ name: "HistoryTrackReport" }, false)

export class HistoryTrackReportState extends State {
    rows = [[]] as [any[]];
    rowsTotal = {} as { [key: string]: number };
    startDate = moment(Utils.now()).startOf('week').valueOf();
    endDate = moment(Utils.now()).endOf('week').valueOf();
    barChartData = [] as HistoryTrackReportChartData[];
    selectedEquipmentIds = "" as string;
    selectedEquipmentTypeIds = "" as string;
    groupDataBy = "wholePeriod" as string;
    startHour = undefined as number | undefined;
    endHour = undefined as number | undefined;
}

export class HistoryTrackReportReducers<S extends HistoryTrackReportState = HistoryTrackReportState> extends Reducers<S>  { }

type Props = RRCProps<HistoryTrackReportState, HistoryTrackReportReducers> & { config: Config, entity: Chart, currentOrganizationToFilterBy: Organization };
type LocalState = { measuredWidth: number | undefined, measuredHeight: number | undefined, showBarChart: boolean, barChartIsGrouped: boolean, barChartIsVertical: boolean, activePage: number, loading: boolean }

export class HistoryTrackReport extends React.Component<Props, LocalState> {
    private fds = new Map<String, FieldDescriptor>();

    constructor(props: Props) {
        super(props);
        
        this.state = {
            measuredWidth: undefined, measuredHeight: undefined, showBarChart: false, barChartIsGrouped: false, barChartIsVertical: false, activePage: 1, loading: false
        };
        const { fds } = this;
        const edER = entityDescriptors["EquipmentResource"];

        const durationFD = new class extends FieldDescriptor {
            name = "duration";
            type = "custom";
            icon = "clock" as SemanticICONS;
            getFieldValue(values: any) {
                return moment.duration(super.getFieldValue(values), "milliseconds").format("d.hh:mm:ss", {trim: false});
            }
        }();

        const percentageFD = new class extends FieldDescriptor {
            name = "percentage";
            type = "custom";
            icon = "percent" as SemanticICONS;
            getFieldValueForDisplay(value: any, truncateValueToXDecimals?: number | undefined) {
                return super.getFieldValueForDisplay(value, truncateValueToXDecimals) + " %";
            }
        }();
        percentageFD.fieldDescriptorSettings = { fieldRef: percentageFD.name, numberOfDecimals: 2 } as FieldDescriptorSettings;

        const dateFD = new class extends FieldDescriptor {
            name = "date";
            type = "custom";
            getFieldValue(values: any) {
                const value = super.getFieldValue(values);
                if (!value) {
                    return value;
                }
                return moment(value).format(Utils.dateTimeFormat);
            }
        }();

        const distanceFD = _.cloneDeep(edER.getField("odometer"));
        if (distanceFD.fieldDescriptorSettings) {
            distanceFD.fieldDescriptorSettings.numberOfDecimals = 3;
        }

        fds.set("odometer", edER.getField("odometer"));
        fds.set("startTime", dateFD);
        fds.set("distance", distanceFD);
        if (edER.fields["speed"]) {
            fds.set("maxSpeed", edER.getField("speed"));
        }
        fds.set("engineHours", edER.getField("engineHours"));
        fds.set("periodDuration", durationFD);
        fds.set("engineDuration", durationFD);
        fds.set("movingDuration", durationFD);
        fds.set("idleDuration", durationFD);
        fds.set("stopDuration", durationFD);
        fds.set("score", percentageFD);
        fds.set("moving/total", percentageFD);
        fds.set("idle/total", percentageFD);
    }

    getIntervalDuration(date: string): number {
        const startHour = this.props.s.startHour || 0;
        const endHour = this.props.s.endHour != undefined ? this.props.s.endHour : 23;

        // get no of ms for the time interval
        const simulatedTimeStart = moment().startOf('day').hour(startHour);
        const simulatedTimeEnd = moment().add(startHour > endHour ? 1 : 0, 'day').endOf('day').hour(endHour);
        const timeDuration = simulatedTimeEnd.diff(simulatedTimeStart);
        
        // get no of days in the interval
        let startTime = moment(date);
        let endTime: moment.Moment;
        switch (this.props.s.groupDataBy) {
            case "week": {
                startTime = startTime.startOf("week");
                endTime = moment(startTime).endOf("week");
                break;
            }
            case "month": {
                startTime = startTime.startOf("month");
                endTime = moment(startTime).endOf("month");
                break;
            }
            case "year": {
                startTime = startTime.startOf("year");
                endTime = moment(startTime).endOf("year");
                break;
            }
            default: {
                startTime = moment(this.props.s.startDate).startOf('day');
                endTime = moment(this.props.s.endDate).endOf('day');
            }
        }

        if (startTime.isBefore(this.props.s.startDate)) {
            startTime = moment(this.props.s.startDate).startOf('day');
        }

        if (endTime.isAfter(this.props.s.endDate)) {
            endTime = moment(this.props.s.endDate).endOf('day');
        }

        const nrOfDays = endTime.diff(startTime, 'day') + 1;

        return nrOfDays * timeDuration;
    }
        
    async prepareData(savedData: string) {
        const result: { [key: string]: { [key: string]: any } } = JSON.parse(savedData)
        let rows: [string[]];

        // !!! IMPORTANT: the order is important for chart too, not just for table, if any changes here, please adapt getBarChartData()
        const rowsNames = ['vehicle', 'make', 'model', 'startTime', 'count', 'distance', 'maxSpeed', 'odometer', 'engineHours', 'periodDuration', 'engineDuration',
            'movingDuration', 'idleDuration', 'stopDuration', 'overSpeed', 'harshBraking', 'overAcceleration', 'overRPM'];

        if (this.props.entity.type == "tripPerformance") {
            rowsNames.push('score', 'moving/total', 'idle/total');
        }
        rows = [rowsNames];
        const rowsTotal: {[key: string]: number} = {'vehicle': 0, 'count': 0, 'distance': 0, 'maxSpeed': 0, 'periodDuration': 0, 'engineDuration': 0,
                                                    'movingDuration': 0, 'idleDuration': 0, 'stopDuration': 0, 'overSpeed': 0, 'harshBraking': 0, 'overAcceleration': 0, 
                                                    'overRPM': 0, 'score': 0, 'moving/total': 0, 'idle/total': 0 };

        for(const value of Object.values(result).sort((a,b) => a["vehicle"].localeCompare(b["vehicle"]) == 0 ? a["startTime"].localeCompare(b["startTime"]) : a["vehicle"].localeCompare(b["vehicle"]))) {
            const intervalDuration = this.getIntervalDuration(value["startTime"]);
            
            rows.push(rowsNames.map(rowName => {
                if (rowName == 'stopDuration') {
                    return intervalDuration - value["movingDuration"] - value["idleDuration"];
                }
                return value[rowName];
            }));
            
            rowsTotal["vehicle"]++;
            rowsTotal["count"] += value["count"];
            rowsTotal["distance"] += value["distance"];
            if (rowsTotal["maxSpeed"] < value["maxSpeed"]) {
                rowsTotal["maxSpeed"] = value["maxSpeed"];
            }
            rowsTotal["periodDuration"] += value["periodDuration"];
            rowsTotal["engineDuration"] += value["engineDuration"];
            rowsTotal["movingDuration"] += value["movingDuration"];
            rowsTotal["idleDuration"] += value["idleDuration"];
            rowsTotal["stopDuration"] += intervalDuration - value["movingDuration"] - value["idleDuration"];
            rowsTotal["overSpeed"] += value["overSpeed"] || 0;
            rowsTotal["harshBraking"] += value["harshBraking"] || 0;
            rowsTotal["overAcceleration"] += value["overAcceleration"] || 0;
            rowsTotal["overRPM"] += value["overRPM"] || 0;
            rowsTotal["score"] = ((rowsTotal["score"] * (rowsTotal["vehicle"] - 1)) + value["score"]) / rowsTotal["vehicle"] ;
            rowsTotal["moving/total"] = ((rowsTotal["moving/total"] * (rowsTotal["vehicle"] - 1)) + value["moving/total"]) / rowsTotal["vehicle"] ;
            rowsTotal["idle/total"] = ((rowsTotal["idle/total"] * (rowsTotal["vehicle"] - 1)) + value["idle/total"]) / rowsTotal["vehicle"];
        }

        this.props.r.setInReduxState({ rows, rowsTotal });
    }

    protected getHistoryTrackStartTimeFilter(): Filter {
        let { startDate, endDate, startHour, endHour } = this.props.s;
        // if no value for startHour or endHour, return the filter for the full period
        if (!startHour && !endHour) {
            return Filter.create("startTime", FilterOperators.forDate.between, moment(startDate).toISOString() + Filter.AND_DELIMITER + moment(endDate).toISOString());
        }
        // in case of changing just one dropdown hours
        startHour = startHour ? startHour : 0;
        endHour = endHour != undefined ? endHour : 23;
        const filter = Filter.createComposed(FilterOperators.forComposedFilter.or, []);
        const needToAddOneDayForEndInterval = startHour > endHour;
        const dayMoment = moment(startDate);
        // iterate on all days between startDate and endDate
        // e.g: 01/04 - 03/04 and 22:00 - 05:00 => filters = [01/04 22:00 BETWEEN 02/04 05:00, 02/04 22:00 BETWEEN 03/04 05:00, 03/04 22:00 BETWEEN 04/04 05:00]
        while (dayMoment.valueOf() <= endDate) {
            // startTime = set hour with startHour for current day, endTime = add one day if needed (startHour > endHour, means next day) and set hour with endHour
            filter.filters!.push(Filter.create("startTime", FilterOperators.forDate.between, dayMoment.set({ hour: startHour, minute: 0, second: 0, milliseconds: 0 }).toISOString() + Filter.AND_DELIMITER + dayMoment.add(Number(needToAddOneDayForEndInterval), "day").set({ hour: endHour, minute: 59, second: 59, milliseconds: 999 }).toISOString()));
            // increment day if was not already done by condition above
            dayMoment.add(Number(!needToAddOneDayForEndInterval), "day");
        }
        
        return filter;
    }

    async runReport(entityId: number, reportType: string) {
        return (await apolloClient.query<prepareHistoryTrackReport>({
            query: PREPARE_HISTORY_TRACK_REPORT,
            variables: { startTimeFilter: this.getHistoryTrackStartTimeFilter(), equipmentIds: this.props.s.selectedEquipmentIds, 
                equipmentTypeIds: this.props.s.selectedEquipmentTypeIds, entityId, reportType, groupDataBy: this.props.s.groupDataBy,
                currentOrganizationToFilterBy: this.props.currentOrganizationToFilterBy ? this.props.currentOrganizationToFilterBy.qualifiedName : "" },
            context: { showSpinner: true }
        })).data.historyTrackServiceCustomReport_prepareReport;
    }

    async populateReport() {
        this.setState({ loading: true });
        const result = await this.runReport(this.props.entity.id, this.props.entity.type!);
        await this.prepareData(result || "{}");
        this.setState({ loading: false });
    }

    exportDataAsCSV(entity: Chart) {
        const rows = lodash.cloneDeep(this.props.s.rows);

        const valuesTotal = []
        for (const field of rows[0]) {
            valuesTotal.push(this.props.s.rowsTotal[field]);
        }
        rows.push(valuesTotal);

        for (var i = 1; i < rows.length; i++) {
            for (var j = 0; j < rows[0].length; j++) {
                const fd = this.fds.get(rows[0][j]);
                if (!fd) {
                    continue;
                }
                const jsonValue = { [fd.name] : fd.getFieldValueConvertedToMeasurementUnit(rows[i][j]) };
                const simulatedEntity = fd.isCustomField ? { [CUSTOM_FIELDS]: jsonValue } : jsonValue;
                rows[i][j] = fd.getFieldValue(simulatedEntity);
            }
        }
        
        for (var i = 0; i < rows[0].length; i++) {
            const fd = this.fds.get(rows[0][i]);
            rows[0][i] = _msg(historyTrackReportDescriptor.name + "." + rows[0][i]) + " " + (fd?.getMeasurementUnitLabel() ? "(" + fd!.getMeasurementUnitLabel() + ")" : "");
        }

        Utils.exportToCsv(entity.name || "", rows);
    }

    componentDidMount() {
        this.componentDidUpdateInternal();
    }

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<LocalState>, snapshot?: any): void {
        this.componentDidUpdateInternal(prevProps);
    }

    async componentDidUpdateInternal(prevProps?: Readonly<Props>) {
        if (!prevProps || (!lodash.isEqual(prevProps?.currentOrganizationToFilterBy, this.props.currentOrganizationToFilterBy))) {
            this.props.r.setInReduxState({ selectedEquipmentIds: "", barChartData: [] });
            this.prepareData("{}");
        }

        if (prevProps && !lodash.isEqual(prevProps.s.rows, this.props.s.rows)) {
            this.props.r.setInReduxState({ barChartData: this.getBarChartData() });
            this.setState({ activePage: 1 });
        }
    }

    protected renderDateDropdowns() {
        return <div className="flex-center gap5">
            <div className="flex-container-row flex-center gap5">
                {_msg("general.from")}
                <Form>
                    <FormField>
                        <DatePickerReactCalendar onChange={(date: Moment | null) => this.props.r.setInReduxState({ startDate: date?.startOf("day").valueOf() })} value={moment(this.props.s.startDate)} format={Utils.dateFormat} />
                    </FormField>
                </Form>
            </div>
            <div className="flex-container-row flex-center gap5">{_msg("general.to")}
                <Form>
                    <FormField>
                        <DatePickerReactCalendar onChange={(date: Moment | null) => this.props.r.setInReduxState({ endDate: date?.endOf("day").valueOf() })} value={moment(this.props.s.endDate)} format={Utils.dateFormat} />
                    </FormField>
                </Form>
            </div>
            <div className="flex-container-row flex-center gap5">{_msg("HistoryTrackReport.fromHour")}
                <Dropdown value={this.props.s.startHour || 0} placeholder={_msg("general.all")} clearable selection options={_.range(24).map(hour => { return { text: (hour < 10 ? "0" + hour : hour).toString() + ":00", value: hour } })} style={{ zIndex: '999' }} // added because scroll of the table
                    onChange={(e, { value }) => this.props.r.setInReduxState({ startHour: value as number })} />
            </div>
            <div className="flex-container-row flex-center gap5">{_msg("HistoryTrackReport.toHour")}
                <Dropdown value={this.props.s.endHour != undefined ? this.props.s.endHour : 23} placeholder={_msg("general.all")} clearable selection options={_.range(24).map(hour => { return { text: (hour < 10 ? "0" + hour : hour).toString() + ":59", value: hour } })} style={{ zIndex: '999' }} // added because scroll of the table
                    onChange={(e, { value }) => this.props.r.setInReduxState({ endHour: value as number })} />
            </div>
            <div className="flex-container-row flex-center gap5">{_msg("HistoryTrackReport.groupDataBy.label")}
                <Dropdown value={this.props.s.groupDataBy} selection options={[{ key: 0, text: "Week", value: "week" }, { key: 1, text: "Month", value: "month" },
                { key: 2, text: "Year", value: "year" }, { key: 3, text: "Whole period", value: "wholePeriod" }]} onChange={(e, { value }) => this.props.r.setInReduxState({ groupDataBy: value as string })} />
            </div>
        </div>
    }

    protected renderSelectionDropdowns() {
        return <div className="flex-center gap5 less-margin-top-bottom">
            {entityDescriptors["EquipmentResource"].getLabel(true)}
            <div className="flex-grow-shrink-no-overflow">
                <AssociationFieldEditor fieldDescriptor={erFd} innerEntityDescriptor={entityDescriptors[erFd.type]} isMulti placeholder={_msg("general.all")}
                    filter={getOrganizationFilter(entityDescriptors[erFd.type], this.props.currentOrganizationToFilterBy)}
                    onChange={value => {
                        if (Utils.isNullOrEmpty(value)) {
                            this.props.r.setInReduxState({ selectedEquipmentIds: "" });
                            return;
                        }
                        this.props.r.setInReduxState({ selectedEquipmentIds: (value as { id: number }[]).map(entity => entity.id).join(',') })
                    }} />
            </div>
            {entityDescriptors["EquipmentType"].getLabel(true)}
            <div className="flex-grow-shrink-no-overflow">
                <AssociationFieldEditor fieldDescriptor={eqFd} innerEntityDescriptor={entityDescriptors[eqFd.type]} isMulti placeholder={_msg("general.all")}
                    onChange={value => {
                        if (Utils.isNullOrEmpty(value)) {
                            this.props.r.setInReduxState({ selectedEquipmentTypeIds: "" });
                            return;
                        }
                        this.props.r.setInReduxState({ selectedEquipmentTypeIds: (value as { id: number }[]).map(entity => entity.id).join(',') })
                    }} />
            </div>
        </div>
    }

    getTableWidth() {
        return this.state?.measuredWidth ? this.state.measuredWidth : 0;
    }

    getTableHeight() {
        return this.state?.measuredHeight ? this.state.measuredHeight - 10 : 0
    }

    renderFieldHistoryTable() {
        return <Table rowsCount={this.props.s.rows.length - 1} rowHeight={50} width={this.getTableWidth()} maxHeight={this.getTableHeight()}
                        headerHeight={50} touchScrollEnabled isColumnResizing={true} isColumnReordering={true} footerHeight={40} >
            {this.props.s.rows[0].map((field: string, index) => {
                return <Column key={field} columnKey={field}
                    allowCellsRecycling width={index < 3 ? 150 : (field.indexOf("Duration") > 0 ? 125 : 100)} isResizable={true} isReorderable={true}
                    flexGrow={index === this.props.s.rows[0].length - 1 ? 1 : undefined}
                    header={props => {
                        const fd = this.fds.get(field);
                        if (fd) {
                            return <Cell>
                                {fd.getIcon()} {_msg(historyTrackReportDescriptor.name + "." + field)} {fd.getMeasurementUnitLabel() ? "(" + fd.getMeasurementUnitLabel() + ")" : ""}
                            </Cell>
                        }
                        return <Cell>{_msg(historyTrackReportDescriptor.name + "." + field)}</Cell>}
                    }
                    cell={props => {
                        const value = this.props.s.rows[props.rowIndex + 1][index];
                        const fd = this.fds.get(field);
                        if (fd) {
                            const jsonValue = { [fd.name] : value };
                            const simulatedEntity = fd.isCustomField ? { [CUSTOM_FIELDS]: jsonValue } : jsonValue;
                            return <Cell>
                                {fd.renderField(simulatedEntity)}
                            </Cell>
                        }

                        return <Cell>
                            {value}
                        </Cell>
                    }}
                    footer={props => {
                        const value = this.props.s.rowsTotal[field];
                        const fd = this.fds.get(field);
                        if (fd) {
                            const jsonValue = { [fd.name] : value};
                            const simulatedEntity = fd.isCustomField ? { [CUSTOM_FIELDS]: jsonValue } : jsonValue;
                            return <Cell>
                                {fd.renderField(simulatedEntity)}
                            </Cell>
                        }
                        return <Cell>
                            {value}
                        </Cell>
                    }}
                    /> 
                })}
        </Table>
    }

    protected getBarChartData(): HistoryTrackReportChartData[] {
        const barChartData: HistoryTrackReportChartData[] = [];
        this.props.s.rows.forEach((row: any[], index: number) => {
            // first row contains names
            if (index == 0) {
                return;
            }
            const intervalDuration = this.getIntervalDuration(row[3]);
            const movingDuration = Math.floor(row[11] / intervalDuration * 10000) / 100;
            const idleDuration = Math.floor(row[12] / intervalDuration * 10000) / 100
            const stopDurationValue = Math.floor((100 - movingDuration - idleDuration) * 100) / 100;
            const stopDuration = stopDurationValue >= 0 ? stopDurationValue : 0;
            const identifier = row[0] + " (" + moment(row[3]).format(Utils.dateFormat) + ")";
            barChartData.push(
                {
                    movingDuration_value: row[11], movingDuration,
                    idleDuration_value: row[12], idleDuration,
                    stopDuration_value: intervalDuration - row[11] - row[12], stopDuration,
                    identifier
                });
        })
        barChartData.sort((a, b) => {
            if (Number(a.movingDuration_value) - Number(b.movingDuration_value) == 0) {
                if (Number(a.idleDuration_value) - Number(b.idleDuration_value) == 0) {
                    return b.identifier.localeCompare(a.identifier);
                } else {
                    return Number(b.idleDuration_value) - Number(a.idleDuration_value);
                }
            }
            return Number(b.movingDuration_value) - Number(a.movingDuration_value);
        });
        return barChartData;
    }

    protected formatMilis(milis: number) {
        return moment.duration(milis, "milliseconds").format("d.hh:mm:ss", { trim: false });
    }

    protected formatLabel(value: any, isVertical: boolean): string {
        if (isVertical) {
            return Math.round(value.value) + '%';
        }
        return value.value + '%' + (value.value > 11 ? " - " + this.formatMilis(value.data[value.id + "_value"]) : "");
    }

    protected getBarChartLegendColors(keys: string[]): string[] {
        const colors: string[] = [];
        const engineStateFd = entityDescriptors["EquipmentResource"].getField("engineState");
        keys.forEach(key => {
            const mapping = LEGEND_MAPPING[key];
            if (!mapping) {
                return;
            }
            const fieldColors = engineStateFd.getFieldColors(mapping);
            const color = fieldColors.backgroundColor || fieldColors.color || fieldColors.bulletColor;
            if (Utils.isNullOrEmpty(color)) {
                return;
            }
            colors.push(color);
        })
        return colors;
    }
    
    renderBarChart() {
        const { activePage } = this.state;
        const startIndex = (activePage - 1) * DEFAULT_BAR_CHART_ROWS_NUMBER;
        const endIndex = startIndex + DEFAULT_BAR_CHART_ROWS_NUMBER;
        const data = this.props.s.barChartData.slice(startIndex, endIndex).reverse();
        if (data.length == 0 || this.state.loading) {
            return <></>
        }
        const isVertical = this.state.barChartIsVertical;
        const keys = ["movingDuration", "idleDuration", "stopDuration"];
        const containerBounds = document.getElementById("HistoryTrackReport_container")!.getBoundingClientRect()!
        const marginLeft = isVertical ? 100 : 250;
        const marginTop = isVertical ? 135 : 50;
        const heightOrWidth = (35 * data.length) * ((this.state.barChartIsGrouped ? 3 : 1)) + (isVertical ? marginLeft : marginTop);
        let style = {};
        if (isVertical) {
            style = containerBounds.width >= heightOrWidth ? { maxWidth: heightOrWidth + 100 } : { minWidth: heightOrWidth };
        } else {
            style = containerBounds.height >= heightOrWidth ? { maxHeight: heightOrWidth + 20 } : { minHeight: heightOrWidth };
        }
        return <div style={style} className="HistogramWithDetails_pieContainer" key={"ResponsiveBar_" + activePage}>
            <ResponsiveBar
                data={data} layout={isVertical? "vertical" : "horizontal"} indexBy="identifier"
                groupMode={this.state.barChartIsGrouped ? "grouped" : "stacked"}
                keys={keys}
                margin={{ top: marginTop, left: marginLeft, right: isVertical ? 100 : 50, bottom: 20 }}
                innerPadding={2} borderRadius={4} borderWidth={2}
                colors={this.getBarChartLegendColors(keys)} borderColor={{ from: 'color', modifiers: [['darker', 1.4]] }}               
                axisTop={{ tickSize: 5, tickPadding: 5, tickRotation: isVertical ? -45 : 0, format: !isVertical ? (value: any) => value + '%' : undefined }}
                axisLeft={{ tickPadding: 5, format: isVertical ? (value: any) => value + '%' : undefined }} axisBottom={null}
                enableGridX={isVertical}
                enableGridY={!isVertical}
                labelSkipWidth={25}
                labelSkipHeight={10}
                labelTextColor="white"
                animate={false}
                theme={{ textColor: 'var(--textColor)', axis: { ticks: { text: { fontWeight: 600 }}}, labels: { text: { fontWeight: 400 } } }}
                label={(value: any) => this.formatLabel(value, isVertical)}
                tooltip={(value: any) => <Label basic>{value.value + '% - ' + this.formatMilis(value.data[value.id + "_value"])} ({_msg("HistoryTrackReport." + value.id)})</Label>}
                legendLabel={(value: any) => _msg("HistoryTrackReport." + value.id)}
                legends={[
                    {
                        dataFrom: 'keys',
                        anchor: 'top-left',
                        direction: 'column',
                        itemWidth: 25,
                        itemHeight: 15,
                        translateX: - marginLeft,
                        translateY: 5 - marginTop
                    }
                ]}
            /> </div>      
    }

    protected getTotalPages() {
        const result = this.props.s.barChartData.length / DEFAULT_BAR_CHART_ROWS_NUMBER;
        return this.props.s.barChartData.length > 0 ? Math.ceil(result) : 0;
    }

    protected renderButtonsAndPagination() {
        return <div className="small-margin-top">
            <Button color='green' onClick={(event: any) => this.populateReport()}>{_msg("FieldsHistoryReport.generate")}</Button>
            <Button onClick={(event: any) => this.exportDataAsCSV(this.props.entity)}>{_msg("FieldsHistoryReport.exportCsv")}</Button>
            <Button primary onClick={(event: any) => this.setState({ showBarChart: !this.state.showBarChart })}> {!this.state.showBarChart ? _msg("HistoryTrackReport.showChart") : _msg("HistoryTrackReport.showTable")}</Button>
            {this.state.showBarChart ? <>
                <ButtonGroup>
                    <Button primary={this.state.barChartIsGrouped == true} toggle onClick={(event: any) => this.setState({ barChartIsGrouped: true })}> {_msg("HistoryTrackReport.grouped")}</Button>
                    <Button primary={this.state.barChartIsGrouped == false} toggle onClick={(event: any) => this.setState({ barChartIsGrouped: false })}> {_msg("HistoryTrackReport.stacked")}</Button>
                </ButtonGroup> &nbsp;
                <ButtonGroup>
                    <Button primary={this.state.barChartIsVertical == true} toggle onClick={(event: any) => this.setState({ barChartIsVertical: true })}> {_msg("HistoryTrackReport.vertical")}</Button>
                    <Button primary={this.state.barChartIsVertical == false} toggle onClick={(event: any) => this.setState({ barChartIsVertical: false })}> {_msg("HistoryTrackReport.horizontal")}</Button>
                </ButtonGroup> &nbsp;
                <Pagination className="HistoryTrackReport_pagination float-right" size="small" totalPages={this.getTotalPages()} activePage={this.state.activePage} siblingRange={1} boundaryRange={1} ellipsisItem={undefined} firstItem={undefined} lastItem={undefined} nextItem={undefined} prevItem={undefined}
                    onPageChange={(e, { activePage }) => this.setState({ activePage: Number(activePage) })} />
            </> : undefined}</div>
    }

    render() {
        return <>
            <Segment compact className="less-margin-top-bottom">
                {this.renderDateDropdowns()}
                {this.renderSelectionDropdowns()}
                {this.renderButtonsAndPagination()}
            </Segment>
            <Segment compact basic className="flex-container flex-grow no-padding-top-bottom no-margin" id={"HistoryTrackReport_container"}>
            {this.state.showBarChart ? this.renderBarChart() : <Measure bounds onResize={contentRect => this.setState({ measuredWidth: contentRect.bounds?.width, measuredHeight: contentRect.bounds?.height })}>
                    {({ measureRef }) => (<div className="flex-container flex-grow" ref={measureRef}>
                        { this.renderFieldHistoryTable()}
                    </div>)}
                </Measure>}
            </Segment>
        </>;
    }
}

export const HistoryTrackReportRRC = ReduxReusableComponents.connectRRC(HistoryTrackReportState, HistoryTrackReportReducers, HistoryTrackReport);