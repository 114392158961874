import { addEntityDescriptor, apolloClient, BigState, createSliceFoundation, EntityDescriptor, EntityTablePage, EntityTablePageProps, FieldDescriptor, getBaseImpures, getBaseReducers, Optional, PropsFrom, SliceEntityTablePage, sliceEntityTablePageOnlyForExtension, Utils } from "@crispico/foundation-react";
import { SplitPaneExt } from "@crispico/foundation-react/components/ReactSplitPaneExt/ReactSplitPaneExt";
import { FieldType } from "@crispico/foundation-react/entity_crud/FieldType";
import { DrivingEventForMap } from "apollo-gen/DrivingEventForMap";
import { loadDrivingEventsForMap, loadDrivingEventsForMapVariables } from "apollo-gen/loadDrivingEventsForMap";
import { InitializationsForClient, MapSettings } from "app";
import { HeatData, HEAT_TYPE, MapContainerLeafletRRC, MapContainerLeaflet } from "components/MapContainerLeaflet/MapContainerLeaflet";
import { LOAD_DRIVING_EVENTS_FOR_MAP } from "pages/DrivingEvent/queries";
import React from "react";
import { Dimmer, Loader, Button, Icon } from "semantic-ui-react";
import lodash from "lodash";
import { FindByFilterParams } from "@crispico/foundation-react/entity_crud/FindByFilterParams";
import { OverrideableElement } from "@crispico/foundation-react/components/TabbedPage/TabbedPage";
import { FilterOperators } from "@crispico/foundation-gwt-js";
import { VideoFieldRenderer } from "./VideoFieldRenderer";
import { MapGoToButton, RealTimeUtils } from "components/realTimeMap/RealTimeUtils";
import { Filter } from "@crispico/foundation-react/components/CustomQuery/Filter";
import DateFieldRenderer from "@crispico/foundation-react/entity_crud/fieldRenderers/DateFieldRenderer";
import { DatePickerFieldEditor } from "@crispico/foundation-react/entity_crud/fieldEditors/DatePickerFieldEditor";

export const drivingEventEntityDescriptor = addEntityDescriptor(new EntityDescriptor({
    name: "DrivingEvent",
    miniFields: ["type", "equipmentResource.identifier", "humanResource.identifier"],
    icon: "car",
    defaultFilter: Filter.createForClient("date", FilterOperators.forDate.thisWeek),
    defaultSort: { field: "date", direction: "DESC" }
})
    .addFieldDescriptor({ name: "id", type: FieldType.number, enabled: false })
    .addFieldDescriptor({ name: "type", type: FieldType.string })
    .addFieldDescriptor({ name: "value", type: FieldType.double })
    .addFieldDescriptor({ name: "equipmentResource", type: "EquipmentResource" })
    .addFieldDescriptor({ name: "humanResource", type: "HumanResource" })
    .addFieldDescriptor({ name: "date", type: FieldType.date, 
        additionalFieldEditorProps: FieldDescriptor.castAdditionalFieldEditorProps(DatePickerFieldEditor, { format: Utils.dateTimeWithSecFormat }),
        additionalFieldRendererProps: FieldDescriptor.castAdditionalFieldRendererProps(DateFieldRenderer, { format: Utils.dateTimeWithSecFormat }) })
    .addFieldDescriptor({ name: "longitude", type: FieldType.double })
    .addFieldDescriptor({ name: "latitude", type: FieldType.double })
    .addFieldDescriptor(new VideoFieldRenderer("video"))
);

export const sliceDrivingEventTablePage = drivingEventEntityDescriptor.infoTable.slice = createSliceFoundation(class Ext extends SliceEntityTablePage {

    nestedSlices = {
        ...sliceEntityTablePageOnlyForExtension.nestedSlices
    }

    initialState = {
        ...sliceEntityTablePageOnlyForExtension.initialState,
        drivingEvents: undefined as Optional<DrivingEventForMap[]>,
        showPointsOnMap: true as boolean,
        selectedEntityIndex: undefined as number | undefined
    }

    reducers = {
        ...sliceEntityTablePageOnlyForExtension.reducers, ...getBaseReducers<Ext>(this)
    }

    impures = {
        ...sliceEntityTablePageOnlyForExtension.impures, ...getBaseImpures<Ext>(this),

        async loadDrivingEventsOnMap(mapContainer: Optional<MapContainerLeaflet>) {
            let filterFromCQ = this.getFilterForLoad();
            const events: Optional<DrivingEventForMap[]> = (await apolloClient.query<loadDrivingEventsForMap, loadDrivingEventsForMapVariables>({
                query: LOAD_DRIVING_EVENTS_FOR_MAP,
                variables: FindByFilterParams.create().filter(filterFromCQ)
            })).data.drivingEventService_findByFilter?.results;

            if (!events || events.length === 0) {
                this.getDispatchers().setInReduxState({ drivingEvents: [] });
                return;
            }

            this.getDispatchers().setInReduxState({ drivingEvents: events });
            this.addDrivingEventsOnMap(mapContainer);
        },

        addDrivingEventsOnMap(mapContainer: Optional<MapContainerLeaflet>) {
            let data: HeatData[] = [];
            Object.values(this.getState().drivingEvents!).forEach((event: DrivingEventForMap) => {
                if (event.longitude && event.latitude) {
                    data.push({ id: event.id, point: { longitude: event.longitude, latitude: event.latitude } });
                }
            });
            mapContainer?.addOrUpdateLayers(data, drivingEventEntityDescriptor.name);
        },
    }
}).setEntityDescriptor(drivingEventEntityDescriptor);

type PropsNotFromState = { mapSettings: MapSettings };
type Props = EntityTablePageProps & PropsFrom<typeof sliceDrivingEventTablePage> & PropsNotFromState;

drivingEventEntityDescriptor.infoTable.wrappedComponentClass = class extends EntityTablePage<Props> {

    mapContainerRef = React.createRef<MapContainerLeaflet>();

    constructor(props: Props) {
        super(props);
    }

    componentDidMount() {
        this.componentDidUpdateInternal();
    }

    componentDidUpdateInternal(prevProps?: Props) {
        super.componentDidUpdateInternal(prevProps);
        if (this.props.customQueryBar.customQuery?.customQueryDefinitionObject.filter !== prevProps?.customQueryBar.customQuery?.customQueryDefinitionObject.filter) {
            this.props.dispatchers.setInReduxState({ drivingEvents: undefined });
            this.entityTableSimpleRef.current?.setSelected(undefined);
            this.mapContainerRef.current?.clearMap();
            this.props.dispatchers.loadDrivingEventsOnMap(this.mapContainerRef.current);
        }

        // verify if [0, 0] -> default value; if not [0, 0], then it means the center was set by value from session storage, so we don't want to reset it
        if (this.mapContainerRef.current && lodash.isEqual(this.mapContainerRef.current!.props.s.center, [0, 0]) && this.props.mapSettings.airport !== null) {
            RealTimeUtils.setAirportCoordinates(this.props.mapSettings.airport, this.mapContainerRef);
        }
        
        if (this.mapContainerRef && this.props.drivingEvents && prevProps?.showPointsOnMap !== this.props.showPointsOnMap) {
            this.mapContainerRef.current?.clearMap();
            this.props.dispatchers.addDrivingEventsOnMap(this.mapContainerRef.current);
        }

        RealTimeUtils.selectAirportOnCurrentOrganizationToFilterByChange(prevProps, this.props, this.mapContainerRef);
    }

    protected onSelectItem(entityId: any): void {
        this.onSelectedEntityChanged(entityId, false);
    }

    protected getTableProps() {
        return {...super.getTableProps(), scrollToRow: this.props.selectedEntityIndex};
    }

    protected onSelectedEntityChanged(entityId: number | undefined, fromMap: boolean) {
        if (entityId) {
            if (fromMap) {
                const { entityDescriptor } = this.props.dispatchers.getSlice();
                this.goToEditor(entityDescriptor.getEntityEditorUrl(entityId));
                this.props.dispatchers.setInReduxState({ selectedEntityIndex: this.entityTableSimpleRef.current?.props.s.entities.findIndex(entity => entity.id === entityId) });
                this.entityTableSimpleRef.current?.setSelected(entityId);
            } else {
                this.mapContainerRef.current!.props.r.setInReduxState({ selectedLayer: { id: entityId, type: drivingEventEntityDescriptor.name, additionalInfo: { flyToLayer: true } } });
            }
        }
    }

    protected preRenderButtons(params: any): Array<OverrideableElement> {
        return [
            ...super.preRenderButtons(params),
            {
                element:
                    <div key="selectAirport" className="MapContainerHeader_segment">
                        <Dimmer inverted active={this.props.drivingEvents === undefined}></Dimmer>
                        <Button color={this.props.showPointsOnMap ? "orange" : "teal"} onClick={() => this.props.dispatchers.setInReduxState({ showPointsOnMap: !this.props.showPointsOnMap })}><Icon name='point' />{_msg(this.props.showPointsOnMap ? "DrivingEventTable.hidePoints" : "DrivingEventTable.showPoints")}</Button>
                        <MapGoToButton options={RealTimeUtils.getMapGoToButtonProps(this.mapContainerRef)} />
                    </div>
            }
        ];
    };

    renderMain() {
        return (<>            
            <SplitPaneExt size="30%">
                {this.renderTableSimple()}
                <>
                    <Dimmer inverted active={this.props.drivingEvents === undefined}><Loader size='medium'>{_msg("general.loading")}</Loader></Dimmer>
                    <MapContainerLeafletRRC id={"mapContainerLeafletDrivingEventTable"} ref={this.mapContainerRef} mapId={"driving-event-map"} saveCenterZoomInStorage={true}
                        layers={{ [drivingEventEntityDescriptor.name]: { layerType: HEAT_TYPE, options: { hideTooltipOnHoveredLayer: true, showPoints: this.props.showPointsOnMap } } }} 
                        pruneClusterMode={false} bingAPIKey={this.props.mapSettings.bingAPIKey}  onSelectLayer={() => this.onSelectedEntityChanged(this.mapContainerRef.current?.props.s.selectedLayer?.id as number, true)}/>
                </>
            </SplitPaneExt>
        </>)
    }
}

drivingEventEntityDescriptor.infoTable.mapBigStateToProps = (state: BigState, props: any) => {   
    props.mapSettings = (state.AppContainer.initializationsForClient as InitializationsForClient).mapSettings;
}