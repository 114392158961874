export default global.entitiesGen = {
  "ActionType": {
    "name": "ActionType",
    "fields": [
      {
        "name": "id",
        "type": "Long",
        "kind": "SCALAR"
      },
      {
        "name": "customFields",
        "type": "Map_String_ObjectScalar",
        "kind": "SCALAR"
      },
      {
        "name": "version",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "code",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "label",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "requiredForValidation",
        "type": "Boolean",
        "kind": "SCALAR"
      },
      {
        "name": "duration",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "startAction",
        "type": "Boolean",
        "kind": "SCALAR"
      },
      {
        "name": "endAction",
        "type": "Boolean",
        "kind": "SCALAR"
      },
      {
        "name": "noValidationAlertTime",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "forDepartures",
        "type": "Boolean",
        "kind": "SCALAR"
      },
      {
        "name": "forArrivals",
        "type": "Boolean",
        "kind": "SCALAR"
      },
      {
        "name": "taskType",
        "type": "TaskType",
        "kind": "OBJECT"
      }
    ]
  },
  "Address": {
    "name": "Address",
    "fields": [
      {
        "name": "id",
        "type": "Long",
        "kind": "SCALAR"
      },
      {
        "name": "customFields",
        "type": "Map_String_ObjectScalar",
        "kind": "SCALAR"
      },
      {
        "name": "version",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "name",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "isParking",
        "type": "Boolean",
        "kind": "SCALAR"
      },
      {
        "name": "contact",
        "type": "Boolean",
        "kind": "SCALAR"
      },
      {
        "name": "parkingType",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "terminal",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "airport",
        "type": "Airport",
        "kind": "OBJECT"
      },
      {
        "name": "hotspot",
        "type": "Boolean",
        "kind": "SCALAR"
      },
      {
        "name": "code",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "zone",
        "type": "Zone",
        "kind": "OBJECT"
      },
      {
        "name": "pits",
        "type": "Pit",
        "kind": "LIST"
      },
      {
        "name": "tags",
        "type": "EntityToTag",
        "kind": "LIST"
      },
      {
        "name": "latitude",
        "type": "Float",
        "kind": "SCALAR"
      },
      {
        "name": "longitude",
        "type": "Float",
        "kind": "SCALAR"
      },
      {
        "name": "archived",
        "type": "Boolean",
        "kind": "SCALAR"
      }
    ]
  },
  "AddressAirline": {
    "name": "AddressAirline",
    "fields": [
      {
        "name": "id",
        "type": "Long",
        "kind": "SCALAR"
      },
      {
        "name": "customFields",
        "type": "Map_String_ObjectScalar",
        "kind": "SCALAR"
      },
      {
        "name": "version",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "departure",
        "type": "Boolean",
        "kind": "SCALAR"
      },
      {
        "name": "address",
        "type": "Address",
        "kind": "OBJECT"
      },
      {
        "name": "airline",
        "type": "Airline",
        "kind": "OBJECT"
      }
    ]
  },
  "Airline": {
    "name": "Airline",
    "fields": [
      {
        "name": "id",
        "type": "Long",
        "kind": "SCALAR"
      },
      {
        "name": "customFields",
        "type": "Map_String_ObjectScalar",
        "kind": "SCALAR"
      },
      {
        "name": "version",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "code",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "name",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "type",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "used",
        "type": "Boolean",
        "kind": "SCALAR"
      },
      {
        "name": "addressForReportId",
        "type": "Long",
        "kind": "SCALAR"
      },
      {
        "name": "addressForReport",
        "type": "Address",
        "kind": "OBJECT"
      },
      {
        "name": "parkings",
        "type": "AddressAirline",
        "kind": "LIST"
      }
    ]
  },
  "AirlineContact": {
    "name": "AirlineContact",
    "fields": [
      {
        "name": "id",
        "type": "Long",
        "kind": "SCALAR"
      },
      {
        "name": "customFields",
        "type": "Map_String_ObjectScalar",
        "kind": "SCALAR"
      },
      {
        "name": "version",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "firstName",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "lastName",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "phoneNumber",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "address",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "email",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "particularity",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "airline",
        "type": "Airline",
        "kind": "OBJECT"
      }
    ]
  },
  "Airport": {
    "name": "Airport",
    "fields": [
      {
        "name": "id",
        "type": "Long",
        "kind": "SCALAR"
      },
      {
        "name": "customFields",
        "type": "Map_String_ObjectScalar",
        "kind": "SCALAR"
      },
      {
        "name": "version",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "code",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "name",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "ifubs",
        "type": "Boolean",
        "kind": "SCALAR"
      },
      {
        "name": "isSchengen",
        "type": "Boolean",
        "kind": "SCALAR"
      },
      {
        "name": "latitude",
        "type": "Float",
        "kind": "SCALAR"
      },
      {
        "name": "longitude",
        "type": "Float",
        "kind": "SCALAR"
      },
      {
        "name": "timeZone",
        "type": "String",
        "kind": "SCALAR"
      }
    ]
  },
  "Alert": {
    "name": "Alert",
    "fields": [
      {
        "name": "id",
        "type": "Long",
        "kind": "SCALAR"
      },
      {
        "name": "customFields",
        "type": "Map_String_ObjectScalar",
        "kind": "SCALAR"
      },
      {
        "name": "version",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "alertRuleId",
        "type": "Long",
        "kind": "SCALAR"
      },
      {
        "name": "type",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "status",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "severity",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "missionId",
        "type": "Long",
        "kind": "SCALAR"
      },
      {
        "name": "stopId",
        "type": "Long",
        "kind": "SCALAR"
      },
      {
        "name": "taskId",
        "type": "Long",
        "kind": "SCALAR"
      },
      {
        "name": "eventId",
        "type": "Long",
        "kind": "SCALAR"
      },
      {
        "name": "creationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "canceled",
        "type": "Boolean",
        "kind": "SCALAR"
      },
      {
        "name": "missionInfo",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "subjectInfo",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "driverName",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "vehicleNo",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "phonedInfo",
        "type": "PhonedInfo",
        "kind": "LIST"
      },
      {
        "name": "message",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "specialisation",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "specialisationDetail",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "oagId",
        "type": "Long",
        "kind": "SCALAR"
      },
      {
        "name": "name",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "unit",
        "type": "Unit",
        "kind": "OBJECT"
      },
      {
        "name": "alertRule",
        "type": "AlertRule",
        "kind": "OBJECT"
      },
      {
        "name": "equipmentResource",
        "type": "EquipmentResource",
        "kind": "OBJECT"
      },
      {
        "name": "organization",
        "type": "Organization",
        "kind": "OBJECT"
      }
    ]
  },
  "AlertRule": {
    "name": "AlertRule",
    "fields": [
      {
        "name": "id",
        "type": "Long",
        "kind": "SCALAR"
      },
      {
        "name": "customFields",
        "type": "Map_String_ObjectScalar",
        "kind": "SCALAR"
      },
      {
        "name": "version",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "name",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "type",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "description",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "enabled",
        "type": "Boolean",
        "kind": "SCALAR"
      },
      {
        "name": "modified",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "script",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "codeType",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "alerts",
        "type": "Alert",
        "kind": "LIST"
      }
    ]
  },
  "BackgroundProcess": {
    "name": "BackgroundProcess",
    "fields": [
      {
        "name": "id",
        "type": "Long",
        "kind": "SCALAR"
      },
      {
        "name": "customFields",
        "type": "Map_String_ObjectScalar",
        "kind": "SCALAR"
      },
      {
        "name": "version",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "label",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "serviceName",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "methodName",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "creationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "status",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "result",
        "type": "ObjectScalar",
        "kind": "SCALAR"
      },
      {
        "name": "finishDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "user",
        "type": "User",
        "kind": "OBJECT"
      }
    ]
  },
  "Baggage": {
    "name": "Baggage",
    "fields": [
      {
        "name": "id",
        "type": "Long",
        "kind": "SCALAR"
      },
      {
        "name": "customFields",
        "type": "Map_String_ObjectScalar",
        "kind": "SCALAR"
      },
      {
        "name": "version",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "dataString",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "dataVersion",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "number",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "date",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "airportCode",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "location",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "uld",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "status",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "type",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "loadDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "unloadDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "inFlight",
        "type": "Flight",
        "kind": "OBJECT"
      },
      {
        "name": "outFlight",
        "type": "Flight",
        "kind": "OBJECT"
      },
      {
        "name": "object",
        "type": "Task",
        "kind": "OBJECT"
      },
      {
        "name": "humanResource",
        "type": "HumanResource",
        "kind": "OBJECT"
      },
      {
        "name": "importedBy",
        "type": "String",
        "kind": "SCALAR"
      }
    ]
  },
  "BaggagesAlert": {
    "name": "BaggagesAlert",
    "fields": [
      {
        "name": "id",
        "type": "Long",
        "kind": "SCALAR"
      },
      {
        "name": "customFields",
        "type": "Map_String_ObjectScalar",
        "kind": "SCALAR"
      },
      {
        "name": "version",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "maxNumber",
        "type": "Long",
        "kind": "SCALAR"
      },
      {
        "name": "flightDestination",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "objectType",
        "type": "ContainerType",
        "kind": "OBJECT"
      }
    ]
  },
  "BlocklyScriptXops": {
    "name": "BlocklyScriptXops",
    "fields": [
      {
        "name": "id",
        "type": "Long",
        "kind": "SCALAR"
      },
      {
        "name": "customFields",
        "type": "Map_String_ObjectScalar",
        "kind": "SCALAR"
      },
      {
        "name": "version",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "name",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "comment",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "blocklyContent",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "equipment",
        "type": "EquipmentResource",
        "kind": "OBJECT"
      },
      {
        "name": "equipmentTag",
        "type": "Tag",
        "kind": "OBJECT"
      },
      {
        "name": "active",
        "type": "Boolean",
        "kind": "SCALAR"
      },
      {
        "name": "error",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "importance",
        "type": "Int",
        "kind": "SCALAR"
      }
    ]
  },
  "CDGPortParking": {
    "name": "CDGPortParking",
    "fields": [
      {
        "name": "id",
        "type": "Long",
        "kind": "SCALAR"
      },
      {
        "name": "customFields",
        "type": "Map_String_ObjectScalar",
        "kind": "SCALAR"
      },
      {
        "name": "version",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "parking",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "port",
        "type": "String",
        "kind": "SCALAR"
      }
    ]
  },
  "Chart": {
    "name": "Chart",
    "fields": [
      {
        "name": "id",
        "type": "Long",
        "kind": "SCALAR"
      },
      {
        "name": "customFields",
        "type": "Map_String_ObjectScalar",
        "kind": "SCALAR"
      },
      {
        "name": "version",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "type",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "config",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "savedData",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "name",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "percentage",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "lastAutomaticGenerationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "organization",
        "type": "Organization",
        "kind": "OBJECT"
      },
      {
        "name": "icon",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "color",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "showInMenu",
        "type": "Boolean",
        "kind": "SCALAR"
      },
      {
        "name": "positionInMenu",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "automaticGenerationRecipientList",
        "type": "RecipientList",
        "kind": "OBJECT"
      },
      {
        "name": "automaticGenerationCron",
        "type": "String",
        "kind": "SCALAR"
      }
    ]
  },
  "Checklist": {
    "name": "Checklist",
    "fields": [
      {
        "name": "id",
        "type": "Long",
        "kind": "SCALAR"
      },
      {
        "name": "customFields",
        "type": "Map_String_ObjectScalar",
        "kind": "SCALAR"
      },
      {
        "name": "version",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "dataString",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "dataVersion",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "creationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "values",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "template",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "mission",
        "type": "Mission2",
        "kind": "OBJECT"
      },
      {
        "name": "vehicle",
        "type": "EquipmentResource",
        "kind": "OBJECT"
      },
      {
        "name": "user",
        "type": "User",
        "kind": "OBJECT"
      },
      {
        "name": "humanResource",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "flight",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "flightParking",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "controleHRId",
        "type": "Long",
        "kind": "SCALAR"
      },
      {
        "name": "departure",
        "type": "Boolean",
        "kind": "SCALAR"
      },
      {
        "name": "equipmentName",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "flightDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "controleHR",
        "type": "HumanResource",
        "kind": "OBJECT"
      },
      {
        "name": "xopsUser",
        "type": "User",
        "kind": "OBJECT"
      }
    ]
  },
  "ColumnConfig": {
    "name": "ColumnConfig",
    "fields": [
      {
        "name": "id",
        "type": "Long",
        "kind": "SCALAR"
      },
      {
        "name": "customFields",
        "type": "Map_String_ObjectScalar",
        "kind": "SCALAR"
      },
      {
        "name": "version",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "name",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "entityName",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "configJson",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "organization",
        "type": "Organization",
        "kind": "OBJECT"
      },
      {
        "name": "displayAsCards",
        "type": "Boolean",
        "kind": "SCALAR"
      },
      {
        "name": "autoRefreshInterval",
        "type": "Int",
        "kind": "SCALAR"
      }
    ]
  },
  "Comment": {
    "name": "Comment",
    "fields": [
      {
        "name": "id",
        "type": "Long",
        "kind": "SCALAR"
      },
      {
        "name": "customFields",
        "type": "Map_String_ObjectScalar",
        "kind": "SCALAR"
      },
      {
        "name": "version",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "text",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "type",
        "type": "String",
        "kind": "SCALAR"
      }
    ]
  },
  "ContainerType": {
    "name": "ContainerType",
    "fields": [
      {
        "name": "id",
        "type": "Long",
        "kind": "SCALAR"
      },
      {
        "name": "customFields",
        "type": "Map_String_ObjectScalar",
        "kind": "SCALAR"
      },
      {
        "name": "version",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "priority",
        "type": "Float",
        "kind": "SCALAR"
      },
      {
        "name": "prefix",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "taskType",
        "type": "TaskType",
        "kind": "OBJECT"
      }
    ]
  },
  "CustomQuery": {
    "name": "CustomQuery",
    "fields": [
      {
        "name": "id",
        "type": "Long",
        "kind": "SCALAR"
      },
      {
        "name": "customFields",
        "type": "Map_String_ObjectScalar",
        "kind": "SCALAR"
      },
      {
        "name": "version",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "screen",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "name",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "preferredColumnConfig",
        "type": "ColumnConfig",
        "kind": "OBJECT"
      },
      {
        "name": "customQueryDefinition",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "emailScheduleRecipientList",
        "type": "RecipientList",
        "kind": "OBJECT"
      },
      {
        "name": "emailScheduleCron",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "organization",
        "type": "Organization",
        "kind": "OBJECT"
      }
    ]
  },
  "Dashboard": {
    "name": "Dashboard",
    "fields": [
      {
        "name": "id",
        "type": "Long",
        "kind": "SCALAR"
      },
      {
        "name": "customFields",
        "type": "Map_String_ObjectScalar",
        "kind": "SCALAR"
      },
      {
        "name": "version",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "name",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "configJson",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "icon",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "color",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "showInMenu",
        "type": "Boolean",
        "kind": "SCALAR"
      },
      {
        "name": "positionInMenu",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "organization",
        "type": "Organization",
        "kind": "OBJECT"
      },
      {
        "name": "entityName",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "filterJson",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "fitHeight",
        "type": "Boolean",
        "kind": "SCALAR"
      },
      {
        "name": "forEditor",
        "type": "Boolean",
        "kind": "SCALAR"
      },
      {
        "name": "width",
        "type": "Int",
        "kind": "SCALAR"
      }
    ]
  },
  "DashboardCalculateForRecordsValue": {
    "name": "DashboardCalculateForRecordsValue",
    "fields": [
      {
        "name": "id",
        "type": "Long",
        "kind": "SCALAR"
      },
      {
        "name": "customFields",
        "type": "Map_String_ObjectScalar",
        "kind": "SCALAR"
      },
      {
        "name": "version",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "widget",
        "type": "DashboardCalculateForRecordsWidget",
        "kind": "OBJECT"
      },
      {
        "name": "timestamp",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "savedValues",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "records",
        "type": "String",
        "kind": "SCALAR"
      }
    ]
  },
  "DashboardCalculateForRecordsWidget": {
    "name": "DashboardCalculateForRecordsWidget",
    "fields": [
      {
        "name": "id",
        "type": "Long",
        "kind": "SCALAR"
      },
      {
        "name": "customFields",
        "type": "Map_String_ObjectScalar",
        "kind": "SCALAR"
      },
      {
        "name": "version",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "dashboard",
        "type": "Dashboard",
        "kind": "OBJECT"
      },
      {
        "name": "widgetIdIfReferenced",
        "type": "String",
        "kind": "SCALAR"
      }
    ]
  },
  "DayTemplate": {
    "name": "DayTemplate",
    "fields": [
      {
        "name": "id",
        "type": "Long",
        "kind": "SCALAR"
      },
      {
        "name": "customFields",
        "type": "Map_String_ObjectScalar",
        "kind": "SCALAR"
      },
      {
        "name": "version",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "name",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "basedOnDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "lastGenerations",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "unit",
        "type": "Unit",
        "kind": "OBJECT"
      },
      {
        "name": "dayTemplateDayTemplateGroupAssociations",
        "type": "DayTemplateDayTemplateGroupAssociation",
        "kind": "LIST"
      },
      {
        "name": "organization",
        "type": "Organization",
        "kind": "OBJECT"
      }
    ]
  },
  "DayTemplateDayTemplateGroupAssociation": {
    "name": "DayTemplateDayTemplateGroupAssociation",
    "fields": [
      {
        "name": "id",
        "type": "Long",
        "kind": "SCALAR"
      },
      {
        "name": "customFields",
        "type": "Map_String_ObjectScalar",
        "kind": "SCALAR"
      },
      {
        "name": "version",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "dayIndex",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "dayTemplate",
        "type": "DayTemplate",
        "kind": "OBJECT"
      },
      {
        "name": "dayTemplateGroup",
        "type": "DayTemplateGroup",
        "kind": "OBJECT"
      }
    ]
  },
  "DayTemplateGroup": {
    "name": "DayTemplateGroup",
    "fields": [
      {
        "name": "id",
        "type": "Long",
        "kind": "SCALAR"
      },
      {
        "name": "customFields",
        "type": "Map_String_ObjectScalar",
        "kind": "SCALAR"
      },
      {
        "name": "version",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "name",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "dayTemplateDayTemplateGroupAssociations",
        "type": "DayTemplateDayTemplateGroupAssociation",
        "kind": "LIST"
      }
    ]
  },
  "Dbc": {
    "name": "Dbc",
    "fields": [
      {
        "name": "id",
        "type": "Long",
        "kind": "SCALAR"
      },
      {
        "name": "customFields",
        "type": "Map_String_ObjectScalar",
        "kind": "SCALAR"
      },
      {
        "name": "version",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "name",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "content",
        "type": "String",
        "kind": "SCALAR"
      }
    ]
  },
  "DetectionEvent": {
    "name": "DetectionEvent",
    "fields": [
      {
        "name": "id",
        "type": "Long",
        "kind": "SCALAR"
      },
      {
        "name": "customFields",
        "type": "Map_String_ObjectScalar",
        "kind": "SCALAR"
      },
      {
        "name": "version",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "equipmentResource",
        "type": "EquipmentResource",
        "kind": "OBJECT"
      },
      {
        "name": "detectedBy",
        "type": "EquipmentResource",
        "kind": "OBJECT"
      },
      {
        "name": "date",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "longitude",
        "type": "Float",
        "kind": "SCALAR"
      },
      {
        "name": "latitude",
        "type": "Float",
        "kind": "SCALAR"
      },
      {
        "name": "territories",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "enteredTerritories",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "exitedTerritories",
        "type": "String",
        "kind": "SCALAR"
      }
    ]
  },
  "DiagnosticTroubleCode": {
    "name": "DiagnosticTroubleCode",
    "fields": [
      {
        "name": "id",
        "type": "Long",
        "kind": "SCALAR"
      },
      {
        "name": "customFields",
        "type": "Map_String_ObjectScalar",
        "kind": "SCALAR"
      },
      {
        "name": "version",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "name",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "categoryDescription",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "categoryCode",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "severity",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "description",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "code",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "occuranceCount",
        "type": "Long",
        "kind": "SCALAR"
      },
      {
        "name": "source",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "rawValue",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "equipmentResource",
        "type": "EquipmentResource",
        "kind": "OBJECT"
      },
      {
        "name": "timestamp",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "organization",
        "type": "Organization",
        "kind": "OBJECT"
      }
    ]
  },
  "DrivingEvent": {
    "name": "DrivingEvent",
    "fields": [
      {
        "name": "id",
        "type": "Long",
        "kind": "SCALAR"
      },
      {
        "name": "customFields",
        "type": "Map_String_ObjectScalar",
        "kind": "SCALAR"
      },
      {
        "name": "version",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "type",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "value",
        "type": "Float",
        "kind": "SCALAR"
      },
      {
        "name": "equipmentResource",
        "type": "EquipmentResource",
        "kind": "OBJECT"
      },
      {
        "name": "humanResource",
        "type": "HumanResource",
        "kind": "OBJECT"
      },
      {
        "name": "organization",
        "type": "Organization",
        "kind": "OBJECT"
      },
      {
        "name": "date",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "longitude",
        "type": "Float",
        "kind": "SCALAR"
      },
      {
        "name": "latitude",
        "type": "Float",
        "kind": "SCALAR"
      }
    ]
  },
  "EmissionRating": {
    "name": "EmissionRating",
    "fields": [
      {
        "name": "id",
        "type": "Long",
        "kind": "SCALAR"
      },
      {
        "name": "customFields",
        "type": "Map_String_ObjectScalar",
        "kind": "SCALAR"
      },
      {
        "name": "version",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "name",
        "type": "String",
        "kind": "SCALAR"
      }
    ]
  },
  "EntityToTag": {
    "name": "EntityToTag",
    "fields": [
      {
        "name": "id",
        "type": "Long",
        "kind": "SCALAR"
      },
      {
        "name": "customFields",
        "type": "Map_String_ObjectScalar",
        "kind": "SCALAR"
      },
      {
        "name": "version",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "entityId",
        "type": "Long",
        "kind": "SCALAR"
      },
      {
        "name": "tag",
        "type": "Tag",
        "kind": "OBJECT"
      },
      {
        "name": "entityName",
        "type": "String",
        "kind": "SCALAR"
      }
    ]
  },
  "EquipmentModel": {
    "name": "EquipmentModel",
    "fields": [
      {
        "name": "id",
        "type": "Long",
        "kind": "SCALAR"
      },
      {
        "name": "customFields",
        "type": "Map_String_ObjectScalar",
        "kind": "SCALAR"
      },
      {
        "name": "version",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "name",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "image",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "externalId",
        "type": "Long",
        "kind": "SCALAR"
      },
      {
        "name": "equipmentModelFamily",
        "type": "EquipmentModelFamily",
        "kind": "OBJECT"
      }
    ]
  },
  "EquipmentModelFamily": {
    "name": "EquipmentModelFamily",
    "fields": [
      {
        "name": "id",
        "type": "Long",
        "kind": "SCALAR"
      },
      {
        "name": "customFields",
        "type": "Map_String_ObjectScalar",
        "kind": "SCALAR"
      },
      {
        "name": "version",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "name",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "externalId",
        "type": "Long",
        "kind": "SCALAR"
      },
      {
        "name": "equipmentProductType",
        "type": "EquipmentProductType",
        "kind": "OBJECT"
      }
    ]
  },
  "EquipmentProductType": {
    "name": "EquipmentProductType",
    "fields": [
      {
        "name": "id",
        "type": "Long",
        "kind": "SCALAR"
      },
      {
        "name": "customFields",
        "type": "Map_String_ObjectScalar",
        "kind": "SCALAR"
      },
      {
        "name": "version",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "name",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "externalId",
        "type": "Long",
        "kind": "SCALAR"
      }
    ]
  },
  "EquipmentResource": {
    "name": "EquipmentResource",
    "fields": [
      {
        "name": "id",
        "type": "Long",
        "kind": "SCALAR"
      },
      {
        "name": "customFields",
        "type": "Map_String_ObjectScalar",
        "kind": "SCALAR"
      },
      {
        "name": "version",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "dataString",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "dataVersion",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "identifier",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "available",
        "type": "Boolean",
        "kind": "SCALAR"
      },
      {
        "name": "unitId",
        "type": "Long",
        "kind": "SCALAR"
      },
      {
        "name": "unit",
        "type": "Unit",
        "kind": "OBJECT"
      },
      {
        "name": "tags",
        "type": "EntityToTag",
        "kind": "LIST"
      },
      {
        "name": "organization",
        "type": "Organization",
        "kind": "OBJECT"
      },
      {
        "name": "astusId",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "imei",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "vehicleId",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "comment",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "closestAddressId",
        "type": "Long",
        "kind": "SCALAR"
      },
      {
        "name": "gpsSystemName",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "geofenceZones",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "hasFault",
        "type": "Boolean",
        "kind": "SCALAR"
      },
      {
        "name": "hasBlockingFault",
        "type": "Boolean",
        "kind": "SCALAR"
      },
      {
        "name": "longitude",
        "type": "Float",
        "kind": "SCALAR"
      },
      {
        "name": "latitude",
        "type": "Float",
        "kind": "SCALAR"
      },
      {
        "name": "attachmentId",
        "type": "Long",
        "kind": "SCALAR"
      },
      {
        "name": "carosery",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "weight",
        "type": "Float",
        "kind": "SCALAR"
      },
      {
        "name": "engineHours",
        "type": "Float",
        "kind": "SCALAR"
      },
      {
        "name": "odometer",
        "type": "Float",
        "kind": "SCALAR"
      },
      {
        "name": "plateNumber",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "tachy",
        "type": "Boolean",
        "kind": "SCALAR"
      },
      {
        "name": "registration",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "batteryLevel",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "territoriesName",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "territories",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "enteredTerritories",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "exitedTerritories",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "isAstusDown",
        "type": "Boolean",
        "kind": "SCALAR"
      },
      {
        "name": "lastDetectionDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "attachment",
        "type": "EquipmentResource",
        "kind": "OBJECT"
      },
      {
        "name": "attachmentParentId",
        "type": "Long",
        "kind": "SCALAR"
      },
      {
        "name": "equipmentType",
        "type": "EquipmentType",
        "kind": "OBJECT"
      },
      {
        "name": "equipmentModel",
        "type": "EquipmentModel",
        "kind": "OBJECT"
      },
      {
        "name": "detectedBy",
        "type": "EquipmentResource",
        "kind": "OBJECT"
      },
      {
        "name": "closestAddress",
        "type": "Address",
        "kind": "OBJECT"
      },
      {
        "name": "initBusParking",
        "type": "Address",
        "kind": "OBJECT"
      },
      {
        "name": "humanResources",
        "type": "HumanResource",
        "kind": "LIST"
      },
      {
        "name": "revisions",
        "type": "Revision",
        "kind": "LIST"
      },
      {
        "name": "faults",
        "type": "Fault",
        "kind": "LIST"
      },
      {
        "name": "updated",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "lastPointLatitude",
        "type": "Float",
        "kind": "SCALAR"
      },
      {
        "name": "lastPointLongitude",
        "type": "Float",
        "kind": "SCALAR"
      },
      {
        "name": "auditDateOverride",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "fieldsLastUpdate",
        "type": "Map_String_StringScalar",
        "kind": "SCALAR"
      },
      {
        "name": "engineState",
        "type": "EngineState",
        "kind": "ENUM"
      },
      {
        "name": "operating",
        "type": "Boolean",
        "kind": "SCALAR"
      },
      {
        "name": "status",
        "type": "EquipmentResourceStatus",
        "kind": "ENUM"
      },
      {
        "name": "videoRecorderId",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "hasMissionInProgress",
        "type": "Boolean",
        "kind": "SCALAR"
      },
      {
        "name": "archived",
        "type": "Boolean",
        "kind": "SCALAR"
      },
      {
        "name": "energySource",
        "type": "EnergySource",
        "kind": "ENUM"
      },
      {
        "name": "emissionRating",
        "type": "EmissionRating",
        "kind": "OBJECT"
      },
      {
        "name": "airportInferredFromGps",
        "type": "Airport",
        "kind": "OBJECT"
      },
      {
        "name": "astusProvidedDriver",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "fleetName",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "fleetOwner",
        "type": "String",
        "kind": "SCALAR"
      }
    ]
  },
  "EquipmentType": {
    "name": "EquipmentType",
    "fields": [
      {
        "name": "id",
        "type": "Long",
        "kind": "SCALAR"
      },
      {
        "name": "customFields",
        "type": "Map_String_ObjectScalar",
        "kind": "SCALAR"
      },
      {
        "name": "version",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "name",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "transportPositions",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "color",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "isNonMotorizedEquipment",
        "type": "Boolean",
        "kind": "SCALAR"
      },
      {
        "name": "assignable",
        "type": "Boolean",
        "kind": "SCALAR"
      },
      {
        "name": "icon",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "showOnMissionSegment",
        "type": "Boolean",
        "kind": "SCALAR"
      },
      {
        "name": "isAttachable",
        "type": "Boolean",
        "kind": "SCALAR"
      },
      {
        "name": "qualificationType",
        "type": "QualificationType",
        "kind": "OBJECT"
      },
      {
        "name": "equipmentTypeTasks",
        "type": "EquipmentTypeTask",
        "kind": "LIST"
      }
    ]
  },
  "EquipmentTypeTask": {
    "name": "EquipmentTypeTask",
    "fields": [
      {
        "name": "id",
        "type": "Long",
        "kind": "SCALAR"
      },
      {
        "name": "customFields",
        "type": "Map_String_ObjectScalar",
        "kind": "SCALAR"
      },
      {
        "name": "version",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "quantity",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "exclusive",
        "type": "Boolean",
        "kind": "SCALAR"
      },
      {
        "name": "cumulative",
        "type": "Boolean",
        "kind": "SCALAR"
      },
      {
        "name": "equipmentType",
        "type": "EquipmentType",
        "kind": "OBJECT"
      },
      {
        "name": "taskType",
        "type": "TaskType",
        "kind": "OBJECT"
      }
    ]
  },
  "EquipmentUsageLog": {
    "name": "EquipmentUsageLog",
    "fields": [
      {
        "name": "id",
        "type": "Long",
        "kind": "SCALAR"
      },
      {
        "name": "customFields",
        "type": "Map_String_ObjectScalar",
        "kind": "SCALAR"
      },
      {
        "name": "version",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "creationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "type",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "user",
        "type": "User",
        "kind": "OBJECT"
      },
      {
        "name": "driver",
        "type": "HumanResource",
        "kind": "OBJECT"
      },
      {
        "name": "oldVehicle",
        "type": "EquipmentResource",
        "kind": "OBJECT"
      },
      {
        "name": "vehicle",
        "type": "EquipmentResource",
        "kind": "OBJECT"
      }
    ]
  },
  "Fault": {
    "name": "Fault",
    "fields": [
      {
        "name": "id",
        "type": "Long",
        "kind": "SCALAR"
      },
      {
        "name": "customFields",
        "type": "Map_String_ObjectScalar",
        "kind": "SCALAR"
      },
      {
        "name": "version",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "createDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "validationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "closeDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "status",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "createUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "validationUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "closingUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "comments",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "immobilisationPlace",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "equipmentResource",
        "type": "EquipmentResource",
        "kind": "OBJECT"
      },
      {
        "name": "faultReason",
        "type": "FaultReason",
        "kind": "OBJECT"
      },
      {
        "name": "faultType",
        "type": "FaultType",
        "kind": "OBJECT"
      },
      {
        "name": "maintenance",
        "type": "Maintenance",
        "kind": "OBJECT"
      }
    ]
  },
  "FaultReason": {
    "name": "FaultReason",
    "fields": [
      {
        "name": "id",
        "type": "Long",
        "kind": "SCALAR"
      },
      {
        "name": "customFields",
        "type": "Map_String_ObjectScalar",
        "kind": "SCALAR"
      },
      {
        "name": "version",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "message",
        "type": "String",
        "kind": "SCALAR"
      }
    ]
  },
  "FaultType": {
    "name": "FaultType",
    "fields": [
      {
        "name": "id",
        "type": "Long",
        "kind": "SCALAR"
      },
      {
        "name": "customFields",
        "type": "Map_String_ObjectScalar",
        "kind": "SCALAR"
      },
      {
        "name": "version",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "message",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "blocking",
        "type": "Boolean",
        "kind": "SCALAR"
      }
    ]
  },
  "FeatureParamsEntity": {
    "name": "FeatureParamsEntity",
    "fields": [
      {
        "name": "id",
        "type": "Long",
        "kind": "SCALAR"
      },
      {
        "name": "customFields",
        "type": "Map_String_ObjectScalar",
        "kind": "SCALAR"
      },
      {
        "name": "version",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "name",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "identifier",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "enabled",
        "type": "Boolean",
        "kind": "SCALAR"
      },
      {
        "name": "paramAsString",
        "type": "String",
        "kind": "SCALAR"
      }
    ]
  },
  "Flight": {
    "name": "Flight",
    "fields": [
      {
        "name": "id",
        "type": "Long",
        "kind": "SCALAR"
      },
      {
        "name": "customFields",
        "type": "Map_String_ObjectScalar",
        "kind": "SCALAR"
      },
      {
        "name": "version",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "dataString",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "dataVersion",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "name",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "date",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "day",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "tasks",
        "type": null,
        "kind": "LIST"
      },
      {
        "name": "airline",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "destination",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "origin",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "initialDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "planeIdentifier",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "defaultTerminal",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "sariaPort",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "startParkingDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "endParkingDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "terminal",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "status",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "irgCode",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "lastKnownDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "lastKnownDateType",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "departure",
        "type": "Boolean",
        "kind": "SCALAR"
      },
      {
        "name": "showFlightInGantt",
        "type": "Boolean",
        "kind": "SCALAR"
      },
      {
        "name": "showFlightInList",
        "type": "Boolean",
        "kind": "SCALAR"
      },
      {
        "name": "defaultShowFlightInGantt",
        "type": "Byte",
        "kind": "SCALAR"
      },
      {
        "name": "number",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "ifubs",
        "type": "Boolean",
        "kind": "SCALAR"
      },
      {
        "name": "departureDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "passengersNo",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "priority",
        "type": "Boolean",
        "kind": "SCALAR"
      },
      {
        "name": "treated",
        "type": "Boolean",
        "kind": "SCALAR"
      },
      {
        "name": "comment",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "flightType",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "schengen",
        "type": "Boolean",
        "kind": "SCALAR"
      },
      {
        "name": "ifubsForEditor",
        "type": "Boolean",
        "kind": "SCALAR"
      },
      {
        "name": "flightVersion",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "is3N",
        "type": "Boolean",
        "kind": "SCALAR"
      },
      {
        "name": "rotationFlightId",
        "type": "Long",
        "kind": "SCALAR"
      },
      {
        "name": "rotationIdSetFromFlex",
        "type": "Long",
        "kind": "SCALAR"
      },
      {
        "name": "ganttStart",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "ganttEnd",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "personnelOnboard",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "passengerWithDisabilities",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "orderDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "canceled",
        "type": "Boolean",
        "kind": "SCALAR"
      },
      {
        "name": "importedBy",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "sariaPaxUpdateDisable",
        "type": "Boolean",
        "kind": "SCALAR"
      },
      {
        "name": "sariaParkingUpdateDisable",
        "type": "Boolean",
        "kind": "SCALAR"
      },
      {
        "name": "sariaPaxParkingUpdateDisable",
        "type": "Boolean",
        "kind": "SCALAR"
      },
      {
        "name": "sariaShowFlightInGanttUpdateDisable",
        "type": "Boolean",
        "kind": "SCALAR"
      },
      {
        "name": "layover2Name",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "layover2Date",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "layover1ArrivalDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "layover1DepartureDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "season",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "airport",
        "type": "Airport",
        "kind": "OBJECT"
      },
      {
        "name": "timeSlot",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "groundAgent",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "towedEstimatedStartTime",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "towedRealStartTime",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "towedRealEndTime",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "towedStatus",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "departParkingTime",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "rotationName",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "verified",
        "type": "Boolean",
        "kind": "SCALAR"
      },
      {
        "name": "cycleDepartureOrder",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "originalDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "customObjectCode",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "crewShuttle",
        "type": "Boolean",
        "kind": "SCALAR"
      },
      {
        "name": "onHold",
        "type": "Boolean",
        "kind": "SCALAR"
      },
      {
        "name": "inHangar",
        "type": "Boolean",
        "kind": "SCALAR"
      },
      {
        "name": "parking",
        "type": "Address",
        "kind": "OBJECT"
      },
      {
        "name": "paxParking",
        "type": "Address",
        "kind": "OBJECT"
      },
      {
        "name": "planeType",
        "type": "PlaneType",
        "kind": "OBJECT"
      },
      {
        "name": "galery",
        "type": "Address",
        "kind": "OBJECT"
      },
      {
        "name": "oilGroup",
        "type": "OilGroup",
        "kind": "OBJECT"
      },
      {
        "name": "invoiceAccount",
        "type": "InvoiceAccount",
        "kind": "OBJECT"
      },
      {
        "name": "unit",
        "type": "Unit",
        "kind": "OBJECT"
      },
      {
        "name": "towedAddress",
        "type": "Address",
        "kind": "OBJECT"
      },
      {
        "name": "towedInitialAddress",
        "type": "Address",
        "kind": "OBJECT"
      },
      {
        "name": "dayTemplate",
        "type": "DayTemplate",
        "kind": "OBJECT"
      },
      {
        "name": "flightSchedule",
        "type": "FlightSchedule",
        "kind": "OBJECT"
      },
      {
        "name": "blockAutoUpdates",
        "type": "Boolean",
        "kind": "SCALAR"
      },
      {
        "name": "staffNo",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "organization",
        "type": "Organization",
        "kind": "OBJECT"
      },
      {
        "name": "estimatedDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "rotationFlight",
        "type": "Flight",
        "kind": "OBJECT"
      },
      {
        "name": "blockDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "lockedFields",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "arrivalAirline",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "arrivalNumber",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "arrivalDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "arrivalInitialDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "arrivalOrigin",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "arrivalPlaneIdentifier",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "departureAirline",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "departureNumber",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "departureDepartureDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "departureInitialDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "departureDestination",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "departurePlaneIdentifier",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "EFuelDate",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "EFuelQuantity",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "EFuelState",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "UAEventName",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "agentParking",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "alertCreateDefaultObjectsNoReset",
        "type": "Boolean",
        "kind": "SCALAR"
      },
      {
        "name": "alreadyEngaged",
        "type": "Boolean",
        "kind": "SCALAR"
      },
      {
        "name": "averageVolume",
        "type": "Long",
        "kind": "SCALAR"
      },
      {
        "name": "baggages",
        "type": null,
        "kind": "LIST"
      },
      {
        "name": "blockObjectCreation",
        "type": "Boolean",
        "kind": "SCALAR"
      },
      {
        "name": "busType",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "color",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "crewShuttleModified",
        "type": "Boolean",
        "kind": "SCALAR"
      },
      {
        "name": "degradedMode",
        "type": "Boolean",
        "kind": "SCALAR"
      },
      {
        "name": "delayedFlight",
        "type": "Boolean",
        "kind": "SCALAR"
      },
      {
        "name": "deltaEventCode",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "dhcCTime",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "duration",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "eco",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "hasArrived",
        "type": "Boolean",
        "kind": "SCALAR"
      },
      {
        "name": "hc",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "lastModifiedDate",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "lastModifiedDestination",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "lastModifiedOrigin",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "lastModifiedParking",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "lastModifiedPlaneIdentifier",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "lastModifiedPlaneType",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "lastModifiedUpdateDate",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "modifiedFlight",
        "type": "Boolean",
        "kind": "SCALAR"
      },
      {
        "name": "modifiedObjects",
        "type": "Boolean",
        "kind": "SCALAR"
      },
      {
        "name": "modifiedPlaneIdentifier",
        "type": "Boolean",
        "kind": "SCALAR"
      },
      {
        "name": "offloadStatus",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "offset",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "parkingChanged",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "parkingType",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "passaerelle",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "recurrentComment",
        "type": "Boolean",
        "kind": "SCALAR"
      },
      {
        "name": "temporaryStorage",
        "type": "String",
        "kind": "SCALAR"
      }
    ]
  },
  "FlightAndObjectAction": {
    "name": "FlightAndObjectAction",
    "fields": [
      {
        "name": "id",
        "type": "Long",
        "kind": "SCALAR"
      },
      {
        "name": "customFields",
        "type": "Map_String_ObjectScalar",
        "kind": "SCALAR"
      },
      {
        "name": "version",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "airline",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "airport",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "number",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "daysOfTheWeek",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "origin",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "destination",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "departure",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "startTime",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "endTime",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "terminal",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "schengen",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "parkingIsContact",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "priority",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "isException",
        "type": "Boolean",
        "kind": "SCALAR"
      },
      {
        "name": "priorityObjects",
        "type": "Boolean",
        "kind": "SCALAR"
      },
      {
        "name": "flightType",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "canceled",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "sariaPort",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "lowerPassengerNumber",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "upperPassengerNumber",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "lowerStaffNumber",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "upperStaffNumber",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "paxType",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "busType",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "degradedModeCondition",
        "type": "Boolean",
        "kind": "SCALAR"
      },
      {
        "name": "flightAndRotationSameUnit",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "showFlightInGantt",
        "type": "Byte",
        "kind": "SCALAR"
      },
      {
        "name": "showFlightInList",
        "type": "Byte",
        "kind": "SCALAR"
      },
      {
        "name": "is3N",
        "type": "Boolean",
        "kind": "SCALAR"
      },
      {
        "name": "flightOffset",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "usedModificationFields",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "color",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "flightComment",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "flightSchengen",
        "type": "Boolean",
        "kind": "SCALAR"
      },
      {
        "name": "degradedModeModification",
        "type": "Boolean",
        "kind": "SCALAR"
      },
      {
        "name": "quantity",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "objectName",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "objectPriority",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "objectOffset",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "objectGalley",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "objectApproachNumber",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "objectMessageCreation",
        "type": "Boolean",
        "kind": "SCALAR"
      },
      {
        "name": "duration",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "useObjectCreation",
        "type": "Boolean",
        "kind": "SCALAR"
      },
      {
        "name": "registrations",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "groundTimeLower",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "groundTimeHigher",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "vraIndex",
        "type": "Float",
        "kind": "SCALAR"
      },
      {
        "name": "objectExceptionEvent",
        "type": "Boolean",
        "kind": "SCALAR"
      },
      {
        "name": "objectFinishMissionEvent",
        "type": "Boolean",
        "kind": "SCALAR"
      },
      {
        "name": "groundAgent",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "planeTypeList",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "name",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "eventMonitoringRulesJson",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "nightStop",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "flightPriority",
        "type": "Boolean",
        "kind": "SCALAR"
      },
      {
        "name": "overlapEscale",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "cycleDepartureOrder",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "withRotation",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "withMatriculationGroup",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "customObjectCode",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "crewShuttle",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "requiredEquipmentResourceQualificationType",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "equipmentResourceFillPercentage",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "blockObjectCreation",
        "type": "Boolean",
        "kind": "SCALAR"
      },
      {
        "name": "planeType",
        "type": "PlaneType",
        "kind": "OBJECT"
      },
      {
        "name": "parking",
        "type": "Address",
        "kind": "OBJECT"
      },
      {
        "name": "oilGroup",
        "type": "OilGroup",
        "kind": "OBJECT"
      },
      {
        "name": "taskType",
        "type": "TaskType",
        "kind": "OBJECT"
      },
      {
        "name": "objectParking",
        "type": "Address",
        "kind": "OBJECT"
      },
      {
        "name": "objectAlternateParking",
        "type": "Address",
        "kind": "OBJECT"
      },
      {
        "name": "flightUnit",
        "type": "Unit",
        "kind": "OBJECT"
      },
      {
        "name": "parent",
        "type": "FlightAndObjectAction",
        "kind": "OBJECT"
      },
      {
        "name": "unit",
        "type": "Unit",
        "kind": "OBJECT"
      },
      {
        "name": "originGroup",
        "type": "ValuesGroup",
        "kind": "OBJECT"
      },
      {
        "name": "destinationGroup",
        "type": "ValuesGroup",
        "kind": "OBJECT"
      },
      {
        "name": "planeTypeGroup",
        "type": "ValuesGroup",
        "kind": "OBJECT"
      },
      {
        "name": "overlapEscaleGroup",
        "type": "ValuesGroup",
        "kind": "OBJECT"
      },
      {
        "name": "matriculationGroup",
        "type": "ValuesGroup",
        "kind": "OBJECT"
      },
      {
        "name": "parkingGroup",
        "type": "ValuesGroup",
        "kind": "OBJECT"
      },
      {
        "name": "children",
        "type": "FlightAndObjectAction",
        "kind": "LIST"
      },
      {
        "name": "emrjUseRotationFlightAsReference",
        "type": "Boolean",
        "kind": "SCALAR"
      },
      {
        "name": "flightVersion",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "organization",
        "type": "Organization",
        "kind": "OBJECT"
      },
      {
        "name": "objectOffsetForRotationFlight",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "enabled",
        "type": "Boolean",
        "kind": "SCALAR"
      },
      {
        "name": "objectComment",
        "type": "String",
        "kind": "SCALAR"
      }
    ]
  },
  "FlightConnexion": {
    "name": "FlightConnexion",
    "fields": [
      {
        "name": "id",
        "type": "Long",
        "kind": "SCALAR"
      },
      {
        "name": "customFields",
        "type": "Map_String_ObjectScalar",
        "kind": "SCALAR"
      },
      {
        "name": "version",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "incomingFlightAirline",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "incomingFlightNumber",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "incomingFlightDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "incomingFlightInitialDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "outgoingFlightAirline",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "outgoingFlightNumber",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "outgoingFlightDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "outgoingFlightInitialDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "pft",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "attendedBaggages",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "arrivedBaggages",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "inPftBaggages",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "confirmedBaggages",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "totalBaggages",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "outgoingFlightInitialDay",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "trieurOut",
        "type": "Address",
        "kind": "OBJECT"
      },
      {
        "name": "trieurIn",
        "type": "Address",
        "kind": "OBJECT"
      },
      {
        "name": "incomingFlight",
        "type": "Flight",
        "kind": "OBJECT"
      },
      {
        "name": "outgoingFlight",
        "type": "Flight",
        "kind": "OBJECT"
      }
    ]
  },
  "FlightSchedule": {
    "name": "FlightSchedule",
    "fields": [
      {
        "name": "id",
        "type": "Long",
        "kind": "SCALAR"
      },
      {
        "name": "customFields",
        "type": "Map_String_ObjectScalar",
        "kind": "SCALAR"
      },
      {
        "name": "version",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "airline",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "flightNumber",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "startDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "endDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "deactivatedAt",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "daysOfTheWeek",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "rotation",
        "type": "FlightSchedule",
        "kind": "OBJECT"
      },
      {
        "name": "targetForThisException",
        "type": "FlightSchedule",
        "kind": "OBJECT"
      },
      {
        "name": "rotationDuration",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "airport",
        "type": "Airport",
        "kind": "OBJECT"
      },
      {
        "name": "departure",
        "type": "Boolean",
        "kind": "SCALAR"
      },
      {
        "name": "properties",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "diffToken",
        "type": "String",
        "kind": "SCALAR"
      }
    ]
  },
  "GanttAssignment": {
    "name": "GanttAssignment",
    "fields": [
      {
        "name": "id",
        "type": "Long",
        "kind": "SCALAR"
      },
      {
        "name": "customFields",
        "type": "Map_String_ObjectScalar",
        "kind": "SCALAR"
      },
      {
        "name": "version",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "name",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "flightsStartDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "flightsEndDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "inputEntitiesCsv",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "outputEntitiesCsv",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "params",
        "type": "GanttAssignmentParams",
        "kind": "OBJECT"
      },
      {
        "name": "organization",
        "type": "Organization",
        "kind": "OBJECT"
      },
      {
        "name": "origin",
        "type": "GanttAssignment",
        "kind": "OBJECT"
      },
      {
        "name": "algorithmType",
        "type": "AlgorithmType",
        "kind": "ENUM"
      },
      {
        "name": "maxAvailableResources",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "readOnly",
        "type": "Boolean",
        "kind": "SCALAR"
      }
    ]
  },
  "GanttAssignmentParams": {
    "name": "GanttAssignmentParams",
    "fields": [
      {
        "name": "id",
        "type": "Long",
        "kind": "SCALAR"
      },
      {
        "name": "customFields",
        "type": "Map_String_ObjectScalar",
        "kind": "SCALAR"
      },
      {
        "name": "version",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "name",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "flightAndObjectActionFilter",
        "type": "String",
        "kind": "SCALAR"
      }
    ]
  },
  "HistoricalMapAnalysis": {
    "name": "HistoricalMapAnalysis",
    "fields": [
      {
        "name": "id",
        "type": "Long",
        "kind": "SCALAR"
      },
      {
        "name": "customFields",
        "type": "Map_String_ObjectScalar",
        "kind": "SCALAR"
      },
      {
        "name": "version",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "startDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "endDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "name",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "entries",
        "type": "HistoricalMapAnalysisEntry",
        "kind": "LIST"
      }
    ]
  },
  "HistoricalMapAnalysisEntry": {
    "name": "HistoricalMapAnalysisEntry",
    "fields": [
      {
        "name": "id",
        "type": "Long",
        "kind": "SCALAR"
      },
      {
        "name": "customFields",
        "type": "Map_String_ObjectScalar",
        "kind": "SCALAR"
      },
      {
        "name": "version",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "equipment",
        "type": "EquipmentResource",
        "kind": "OBJECT"
      },
      {
        "name": "historicalMapAnalysis",
        "type": "HistoricalMapAnalysis",
        "kind": "OBJECT"
      },
      {
        "name": "position",
        "type": "Long",
        "kind": "SCALAR"
      }
    ]
  },
  "HistoryTrack": {
    "name": "HistoryTrack",
    "fields": [
      {
        "name": "id",
        "type": "Long",
        "kind": "SCALAR"
      },
      {
        "name": "customFields",
        "type": "Map_String_ObjectScalar",
        "kind": "SCALAR"
      },
      {
        "name": "version",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "startTime",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "endTime",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "color",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "show",
        "type": "Boolean",
        "kind": "SCALAR"
      },
      {
        "name": "highlight",
        "type": "Boolean",
        "kind": "SCALAR"
      },
      {
        "name": "equipmentResource",
        "type": "EquipmentResource",
        "kind": "OBJECT"
      },
      {
        "name": "startEngineHours",
        "type": "Float",
        "kind": "SCALAR"
      },
      {
        "name": "endEngineHours",
        "type": "Float",
        "kind": "SCALAR"
      },
      {
        "name": "startOdometer",
        "type": "Float",
        "kind": "SCALAR"
      },
      {
        "name": "endOdometer",
        "type": "Float",
        "kind": "SCALAR"
      },
      {
        "name": "idleDuration",
        "type": "Long",
        "kind": "SCALAR"
      },
      {
        "name": "movingDuration",
        "type": "Long",
        "kind": "SCALAR"
      },
      {
        "name": "maxSpeed",
        "type": "Int",
        "kind": "SCALAR"
      }
    ]
  },
  "HumanResource": {
    "name": "HumanResource",
    "fields": [
      {
        "name": "id",
        "type": "Long",
        "kind": "SCALAR"
      },
      {
        "name": "customFields",
        "type": "Map_String_ObjectScalar",
        "kind": "SCALAR"
      },
      {
        "name": "version",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "dataString",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "dataVersion",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "identifier",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "available",
        "type": "Boolean",
        "kind": "SCALAR"
      },
      {
        "name": "unitId",
        "type": "Long",
        "kind": "SCALAR"
      },
      {
        "name": "unit",
        "type": "Unit",
        "kind": "OBJECT"
      },
      {
        "name": "tags",
        "type": "EntityToTag",
        "kind": "LIST"
      },
      {
        "name": "organization",
        "type": "Organization",
        "kind": "OBJECT"
      },
      {
        "name": "firstName",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "lastName",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "todayStartTime",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "todayEndTime",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "todayBreakStartTime",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "todayBreakEndTime",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "comment",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "pdaIdentifier",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "shiftId",
        "type": "Long",
        "kind": "SCALAR"
      },
      {
        "name": "rfidIdentifier",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "partialInactivity",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "vehicleId",
        "type": "Long",
        "kind": "SCALAR"
      },
      {
        "name": "mobileDeviceId",
        "type": "Long",
        "kind": "SCALAR"
      },
      {
        "name": "nrOfCurrentMissions",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "nrOfCurrentMissionsAndOtherActivities",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "lastMission",
        "type": "Map_Object_ObjectScalar",
        "kind": "SCALAR"
      },
      {
        "name": "radioId",
        "type": "Long",
        "kind": "SCALAR"
      },
      {
        "name": "mobileNumber",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "vehicle",
        "type": "EquipmentResource",
        "kind": "OBJECT"
      },
      {
        "name": "mobileDevice",
        "type": "MobileDevice",
        "kind": "OBJECT"
      },
      {
        "name": "shift",
        "type": "HumanResourceShift",
        "kind": "OBJECT"
      },
      {
        "name": "radio",
        "type": "Radio",
        "kind": "OBJECT"
      },
      {
        "name": "qualifications",
        "type": "Qualification",
        "kind": "LIST"
      },
      {
        "name": "lastEquipmentResourceAssignment",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "lastEquipmentResourceAssignmentViaRfid",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "borrowedToUnit",
        "type": "Unit",
        "kind": "OBJECT"
      },
      {
        "name": "userId",
        "type": "Long",
        "kind": "SCALAR"
      },
      {
        "name": "archived",
        "type": "Boolean",
        "kind": "SCALAR"
      },
      {
        "name": "correspondingAstusName",
        "type": "String",
        "kind": "SCALAR"
      }
    ]
  },
  "HumanResourceLog": {
    "name": "HumanResourceLog",
    "fields": [
      {
        "name": "id",
        "type": "Long",
        "kind": "SCALAR"
      },
      {
        "name": "customFields",
        "type": "Map_String_ObjectScalar",
        "kind": "SCALAR"
      },
      {
        "name": "version",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "date",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "event",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "humanResource",
        "type": "HumanResource",
        "kind": "OBJECT"
      }
    ]
  },
  "HumanResourceMessage": {
    "name": "HumanResourceMessage",
    "fields": [
      {
        "name": "id",
        "type": "Long",
        "kind": "SCALAR"
      },
      {
        "name": "customFields",
        "type": "Map_String_ObjectScalar",
        "kind": "SCALAR"
      },
      {
        "name": "version",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "message",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "xopsUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "sentTime",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "seen",
        "type": "Boolean",
        "kind": "SCALAR"
      },
      {
        "name": "fromHumanResource",
        "type": "Boolean",
        "kind": "SCALAR"
      },
      {
        "name": "airline",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "flightNumber",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "flightDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "answer",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "isAlert",
        "type": "Boolean",
        "kind": "SCALAR"
      },
      {
        "name": "eventId",
        "type": "Long",
        "kind": "SCALAR"
      },
      {
        "name": "observation",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "isComment",
        "type": "Boolean",
        "kind": "SCALAR"
      },
      {
        "name": "unitName",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "humanResource",
        "type": "HumanResource",
        "kind": "OBJECT"
      },
      {
        "name": "stop",
        "type": "Stop2",
        "kind": "OBJECT"
      },
      {
        "name": "flightParking",
        "type": "Address",
        "kind": "OBJECT"
      },
      {
        "name": "flightPlaneType",
        "type": "PlaneType",
        "kind": "OBJECT"
      }
    ]
  },
  "HumanResourceSchedule": {
    "name": "HumanResourceSchedule",
    "fields": [
      {
        "name": "id",
        "type": "Long",
        "kind": "SCALAR"
      },
      {
        "name": "customFields",
        "type": "Map_String_ObjectScalar",
        "kind": "SCALAR"
      },
      {
        "name": "version",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "startTime",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "endTime",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "humanResourceId",
        "type": "Long",
        "kind": "SCALAR"
      },
      {
        "name": "finished",
        "type": "Boolean",
        "kind": "SCALAR"
      },
      {
        "name": "finishedDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "units",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "autoPauseProcessed",
        "type": "Boolean",
        "kind": "SCALAR"
      },
      {
        "name": "shiftName",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "regulator",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "mole",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "driverAssistant1Id",
        "type": "Long",
        "kind": "SCALAR"
      },
      {
        "name": "driverAssistant2Id",
        "type": "Long",
        "kind": "SCALAR"
      },
      {
        "name": "humanResource",
        "type": "HumanResource",
        "kind": "OBJECT"
      },
      {
        "name": "equipment",
        "type": "EquipmentResource",
        "kind": "OBJECT"
      },
      {
        "name": "radio",
        "type": "Radio",
        "kind": "OBJECT"
      },
      {
        "name": "driverAssistant1",
        "type": "HumanResource",
        "kind": "OBJECT"
      },
      {
        "name": "driverAssistant2",
        "type": "HumanResource",
        "kind": "OBJECT"
      },
      {
        "name": "team",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "teamName",
        "type": "String",
        "kind": "SCALAR"
      }
    ]
  },
  "HumanResourceShift": {
    "name": "HumanResourceShift",
    "fields": [
      {
        "name": "id",
        "type": "Long",
        "kind": "SCALAR"
      },
      {
        "name": "customFields",
        "type": "Map_String_ObjectScalar",
        "kind": "SCALAR"
      },
      {
        "name": "version",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "name",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "startTime",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "endTime",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "startBreakTime",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "endBreakTime",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "startBreakTime2",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "endBreakTime2",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "unit",
        "type": "Unit",
        "kind": "OBJECT"
      },
      {
        "name": "organization",
        "type": "Organization",
        "kind": "OBJECT"
      }
    ]
  },
  "InactivityType": {
    "name": "InactivityType",
    "fields": [
      {
        "name": "id",
        "type": "Long",
        "kind": "SCALAR"
      },
      {
        "name": "customFields",
        "type": "Map_String_ObjectScalar",
        "kind": "SCALAR"
      },
      {
        "name": "version",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "name",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "duration",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "isBillable",
        "type": "Boolean",
        "kind": "SCALAR"
      },
      {
        "name": "color",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "isPartial",
        "type": "Boolean",
        "kind": "SCALAR"
      },
      {
        "name": "isSpecial",
        "type": "Boolean",
        "kind": "SCALAR"
      },
      {
        "name": "filterSchedulesByIt",
        "type": "Boolean",
        "kind": "SCALAR"
      },
      {
        "name": "hasEvents",
        "type": "Boolean",
        "kind": "SCALAR"
      },
      {
        "name": "hideInGantt",
        "type": "Boolean",
        "kind": "SCALAR"
      },
      {
        "name": "allowOverlapping",
        "type": "Boolean",
        "kind": "SCALAR"
      },
      {
        "name": "vraIndex",
        "type": "Float",
        "kind": "SCALAR"
      },
      {
        "name": "archived",
        "type": "Boolean",
        "kind": "SCALAR"
      }
    ]
  },
  "Interdiction": {
    "name": "Interdiction",
    "fields": [
      {
        "name": "id",
        "type": "Long",
        "kind": "SCALAR"
      },
      {
        "name": "customFields",
        "type": "Map_String_ObjectScalar",
        "kind": "SCALAR"
      },
      {
        "name": "version",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "airline",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "planeTypeObservations",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "humanResource",
        "type": "HumanResource",
        "kind": "OBJECT"
      },
      {
        "name": "equipmentResource",
        "type": "EquipmentResource",
        "kind": "OBJECT"
      },
      {
        "name": "address",
        "type": "Address",
        "kind": "OBJECT"
      },
      {
        "name": "taskType",
        "type": "TaskType",
        "kind": "OBJECT"
      },
      {
        "name": "zone",
        "type": "Zone",
        "kind": "OBJECT"
      }
    ]
  },
  "InventoryHistoryData": {
    "name": "InventoryHistoryData",
    "fields": [
      {
        "name": "id",
        "type": "Long",
        "kind": "SCALAR"
      },
      {
        "name": "customFields",
        "type": "Map_String_ObjectScalar",
        "kind": "SCALAR"
      },
      {
        "name": "version",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "driverName",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "driverIdentifier",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "zone",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "creationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "akhData",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "akeData",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "cpcData",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "cpbData",
        "type": "String",
        "kind": "SCALAR"
      }
    ]
  },
  "InvoiceAccount": {
    "name": "InvoiceAccount",
    "fields": [
      {
        "name": "id",
        "type": "Long",
        "kind": "SCALAR"
      },
      {
        "name": "customFields",
        "type": "Map_String_ObjectScalar",
        "kind": "SCALAR"
      },
      {
        "name": "version",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "name",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "code",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "details",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "contractNumber",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "startDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "endDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "payType",
        "type": "String",
        "kind": "SCALAR"
      }
    ]
  },
  "Maintenance": {
    "name": "Maintenance",
    "fields": [
      {
        "name": "id",
        "type": "Long",
        "kind": "SCALAR"
      },
      {
        "name": "customFields",
        "type": "Map_String_ObjectScalar",
        "kind": "SCALAR"
      },
      {
        "name": "version",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "name",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "address",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "telephone",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "email",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "faxNumber",
        "type": "String",
        "kind": "SCALAR"
      }
    ]
  },
  "Mission2": {
    "name": "Mission2",
    "fields": [
      {
        "name": "id",
        "type": "Long",
        "kind": "SCALAR"
      },
      {
        "name": "customFields",
        "type": "Map_String_ObjectScalar",
        "kind": "SCALAR"
      },
      {
        "name": "version",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "dataString",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "dataVersion",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "startTime",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "endTime",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "creationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "isSpecial",
        "type": "Boolean",
        "kind": "SCALAR"
      },
      {
        "name": "day",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "status",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "humanResourceId",
        "type": "Long",
        "kind": "SCALAR"
      },
      {
        "name": "equipmentResourceId",
        "type": "Long",
        "kind": "SCALAR"
      },
      {
        "name": "initialStartTime",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "initialEndTime",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "availableEventTypes",
        "type": "String",
        "kind": "LIST"
      },
      {
        "name": "startTimeManual",
        "type": "Boolean",
        "kind": "SCALAR"
      },
      {
        "name": "endTimeManual",
        "type": "Boolean",
        "kind": "SCALAR"
      },
      {
        "name": "inactivityTypeId",
        "type": "Long",
        "kind": "SCALAR"
      },
      {
        "name": "unitName",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "priority",
        "type": "Long",
        "kind": "SCALAR"
      },
      {
        "name": "comment",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "comment2",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "creationMode",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "billingDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "mobileDeviceId",
        "type": "Long",
        "kind": "SCALAR"
      },
      {
        "name": "driverAssistant1",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "driverAssistant2",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "mole",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "eventMonitoringExpectedNumberOfEvents",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "eventMonitoringActualNumberOfEvents",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "eventMonitoringAutoNumberOfEvents",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "eventMonitoringStatus",
        "type": "EventMonitoringStatusEnum",
        "kind": "ENUM"
      },
      {
        "name": "color",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "startAddress",
        "type": "Address",
        "kind": "OBJECT"
      },
      {
        "name": "endAddress",
        "type": "Address",
        "kind": "OBJECT"
      },
      {
        "name": "humanResource",
        "type": "HumanResource",
        "kind": "OBJECT"
      },
      {
        "name": "equipmentResource",
        "type": "EquipmentResource",
        "kind": "OBJECT"
      },
      {
        "name": "equipmentType",
        "type": "EquipmentType",
        "kind": "OBJECT"
      },
      {
        "name": "inactivityType",
        "type": "InactivityType",
        "kind": "OBJECT"
      },
      {
        "name": "mobileDevice",
        "type": "MobileDevice",
        "kind": "OBJECT"
      },
      {
        "name": "stops",
        "type": "Stop2",
        "kind": "LIST"
      },
      {
        "name": "events",
        "type": null,
        "kind": "LIST"
      },
      {
        "name": "validationMessages",
        "type": "ValidationMessage",
        "kind": "LIST"
      },
      {
        "name": "requestStatus",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "type",
        "type": "MissionType",
        "kind": "OBJECT"
      },
      {
        "name": "currentState",
        "type": "MissionWorkflowElement",
        "kind": "OBJECT"
      },
      {
        "name": "currentStopId",
        "type": "Long",
        "kind": "SCALAR"
      },
      {
        "name": "organization",
        "type": "Organization",
        "kind": "OBJECT"
      },
      {
        "name": "tasks",
        "type": null,
        "kind": "LIST"
      },
      {
        "name": "activatedFromFlex",
        "type": "Boolean",
        "kind": "SCALAR"
      },
      {
        "name": "additionalStatus",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "endOfMission",
        "type": "Boolean",
        "kind": "SCALAR"
      },
      {
        "name": "endVolumeIndex",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "finishedByUser",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "flightPriorityComment",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "flightPriorityType",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "hasNewDriver",
        "type": "Boolean",
        "kind": "SCALAR"
      },
      {
        "name": "hasNoFuelEvent",
        "type": "Boolean",
        "kind": "SCALAR"
      },
      {
        "name": "initialObjectsNumber",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "oilGroupBonNumber",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "oilGroupName",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "oldDriverId",
        "type": "Long",
        "kind": "SCALAR"
      },
      {
        "name": "orderOfFlightsLocked",
        "type": "Boolean",
        "kind": "SCALAR"
      },
      {
        "name": "previousEndVolumeIndex",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "specialMissionFlightString",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "specialMissionOverlap",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "startVolumeIndex",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "userName",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "validationErrors",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "vehicleAttachment",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "vehicleCapacity",
        "type": "Float",
        "kind": "SCALAR"
      },
      {
        "name": "volume",
        "type": "Long",
        "kind": "SCALAR"
      }
    ]
  },
  "MissionEvent2": {
    "name": "MissionEvent2",
    "fields": [
      {
        "name": "id",
        "type": "Long",
        "kind": "SCALAR"
      },
      {
        "name": "customFields",
        "type": "Map_String_ObjectScalar",
        "kind": "SCALAR"
      },
      {
        "name": "version",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "dataString",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "dataVersion",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "missionId",
        "type": "Long",
        "kind": "SCALAR"
      },
      {
        "name": "missionIdOld",
        "type": "Long",
        "kind": "SCALAR"
      },
      {
        "name": "uidForTimeout",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "stopId",
        "type": "Long",
        "kind": "SCALAR"
      },
      {
        "name": "taskId",
        "type": "Long",
        "kind": "SCALAR"
      },
      {
        "name": "creationdate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "deleted",
        "type": "Boolean",
        "kind": "SCALAR"
      },
      {
        "name": "descriptorType",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "userId",
        "type": "Long",
        "kind": "SCALAR"
      },
      {
        "name": "status",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "severity",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "parentId",
        "type": "Long",
        "kind": "SCALAR"
      },
      {
        "name": "driverId",
        "type": "Long",
        "kind": "SCALAR"
      },
      {
        "name": "oagId",
        "type": "Long",
        "kind": "SCALAR"
      },
      {
        "name": "generationSource",
        "type": "EventGenerationSourceTypeEnum",
        "kind": "ENUM"
      },
      {
        "name": "flight",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "objectActionInfo",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "observation",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "statusChangedAt",
        "type": "Long",
        "kind": "SCALAR"
      },
      {
        "name": "stopInfo",
        "type": "String",
        "kind": "SCALAR"
      }
    ]
  },
  "MissionType": {
    "name": "MissionType",
    "fields": [
      {
        "name": "id",
        "type": "Long",
        "kind": "SCALAR"
      },
      {
        "name": "customFields",
        "type": "Map_String_ObjectScalar",
        "kind": "SCALAR"
      },
      {
        "name": "version",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "name",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "color",
        "type": "String",
        "kind": "SCALAR"
      }
    ]
  },
  "MissionWorkflowElement": {
    "name": "MissionWorkflowElement",
    "fields": [
      {
        "name": "id",
        "type": "Long",
        "kind": "SCALAR"
      },
      {
        "name": "customFields",
        "type": "Map_String_ObjectScalar",
        "kind": "SCALAR"
      },
      {
        "name": "version",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "name",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "type",
        "type": "MissionWorkflowElementType",
        "kind": "ENUM"
      },
      {
        "name": "label",
        "type": "String",
        "kind": "SCALAR"
      }
    ]
  },
  "MobileDevice": {
    "name": "MobileDevice",
    "fields": [
      {
        "name": "id",
        "type": "Long",
        "kind": "SCALAR"
      },
      {
        "name": "customFields",
        "type": "Map_String_ObjectScalar",
        "kind": "SCALAR"
      },
      {
        "name": "version",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "identifier",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "phoneNumber",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "status",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "lastVersion",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "lastUpdate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "serial",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "equipmentResource",
        "type": "EquipmentResource",
        "kind": "OBJECT"
      },
      {
        "name": "humanResources",
        "type": "HumanResource",
        "kind": "LIST"
      },
      {
        "name": "unit",
        "type": "Unit",
        "kind": "OBJECT"
      },
      {
        "name": "organization",
        "type": "Organization",
        "kind": "OBJECT"
      },
      {
        "name": "lastPointLatitude",
        "type": "Float",
        "kind": "SCALAR"
      },
      {
        "name": "lastPointLongitude",
        "type": "Float",
        "kind": "SCALAR"
      },
      {
        "name": "updated",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "archived",
        "type": "Boolean",
        "kind": "SCALAR"
      },
      {
        "name": "almostUniqueDeviceId",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "requiredEquipmentResource",
        "type": "EquipmentResource",
        "kind": "OBJECT"
      }
    ]
  },
  "MobileDeviceActivationHistory": {
    "name": "MobileDeviceActivationHistory",
    "fields": [
      {
        "name": "id",
        "type": "Long",
        "kind": "SCALAR"
      },
      {
        "name": "customFields",
        "type": "Map_String_ObjectScalar",
        "kind": "SCALAR"
      },
      {
        "name": "version",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "date",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "event",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "humanResource",
        "type": "HumanResource",
        "kind": "OBJECT"
      },
      {
        "name": "mobileDevice",
        "type": "MobileDevice",
        "kind": "OBJECT"
      }
    ]
  },
  "Notification": {
    "name": "Notification",
    "fields": [
      {
        "name": "id",
        "type": "Long",
        "kind": "SCALAR"
      },
      {
        "name": "customFields",
        "type": "Map_String_ObjectScalar",
        "kind": "SCALAR"
      },
      {
        "name": "version",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "creationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "entityName",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "entityId",
        "type": "Long",
        "kind": "SCALAR"
      },
      {
        "name": "code",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "severity",
        "type": "Severity",
        "kind": "ENUM"
      },
      {
        "name": "description",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "organization",
        "type": "Organization",
        "kind": "OBJECT"
      },
      {
        "name": "deletionDate",
        "type": "Date",
        "kind": "SCALAR"
      }
    ]
  },
  "NotificationAndUser": {
    "name": "NotificationAndUser",
    "fields": [
      {
        "name": "id",
        "type": "Long",
        "kind": "SCALAR"
      },
      {
        "name": "customFields",
        "type": "Map_String_ObjectScalar",
        "kind": "SCALAR"
      },
      {
        "name": "version",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "user",
        "type": "User",
        "kind": "OBJECT"
      },
      {
        "name": "notification",
        "type": "Notification",
        "kind": "OBJECT"
      },
      {
        "name": "readDate",
        "type": "Date",
        "kind": "SCALAR"
      }
    ]
  },
  "ObjectAction": {
    "name": "ObjectAction",
    "fields": [
      {
        "name": "id",
        "type": "Long",
        "kind": "SCALAR"
      },
      {
        "name": "customFields",
        "type": "Map_String_ObjectScalar",
        "kind": "SCALAR"
      },
      {
        "name": "version",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "dataString",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "dataVersion",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "stopId",
        "type": "Long",
        "kind": "SCALAR"
      },
      {
        "name": "objectActionGroupId",
        "type": "Long",
        "kind": "SCALAR"
      },
      {
        "name": "type",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "validated",
        "type": "Boolean",
        "kind": "SCALAR"
      },
      {
        "name": "objectActionGroupIdIndex",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "availableEventTypes",
        "type": "String",
        "kind": "LIST"
      },
      {
        "name": "stopIdIndex",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "objectActionGroup",
        "type": "ObjectActionGroup",
        "kind": "OBJECT"
      },
      {
        "name": "stop",
        "type": "Stop2",
        "kind": "OBJECT"
      }
    ]
  },
  "ObjectActionGroup": {
    "name": "ObjectActionGroup",
    "fields": [
      {
        "name": "id",
        "type": "Long",
        "kind": "SCALAR"
      },
      {
        "name": "customFields",
        "type": "Map_String_ObjectScalar",
        "kind": "SCALAR"
      },
      {
        "name": "version",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "dataString",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "dataVersion",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "missionId",
        "type": "Long",
        "kind": "SCALAR"
      },
      {
        "name": "missionSubsetId",
        "type": "Long",
        "kind": "SCALAR"
      },
      {
        "name": "objectId",
        "type": "Long",
        "kind": "SCALAR"
      },
      {
        "name": "objectActionIds",
        "type": "Long",
        "kind": "LIST"
      },
      {
        "name": "ganttStart",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "ganttEnd",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "ganttStartManual",
        "type": "Boolean",
        "kind": "SCALAR"
      },
      {
        "name": "ganttEndManual",
        "type": "Boolean",
        "kind": "SCALAR"
      },
      {
        "name": "missionSubsetIdIndex",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "treated",
        "type": "Boolean",
        "kind": "SCALAR"
      },
      {
        "name": "mission",
        "type": "Mission2",
        "kind": "OBJECT"
      },
      {
        "name": "object",
        "type": "Task",
        "kind": "OBJECT"
      },
      {
        "name": "objectActions",
        "type": "ObjectAction",
        "kind": "LIST"
      }
    ]
  },
  "OilGroup": {
    "name": "OilGroup",
    "fields": [
      {
        "name": "id",
        "type": "Long",
        "kind": "SCALAR"
      },
      {
        "name": "customFields",
        "type": "Map_String_ObjectScalar",
        "kind": "SCALAR"
      },
      {
        "name": "version",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "name",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "description",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "prefix",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "lastBonNumber",
        "type": "Long",
        "kind": "SCALAR"
      }
    ]
  },
  "Organization": {
    "name": "Organization",
    "fields": [
      {
        "name": "id",
        "type": "Long",
        "kind": "SCALAR"
      },
      {
        "name": "customFields",
        "type": "Map_String_ObjectScalar",
        "kind": "SCALAR"
      },
      {
        "name": "version",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "name",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "qualifiedName",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "parent",
        "type": "Organization",
        "kind": "OBJECT"
      },
      {
        "name": "children",
        "type": "Organization",
        "kind": "LIST"
      },
      {
        "name": "airport",
        "type": "Airport",
        "kind": "OBJECT"
      }
    ]
  },
  "PFT": {
    "name": "PFT",
    "fields": [
      {
        "name": "id",
        "type": "Long",
        "kind": "SCALAR"
      },
      {
        "name": "customFields",
        "type": "Map_String_ObjectScalar",
        "kind": "SCALAR"
      },
      {
        "name": "version",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "name",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "jette",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "indexTrieur",
        "type": "Byte",
        "kind": "SCALAR"
      },
      {
        "name": "sideTrieur",
        "type": "Byte",
        "kind": "SCALAR"
      },
      {
        "name": "trieur",
        "type": "Address",
        "kind": "OBJECT"
      }
    ]
  },
  "Perspective": {
    "name": "Perspective",
    "fields": [
      {
        "name": "id",
        "type": "Long",
        "kind": "SCALAR"
      },
      {
        "name": "customFields",
        "type": "Map_String_ObjectScalar",
        "kind": "SCALAR"
      },
      {
        "name": "version",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "myXml",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "name",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "userId",
        "type": "Long",
        "kind": "SCALAR"
      },
      {
        "name": "isGlobal",
        "type": "Boolean",
        "kind": "SCALAR"
      },
      {
        "name": "scaleFactor",
        "type": "Float",
        "kind": "SCALAR"
      }
    ]
  },
  "Pit": {
    "name": "Pit",
    "fields": [
      {
        "name": "id",
        "type": "Long",
        "kind": "SCALAR"
      },
      {
        "name": "customFields",
        "type": "Map_String_ObjectScalar",
        "kind": "SCALAR"
      },
      {
        "name": "version",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "name",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "description",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "address",
        "type": "Address",
        "kind": "OBJECT"
      }
    ]
  },
  "PlaneType": {
    "name": "PlaneType",
    "fields": [
      {
        "name": "id",
        "type": "Long",
        "kind": "SCALAR"
      },
      {
        "name": "customFields",
        "type": "Map_String_ObjectScalar",
        "kind": "SCALAR"
      },
      {
        "name": "version",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "name",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "flightType",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "observations",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "description",
        "type": "String",
        "kind": "SCALAR"
      }
    ]
  },
  "Position": {
    "name": "Position",
    "fields": [
      {
        "name": "id",
        "type": "Long",
        "kind": "SCALAR"
      },
      {
        "name": "customFields",
        "type": "Map_String_ObjectScalar",
        "kind": "SCALAR"
      },
      {
        "name": "version",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "gpsProvider",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "date",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "dateReceived",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "dateProcessed",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "plateNumber",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "longitude",
        "type": "Float",
        "kind": "SCALAR"
      },
      {
        "name": "latitude",
        "type": "Float",
        "kind": "SCALAR"
      },
      {
        "name": "accuracy",
        "type": "Float",
        "kind": "SCALAR"
      },
      {
        "name": "speed",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "engineState",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "odometer",
        "type": "Float",
        "kind": "SCALAR"
      },
      {
        "name": "motorHour",
        "type": "Float",
        "kind": "SCALAR"
      },
      {
        "name": "telemetry",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "hasTelemetry",
        "type": "Boolean",
        "kind": "SCALAR"
      }
    ]
  },
  "Problem": {
    "name": "Problem",
    "fields": [
      {
        "name": "id",
        "type": "Long",
        "kind": "SCALAR"
      },
      {
        "name": "customFields",
        "type": "Map_String_ObjectScalar",
        "kind": "SCALAR"
      },
      {
        "name": "version",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "creationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "entityName",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "entityId",
        "type": "Long",
        "kind": "SCALAR"
      },
      {
        "name": "code",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "severity",
        "type": "Severity",
        "kind": "ENUM"
      },
      {
        "name": "description",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "organization",
        "type": "Organization",
        "kind": "OBJECT"
      }
    ]
  },
  "Puck": {
    "name": "Puck",
    "fields": [
      {
        "name": "id",
        "type": "Long",
        "kind": "SCALAR"
      },
      {
        "name": "customFields",
        "type": "Map_String_ObjectScalar",
        "kind": "SCALAR"
      },
      {
        "name": "version",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "name",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "territory",
        "type": "Territory",
        "kind": "OBJECT"
      }
    ]
  },
  "Qualification": {
    "name": "Qualification",
    "fields": [
      {
        "name": "id",
        "type": "Long",
        "kind": "SCALAR"
      },
      {
        "name": "customFields",
        "type": "Map_String_ObjectScalar",
        "kind": "SCALAR"
      },
      {
        "name": "version",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "qualificationType",
        "type": "QualificationType",
        "kind": "OBJECT"
      },
      {
        "name": "humanResource",
        "type": "HumanResource",
        "kind": "OBJECT"
      },
      {
        "name": "startDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "endDate",
        "type": "Date",
        "kind": "SCALAR"
      }
    ]
  },
  "QualificationType": {
    "name": "QualificationType",
    "fields": [
      {
        "name": "id",
        "type": "Long",
        "kind": "SCALAR"
      },
      {
        "name": "customFields",
        "type": "Map_String_ObjectScalar",
        "kind": "SCALAR"
      },
      {
        "name": "version",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "name",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "description",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "airline",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "unit",
        "type": "Unit",
        "kind": "OBJECT"
      },
      {
        "name": "planeType",
        "type": "PlaneType",
        "kind": "OBJECT"
      },
      {
        "name": "planeTypeGroup",
        "type": "ValuesGroup",
        "kind": "OBJECT"
      },
      {
        "name": "customBadge",
        "type": "Boolean",
        "kind": "SCALAR"
      },
      {
        "name": "organization",
        "type": "Organization",
        "kind": "OBJECT"
      }
    ]
  },
  "Radio": {
    "name": "Radio",
    "fields": [
      {
        "name": "id",
        "type": "Long",
        "kind": "SCALAR"
      },
      {
        "name": "customFields",
        "type": "Map_String_ObjectScalar",
        "kind": "SCALAR"
      },
      {
        "name": "version",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "identifier",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "humanResources",
        "type": "HumanResource",
        "kind": "LIST"
      }
    ]
  },
  "RecipientList": {
    "name": "RecipientList",
    "fields": [
      {
        "name": "id",
        "type": "Long",
        "kind": "SCALAR"
      },
      {
        "name": "customFields",
        "type": "Map_String_ObjectScalar",
        "kind": "SCALAR"
      },
      {
        "name": "version",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "name",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "recipientListToUsers",
        "type": "RecipientListToUser",
        "kind": "LIST"
      },
      {
        "name": "additionalEmailAddresses",
        "type": "String",
        "kind": "SCALAR"
      }
    ]
  },
  "RecipientListToUser": {
    "name": "RecipientListToUser",
    "fields": [
      {
        "name": "id",
        "type": "Long",
        "kind": "SCALAR"
      },
      {
        "name": "customFields",
        "type": "Map_String_ObjectScalar",
        "kind": "SCALAR"
      },
      {
        "name": "version",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "recipientList",
        "type": "RecipientList",
        "kind": "OBJECT"
      },
      {
        "name": "user",
        "type": "User",
        "kind": "OBJECT"
      }
    ]
  },
  "RequiredEquipment": {
    "name": "RequiredEquipment",
    "fields": [
      {
        "name": "id",
        "type": "Long",
        "kind": "SCALAR"
      },
      {
        "name": "customFields",
        "type": "Map_String_ObjectScalar",
        "kind": "SCALAR"
      },
      {
        "name": "version",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "requiredNumber",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "taskType",
        "type": "TaskType",
        "kind": "OBJECT"
      },
      {
        "name": "equipmentType",
        "type": "EquipmentType",
        "kind": "OBJECT"
      }
    ]
  },
  "RequiredQualification": {
    "name": "RequiredQualification",
    "fields": [
      {
        "name": "id",
        "type": "Long",
        "kind": "SCALAR"
      },
      {
        "name": "customFields",
        "type": "Map_String_ObjectScalar",
        "kind": "SCALAR"
      },
      {
        "name": "version",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "requiredNumber",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "taskType",
        "type": "TaskType",
        "kind": "OBJECT"
      },
      {
        "name": "qualificationType",
        "type": "QualificationType",
        "kind": "OBJECT"
      }
    ]
  },
  "Revision": {
    "name": "Revision",
    "fields": [
      {
        "name": "id",
        "type": "Long",
        "kind": "SCALAR"
      },
      {
        "name": "customFields",
        "type": "Map_String_ObjectScalar",
        "kind": "SCALAR"
      },
      {
        "name": "version",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "date",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "plannedDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "equipmentResource",
        "type": "EquipmentResource",
        "kind": "OBJECT"
      },
      {
        "name": "revisionType",
        "type": "RevisionType",
        "kind": "OBJECT"
      }
    ]
  },
  "RevisionType": {
    "name": "RevisionType",
    "fields": [
      {
        "name": "id",
        "type": "Long",
        "kind": "SCALAR"
      },
      {
        "name": "customFields",
        "type": "Map_String_ObjectScalar",
        "kind": "SCALAR"
      },
      {
        "name": "version",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "name",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "description",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "runningHours",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "revisionInterval",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "equipmentType",
        "type": "EquipmentType",
        "kind": "OBJECT"
      }
    ]
  },
  "Role": {
    "name": "Role",
    "fields": [
      {
        "name": "id",
        "type": "Long",
        "kind": "SCALAR"
      },
      {
        "name": "customFields",
        "type": "Map_String_ObjectScalar",
        "kind": "SCALAR"
      },
      {
        "name": "version",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "name",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "permissions",
        "type": "String",
        "kind": "SCALAR"
      }
    ]
  },
  "SettingsEntity": {
    "name": "SettingsEntity",
    "fields": [
      {
        "name": "id",
        "type": "Long",
        "kind": "SCALAR"
      },
      {
        "name": "customFields",
        "type": "Map_String_ObjectScalar",
        "kind": "SCALAR"
      },
      {
        "name": "version",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "rootType",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "json",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "organization",
        "type": "Organization",
        "kind": "OBJECT"
      },
      {
        "name": "enabled",
        "type": "Boolean",
        "kind": "SCALAR"
      },
      {
        "name": "importance",
        "type": "Int",
        "kind": "SCALAR"
      }
    ]
  },
  "Stop2": {
    "name": "Stop2",
    "fields": [
      {
        "name": "id",
        "type": "Long",
        "kind": "SCALAR"
      },
      {
        "name": "customFields",
        "type": "Map_String_ObjectScalar",
        "kind": "SCALAR"
      },
      {
        "name": "version",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "dataString",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "dataVersion",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "missionId",
        "type": "Long",
        "kind": "SCALAR"
      },
      {
        "name": "missionIdIndex",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "tripStartTime",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "tripDuration",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "stopDuration",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "initialTripStartTime",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "initialTripDuration",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "initialStopDuration",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "tripEndTimeMin",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "tripEndTimeMax",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "capacity1",
        "type": "Float",
        "kind": "SCALAR"
      },
      {
        "name": "capacity2",
        "type": "Float",
        "kind": "SCALAR"
      },
      {
        "name": "status",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "tripStartTimeManual",
        "type": "Boolean",
        "kind": "SCALAR"
      },
      {
        "name": "nbTurns",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "availableEventTypes",
        "type": "String",
        "kind": "LIST"
      },
      {
        "name": "mission",
        "type": "Mission2",
        "kind": "OBJECT"
      },
      {
        "name": "address",
        "type": "Address",
        "kind": "OBJECT"
      },
      {
        "name": "objectActions",
        "type": "ObjectAction",
        "kind": "LIST"
      },
      {
        "name": "type",
        "type": "StopType",
        "kind": "OBJECT"
      }
    ]
  },
  "StopType": {
    "name": "StopType",
    "fields": [
      {
        "name": "id",
        "type": "Long",
        "kind": "SCALAR"
      },
      {
        "name": "customFields",
        "type": "Map_String_ObjectScalar",
        "kind": "SCALAR"
      },
      {
        "name": "version",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "name",
        "type": "String",
        "kind": "SCALAR"
      }
    ]
  },
  "Tag": {
    "name": "Tag",
    "fields": [
      {
        "name": "id",
        "type": "Long",
        "kind": "SCALAR"
      },
      {
        "name": "customFields",
        "type": "Map_String_ObjectScalar",
        "kind": "SCALAR"
      },
      {
        "name": "version",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "name",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "color",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "colorRegistryColor",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "description",
        "type": "String",
        "kind": "SCALAR"
      }
    ]
  },
  "Task": {
    "name": "Task",
    "fields": [
      {
        "name": "id",
        "type": "Long",
        "kind": "SCALAR"
      },
      {
        "name": "customFields",
        "type": "Map_String_ObjectScalar",
        "kind": "SCALAR"
      },
      {
        "name": "version",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "dataString",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "dataVersion",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "name",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "flightId",
        "type": "Long",
        "kind": "SCALAR"
      },
      {
        "name": "showInGME",
        "type": "Boolean",
        "kind": "SCALAR"
      },
      {
        "name": "taskGroupData",
        "type": "Map_String_ObjectScalar",
        "kind": "SCALAR"
      },
      {
        "name": "comment",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "taskParameter",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "priority",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "debarqued",
        "type": "Boolean",
        "kind": "SCALAR"
      },
      {
        "name": "day",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "taskGroup",
        "type": "Flight",
        "kind": "OBJECT"
      },
      {
        "name": "startAddress",
        "type": "Address",
        "kind": "OBJECT"
      },
      {
        "name": "endAddress",
        "type": "Address",
        "kind": "OBJECT"
      },
      {
        "name": "taskType",
        "type": "TaskType",
        "kind": "OBJECT"
      },
      {
        "name": "startAddresses",
        "type": "Address",
        "kind": "LIST"
      },
      {
        "name": "endAddresses",
        "type": "Address",
        "kind": "LIST"
      },
      {
        "name": "missionType",
        "type": "MissionType",
        "kind": "OBJECT"
      },
      {
        "name": "currentState",
        "type": "MissionWorkflowElement",
        "kind": "OBJECT"
      },
      {
        "name": "connectionFlight",
        "type": "Flight",
        "kind": "OBJECT"
      },
      {
        "name": "startTime",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "endTime",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "quantity",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "leftOverlap",
        "type": "Boolean",
        "kind": "SCALAR"
      },
      {
        "name": "rightOverlap",
        "type": "Boolean",
        "kind": "SCALAR"
      },
      {
        "name": "position",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "weight",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "particularity",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "processedBaggages",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "connectionFlights",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "uldStatus",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "uldTime",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "shcCode",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "transferObjectName",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "galley",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "approachNumber",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "messageCreation",
        "type": "Boolean",
        "kind": "SCALAR"
      },
      {
        "name": "vraIndex",
        "type": "Float",
        "kind": "SCALAR"
      },
      {
        "name": "exceptionEvent",
        "type": "Boolean",
        "kind": "SCALAR"
      },
      {
        "name": "finishMissionEvent",
        "type": "Boolean",
        "kind": "SCALAR"
      },
      {
        "name": "eventMonitoringRulesJson",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "eventMonitoringExpectedNumberOfEvents",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "eventMonitoringActualNumberOfEvents",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "eventMonitoringAutoNumberOfEvents",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "requiredEquipmentResourceQualificationType",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "equipmentResourceFillPercentage",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "eventMonitoringStatus",
        "type": "EventMonitoringStatusEnum",
        "kind": "ENUM"
      },
      {
        "name": "extraDemand",
        "type": "Boolean",
        "kind": "SCALAR"
      },
      {
        "name": "extraDemandValidated",
        "type": "Boolean",
        "kind": "SCALAR"
      },
      {
        "name": "pft",
        "type": "PFT",
        "kind": "OBJECT"
      },
      {
        "name": "emrjUseRotationFlightAsReference",
        "type": "Boolean",
        "kind": "SCALAR"
      },
      {
        "name": "loadMode",
        "type": "LoadModeEnum",
        "kind": "ENUM"
      },
      {
        "name": "connectionFlightId",
        "type": "Long",
        "kind": "SCALAR"
      },
      {
        "name": "archived",
        "type": "Boolean",
        "kind": "SCALAR"
      },
      {
        "name": "offset",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "duration",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "lockedFields",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "mission",
        "type": "Mission2",
        "kind": "OBJECT"
      },
      {
        "name": "actualStartTime",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "actualEndTime",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "actualStartTimeFixed",
        "type": "Boolean",
        "kind": "SCALAR"
      },
      {
        "name": "actualEndTimeFixed",
        "type": "Boolean",
        "kind": "SCALAR"
      },
      {
        "name": "baggagesLimitControled",
        "type": "Boolean",
        "kind": "SCALAR"
      },
      {
        "name": "createdBy",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "createdByEventId",
        "type": "Long",
        "kind": "SCALAR"
      },
      {
        "name": "crewBaggageVersion",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "endAddressIsTemporaryStorage",
        "type": "Boolean",
        "kind": "SCALAR"
      },
      {
        "name": "flightDateUpdatedAt",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "hasSpecialColor",
        "type": "Boolean",
        "kind": "SCALAR"
      },
      {
        "name": "lastAgentIdentifier",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "lastScanDate",
        "type": "Long",
        "kind": "SCALAR"
      },
      {
        "name": "lastScannerIdentifier",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "offloadStatus",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "offsetForRotationFlight",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "resetCitePNBaggages",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "sentBaggageUpdate",
        "type": "Boolean",
        "kind": "SCALAR"
      },
      {
        "name": "startAddressIsTemporaryStorage",
        "type": "Boolean",
        "kind": "SCALAR"
      },
      {
        "name": "temporaryStoragePairObject",
        "type": "Long",
        "kind": "SCALAR"
      }
    ]
  },
  "TaskType": {
    "name": "TaskType",
    "fields": [
      {
        "name": "id",
        "type": "Long",
        "kind": "SCALAR"
      },
      {
        "name": "customFields",
        "type": "Map_String_ObjectScalar",
        "kind": "SCALAR"
      },
      {
        "name": "version",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "name",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "color",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "createAtFlightIntegration",
        "type": "Boolean",
        "kind": "SCALAR"
      },
      {
        "name": "canBeSplit",
        "type": "Boolean",
        "kind": "SCALAR"
      },
      {
        "name": "ocupiedPositions",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "noTaskGroup",
        "type": "Boolean",
        "kind": "SCALAR"
      },
      {
        "name": "untreatable",
        "type": "Boolean",
        "kind": "SCALAR"
      },
      {
        "name": "isSpecial",
        "type": "Boolean",
        "kind": "SCALAR"
      },
      {
        "name": "showRotationInfo",
        "type": "Boolean",
        "kind": "SCALAR"
      },
      {
        "name": "blockMissionTime",
        "type": "Boolean",
        "kind": "SCALAR"
      },
      {
        "name": "requiredEquipments",
        "type": "RequiredEquipment",
        "kind": "LIST"
      },
      {
        "name": "requiredQualifications",
        "type": "RequiredQualification",
        "kind": "LIST"
      },
      {
        "name": "actionTypes",
        "type": "ActionType",
        "kind": "LIST"
      }
    ]
  },
  "TelemetryEventMapping": {
    "name": "TelemetryEventMapping",
    "fields": [
      {
        "name": "id",
        "type": "Long",
        "kind": "SCALAR"
      },
      {
        "name": "customFields",
        "type": "Map_String_ObjectScalar",
        "kind": "SCALAR"
      },
      {
        "name": "version",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "telemetrySystem",
        "type": "TelemetrySystem",
        "kind": "OBJECT"
      },
      {
        "name": "mappingFrom",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "mappingTo",
        "type": "Short",
        "kind": "SCALAR"
      }
    ]
  },
  "TelemetrySystem": {
    "name": "TelemetrySystem",
    "fields": [
      {
        "name": "id",
        "type": "Long",
        "kind": "SCALAR"
      },
      {
        "name": "customFields",
        "type": "Map_String_ObjectScalar",
        "kind": "SCALAR"
      },
      {
        "name": "version",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "name",
        "type": "String",
        "kind": "SCALAR"
      }
    ]
  },
  "Territory": {
    "name": "Territory",
    "fields": [
      {
        "name": "id",
        "type": "Long",
        "kind": "SCALAR"
      },
      {
        "name": "customFields",
        "type": "Map_String_ObjectScalar",
        "kind": "SCALAR"
      },
      {
        "name": "version",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "name",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "description",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "color",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "tags",
        "type": "EntityToTag",
        "kind": "LIST"
      },
      {
        "name": "coordinates",
        "type": null,
        "kind": "LIST"
      },
      {
        "name": "organization",
        "type": "Organization",
        "kind": "OBJECT"
      }
    ]
  },
  "Typology": {
    "name": "Typology",
    "fields": [
      {
        "name": "id",
        "type": "Long",
        "kind": "SCALAR"
      },
      {
        "name": "customFields",
        "type": "Map_String_ObjectScalar",
        "kind": "SCALAR"
      },
      {
        "name": "version",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "name",
        "type": "String",
        "kind": "SCALAR"
      }
    ]
  },
  "Unit": {
    "name": "Unit",
    "fields": [
      {
        "name": "id",
        "type": "Long",
        "kind": "SCALAR"
      },
      {
        "name": "customFields",
        "type": "Map_String_ObjectScalar",
        "kind": "SCALAR"
      },
      {
        "name": "version",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "dataString",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "dataVersion",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "name",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "color",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "airport",
        "type": "Airport",
        "kind": "OBJECT"
      },
      {
        "name": "location",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "territoryName",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "telephone",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "email",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "tagsForResources",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "label",
        "type": "String",
        "kind": "SCALAR"
      }
    ]
  },
  "User": {
    "name": "User",
    "fields": [
      {
        "name": "id",
        "type": "Long",
        "kind": "SCALAR"
      },
      {
        "name": "customFields",
        "type": "Map_String_ObjectScalar",
        "kind": "SCALAR"
      },
      {
        "name": "version",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "username",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "firstName",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "lastName",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "email",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "language",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "resetKey",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "resetDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "passwordChangedDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "passwordExpires",
        "type": "Boolean",
        "kind": "SCALAR"
      },
      {
        "name": "failedLoginAttempts",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "isLocked",
        "type": "Boolean",
        "kind": "SCALAR"
      },
      {
        "name": "lastLoginDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "creationDate",
        "type": "Date",
        "kind": "SCALAR"
      },
      {
        "name": "userToOrganizations",
        "type": "UserToOrganization",
        "kind": "LIST"
      },
      {
        "name": "isAdmin",
        "type": "Boolean",
        "kind": "SCALAR"
      },
      {
        "name": "newPassword",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "lastOrganization",
        "type": "Organization",
        "kind": "OBJECT"
      },
      {
        "name": "archived",
        "type": "Boolean",
        "kind": "SCALAR"
      },
      {
        "name": "organization",
        "type": "Organization",
        "kind": "OBJECT"
      },
      {
        "name": "screen",
        "type": "Perspective",
        "kind": "OBJECT"
      },
      {
        "name": "screenposition",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "pricesAvailable",
        "type": "Boolean",
        "kind": "SCALAR"
      },
      {
        "name": "showDoneTasksInFlightGantt",
        "type": "Boolean",
        "kind": "SCALAR"
      },
      {
        "name": "showDoneTasksInMissionGantt",
        "type": "Boolean",
        "kind": "SCALAR"
      },
      {
        "name": "unit",
        "type": "Unit",
        "kind": "OBJECT"
      },
      {
        "name": "initialConfiguration",
        "type": "User",
        "kind": "OBJECT"
      },
      {
        "name": "units",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "roles",
        "type": "XopsRole",
        "kind": "LIST"
      }
    ]
  },
  "UserToOrganization": {
    "name": "UserToOrganization",
    "fields": [
      {
        "name": "id",
        "type": "Long",
        "kind": "SCALAR"
      },
      {
        "name": "customFields",
        "type": "Map_String_ObjectScalar",
        "kind": "SCALAR"
      },
      {
        "name": "version",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "user",
        "type": "User",
        "kind": "OBJECT"
      },
      {
        "name": "organization",
        "type": "Organization",
        "kind": "OBJECT"
      },
      {
        "name": "role",
        "type": "Role",
        "kind": "OBJECT"
      }
    ]
  },
  "ValidationMessage": {
    "name": "ValidationMessage",
    "fields": [
      {
        "name": "id",
        "type": "Long",
        "kind": "SCALAR"
      },
      {
        "name": "customFields",
        "type": "Map_String_ObjectScalar",
        "kind": "SCALAR"
      },
      {
        "name": "version",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "missionId",
        "type": "Long",
        "kind": "SCALAR"
      },
      {
        "name": "type",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "message",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "messageParameters",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "otherMissionId",
        "type": "Long",
        "kind": "SCALAR"
      },
      {
        "name": "creationdate",
        "type": "Date",
        "kind": "SCALAR"
      }
    ]
  },
  "ValuesGroup": {
    "name": "ValuesGroup",
    "fields": [
      {
        "name": "id",
        "type": "Long",
        "kind": "SCALAR"
      },
      {
        "name": "customFields",
        "type": "Map_String_ObjectScalar",
        "kind": "SCALAR"
      },
      {
        "name": "version",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "name",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "type",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "values",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "unit",
        "type": "Unit",
        "kind": "OBJECT"
      },
      {
        "name": "organization",
        "type": "Organization",
        "kind": "OBJECT"
      }
    ]
  },
  "XopsColor": {
    "name": "XopsColor",
    "fields": [
      {
        "name": "id",
        "type": "Long",
        "kind": "SCALAR"
      },
      {
        "name": "customFields",
        "type": "Map_String_ObjectScalar",
        "kind": "SCALAR"
      },
      {
        "name": "version",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "name",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "colorValue",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "active",
        "type": "Boolean",
        "kind": "SCALAR"
      },
      {
        "name": "description",
        "type": "String",
        "kind": "SCALAR"
      }
    ]
  },
  "XopsRole": {
    "name": "XopsRole",
    "fields": [
      {
        "name": "id",
        "type": "Long",
        "kind": "SCALAR"
      },
      {
        "name": "customFields",
        "type": "Map_String_ObjectScalar",
        "kind": "SCALAR"
      },
      {
        "name": "version",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "name",
        "type": "String",
        "kind": "SCALAR"
      }
    ]
  },
  "XopsUserSettings": {
    "name": "XopsUserSettings",
    "fields": [
      {
        "name": "id",
        "type": "Long",
        "kind": "SCALAR"
      },
      {
        "name": "customFields",
        "type": "Map_String_ObjectScalar",
        "kind": "SCALAR"
      },
      {
        "name": "version",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "table",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "code",
        "type": "String",
        "kind": "SCALAR"
      },
      {
        "name": "user",
        "type": "User",
        "kind": "OBJECT"
      }
    ]
  },
  "Zone": {
    "name": "Zone",
    "fields": [
      {
        "name": "id",
        "type": "Long",
        "kind": "SCALAR"
      },
      {
        "name": "customFields",
        "type": "Map_String_ObjectScalar",
        "kind": "SCALAR"
      },
      {
        "name": "version",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "name",
        "type": "String",
        "kind": "SCALAR"
      }
    ]
  },
  "ZoneDistance": {
    "name": "ZoneDistance",
    "fields": [
      {
        "name": "id",
        "type": "Long",
        "kind": "SCALAR"
      },
      {
        "name": "customFields",
        "type": "Map_String_ObjectScalar",
        "kind": "SCALAR"
      },
      {
        "name": "version",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "distanceInMinutes",
        "type": "Int",
        "kind": "SCALAR"
      },
      {
        "name": "compatibility",
        "type": "Boolean",
        "kind": "SCALAR"
      },
      {
        "name": "zone1",
        "type": "Zone",
        "kind": "OBJECT"
      },
      {
        "name": "zone2",
        "type": "Zone",
        "kind": "OBJECT"
      },
      {
        "name": "trieur",
        "type": "Address",
        "kind": "OBJECT"
      }
    ]
  }
}