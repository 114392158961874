import { FilterOperators } from "@crispico/foundation-gwt-js";
import { EntityDescriptor, FieldDescriptor, SliceEntityTablePage, createSliceFoundation, getBaseImpures, getBaseReducers, sliceEntityTablePageOnlyForExtension } from "@crispico/foundation-react";
import { Filter } from "@crispico/foundation-react/components/CustomQuery/Filter";
import { ShareLinkLogic } from "@crispico/foundation-react/entity_crud/ShareLinkLogic";
import { ID, entityDescriptors } from "@crispico/foundation-react/entity_crud/entityCrudConstants";
import { NavLink } from "react-router-dom";

export class DiagnosticTroubleCodeEntityDescriptor extends EntityDescriptor {
    constructor() {
        super({
            name: "DiagnosticTroubleCode",
            miniFields: ["equipmentResource.identifier", "code"],
            icon: "car",
            defaultFilter: Filter.createComposedForClient(FilterOperators.forComposedFilter.or, [Filter.createForClient("timestamp", FilterOperators.forDate.lessThanHoursAgo, "1")]),
            defaultSort: { field: "timestamp", direction: "DESC" }
        });
    }

    protected customize() {

        const diagnosticTroubleCodeEntityDescriptor = this;
        const sliceDiagnosticTroubleCodeTable = diagnosticTroubleCodeEntityDescriptor.infoTable.slice = createSliceFoundation(class Ext extends SliceEntityTablePage {

            initialState = {
                ...sliceEntityTablePageOnlyForExtension.initialState,
            }
        
            nestedSlices = {
                ...sliceEntityTablePageOnlyForExtension.nestedSlices
            }
        
            reducers = {
                ...sliceEntityTablePageOnlyForExtension.reducers, ...getBaseReducers<Ext>(this)
            }
        
            impures = {
                ...sliceEntityTablePageOnlyForExtension.impures, ...getBaseImpures<Ext>(this),
                
                getFieldsToRequestSuper: sliceEntityTablePageOnlyForExtension.impures.getFieldsToRequest,
                getFieldsToRequest(fieldsToRequest: string[]) {
                    return this.getFieldsToRequestSuper(fieldsToRequest) + " timestamp equipmentResource { plateNumber } ";
                },
            }
        }).setEntityDescriptor(diagnosticTroubleCodeEntityDescriptor);

        (this.infoTable.slice as SliceEntityTablePage).setOptions({ countMode: undefined });
    }
}

export class GoToPositionFieldDescriptor extends FieldDescriptor {
    constructor() {
        super();
        this.clientOnly = true;
        this.filterable = false; 
        this.sortable = false;
    }

    getAppearsInUi() {
        return true;
    }
    
    renderField(entity: any) {
        const erPlateNumberField = entityDescriptors["EquipmentResource"].getField("plateNumber");
        return <NavLink key={entity[ID]} to={
            new ShareLinkLogic().createLink(false, entityDescriptors["Position"], 
                Filter.enableAllFilters(Filter.createComposed(FilterOperators.forComposedFilter.and, [
                    Filter.create("plateNumber", FilterOperators.forString.equals, erPlateNumberField.getFieldValue(entity["equipmentResource"])),
                    Filter.create("date", FilterOperators.forDate.between, entity["timestamp"] + ' AND ' + entity["timestamp"])]))
            )}
        >{_msg("DiagnosticTroubleCode.goToPosition")}</NavLink>
    }
}

