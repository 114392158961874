import { apolloClientHolder, createSliceFoundation, EntityDescriptor, getBaseImpures, getBaseReducers, PropsFrom, Utils } from "@crispico/foundation-react";

//@ts-ignore
import React from "react";
import { Button, Container, Segment, List, Image, Modal, Dimmer, Loader, Label, Icon, Divider } from "semantic-ui-react";
import { MessageExt } from "@crispico/foundation-react/components/semanticUiReactExt";
import gql from "graphql-tag";
import { SplitPaneExt } from "@crispico/foundation-react/components/ReactSplitPaneExt/ReactSplitPaneExt";
import { ModalExt } from "@crispico/foundation-react/components/ModalExt/ModalExt";
import { MapSettings } from "app";
import { MapContainerLeafletRRC, MapContainerLeaflet, MarkerData, MARKER_TYPE } from "components/MapContainerLeaflet/MapContainerLeaflet";
import { LiveVideo } from "components/LiveVideo/LiveVideo";
import { entityDescriptors } from "@crispico/foundation-react/entity_crud/entityCrudConstants";
import { NavLink } from "react-router-dom";
import { GalleryMedia } from "components/LiveVideo/GalleryMedia";
import { PeriodPickerRRC } from "@crispico/foundation-react/components/periodPicker/PeriodPicker";
import { AppMetaTempGlobals } from "@crispico/foundation-react/AppMetaTempGlobals";
import { VIDEO_ALLOW_VIEW_BLURRED, VIDEO_ALLOW_VIEW_ORIGINAL } from "app";

var moment = require("moment");

export type Video = {
    date: Date,
    thumbnailPath: string,
    videoPath: string
    eventType: string,
    cameraChannel: string,
    entityId: string,
    longitude: number,
    latitude: number,
    duration: number,
}

export const VIDEO_TYPE = "video";

export const FILES_PATH = Utils.adjustUrlToServerContext("videos");
export const sliceVideo = createSliceFoundation(class SliceVideo {

    initialState = {
        videos: undefined as unknown as { [key: string]: Video[]; },
        showVideo: false,
        loading: true,
        currentEntityId: undefined as unknown as string,
        playBlurredVideo: false,
        playVideoModalOpen: false
    }

    reducers = {
        ...getBaseReducers<SliceVideo>(this),
    }

    impures = {
        ...getBaseImpures<SliceVideo>(this),

        onRangePickerChange(infos: any) {
            this.getDispatchers().setInReduxState({ videos: undefined, loading: true, currentEntityId: undefined });
            this.getVideos(Number.parseFloat(infos[2].valueOf()), infos[0].valueOf(), infos[1].valueOf(), infos[3].valueOf());
        },

        async getVideos(equipmentResourceId: any, startDate: any, endDate: any, mapContainer: MapContainerLeaflet) {
            const query = gql(`query getVideos($equipmentResourceId: Long!, $startDate: Date, $endDate: Date){
            smartwitnessService_media(
                equipmentResourceId: $equipmentResourceId
                startDate: $startDate
                endDate: $endDate
            ) {date, thumbnailPath, videoPath, eventType, cameraChannel, entityId, latitude, longitude, duration}
          } `);

            mapContainer.clearMap();
            const result: Video[] = await (await (await apolloClientHolder.apolloClient.query({ query, variables: { equipmentResourceId, startDate, endDate } }))).data.smartwitnessService_media;
            if (!result || result.length == 0) {
                this.getDispatchers().setInReduxState({ videos: undefined, loading: false, currentEntityId: undefined });
                return;
            }

            const groupedVideos = {} as { [key: string]: Video[]; }
            result.forEach((video: Video) => {
                video.thumbnailPath += "?blurred=" + (this.getState().playBlurredVideo ? "true" : "false");
                groupedVideos[video.entityId] = result.filter(video1 => video.entityId == video1.entityId)
            })
            this.getDispatchers().setInReduxState({ videos: groupedVideos, loading: false, currentEntityId: undefined });

            let data: MarkerData[] = [];
            Object.entries(groupedVideos).forEach(([key, videos]) => {
                let video = videos[0];
                if (video.longitude !== undefined && video.latitude !== undefined &&
                    video.longitude !== null && video.latitude !== null) {
                    data.push({ id: video.entityId, point: { longitude: video.longitude, latitude: video.latitude }, text: (entityDescriptors["EquipmentResource"].getField(video.eventType).getLabel() || video.eventType) + " " + moment(video.date).format(Utils.dateTimeWithSecFormat) || "" });
                }
            })
            mapContainer.addOrUpdateLayers(data, VIDEO_TYPE);
        },

        selectVideoOnMap(currentKey: string, mapContainer: MapContainerLeaflet) {
            const current: Video = this.getState().videos[currentKey][0];
            if (current.latitude && current.longitude) {
                mapContainer.props.r.setInReduxState({ selectedLayer: { id: current.entityId, type: VIDEO_TYPE, additionalInfo: { flyToLayer: true } } });
            }
            this.getDispatchers().setInReduxState({ currentEntityId: current.entityId })
        },
    }

}, true)

export type Props = PropsFrom<typeof sliceVideo> & {
    equipmentResourceId: any,
    mapSettings: MapSettings,
};

export class VideoTab extends React.Component<Props> {

    private mapContainerRef = React.createRef<MapContainerLeaflet>();

    constructor(props: Props) {
        super(props);
        this.renderMarkerIcon = this.renderMarkerIcon.bind(this)
        this.renderTooltipContent = this.renderTooltipContent.bind(this);
    }

    componentDidMount(): void {
        this.props.dispatchers.setInReduxState({ playBlurredVideo: !AppMetaTempGlobals.appMetaInstance.hasPermission(VIDEO_ALLOW_VIEW_ORIGINAL) });
    }

    componentWillUnmount() {
        this.props.dispatchers.setInReduxState({ videos: undefined, currentEntityId: undefined });
    }

    renderMarkerIcon(markerData: MarkerData): React.ReactNode {
        return <span className='fa fa-stack fa-lg'><i className={'fa-solid fa-video-camera fa-stack-1x'} ></i></span>
    }

    renderTooltipContent(markerData: MarkerData): React.ReactElement {
        return <div className="flex-container">{markerData.text}</div>;
    }

    onSelectedLayerChanged() {
        let id = this.mapContainerRef.current!.props.s.selectedLayer?.id;
        if (id != undefined) {
            document.getElementById(id.toString())?.scrollIntoView();
            this.props.dispatchers.setInReduxState({ currentEntityId: id.toString() });
        }
    }

    render() {
        return <>
            <div className="flex-container flex-center">
                <Segment basic className="flex-container-row flex-center flex-grow-shrink-no-overflow less-padding no-margin" style={{ width: '100%' }}>
                    <MessageExt className="flex-grow">
                        <PeriodPickerRRC id="periodPicker" onChange={(startDate, endDate) => this.props.dispatchers.onRangePickerChange([startDate, endDate, this.props.equipmentResourceId, this.mapContainerRef.current])} />
                        <span className="float-right"><LiveVideo entityId={this.props.equipmentResourceId} /></span>
                    </MessageExt>
                </Segment>
            </div>
            <SplitPaneExt minSize={250} size="50%" >
                <Container className="wh80">
                    <Dimmer active={this.props.loading} ><Loader size="big">{_msg("general.loading")}</Loader></Dimmer>
                    <List className="flex-grow-shrink-no-overflow less-padding no-margin" divided relaxed>
                        {this.props.videos ? Object.entries(this.props.videos).sort((a, b) => {
                            // sort desc by date
                            return a[1][0].date < b[1][0].date ? 1 : a[1][0].date > b[1][0].date ? -1 : 0
                        }).map(([key, videos]) => {
                            // copy videos because of state and sort videos by cameraChannel to show always thumbnail of camera 1 if exists
                            let videosCopy = [...videos]
                            videosCopy.sort((a, b) => a.cameraChannel.localeCompare(b.cameraChannel));
                            let video = videosCopy[0]
                            return (
                                <List.Item id={video.entityId} onClick={() => this.props.dispatchers.selectVideoOnMap(key, this.mapContainerRef.current!)} style={{ background: this.props.currentEntityId === video.entityId ? "lightgrey" : undefined }}>
                                    <VideoElement video={video} onClickPlayButton={() => {
                                        if (
                                            AppMetaTempGlobals.appMetaInstance.hasPermission(VIDEO_ALLOW_VIEW_ORIGINAL) ||
                                            AppMetaTempGlobals.appMetaInstance.hasPermission(VIDEO_ALLOW_VIEW_BLURRED, true)
                                        ) {
                                            if (
                                                AppMetaTempGlobals.appMetaInstance.hasPermission(VIDEO_ALLOW_VIEW_BLURRED) &&
                                                AppMetaTempGlobals.appMetaInstance.hasPermission(VIDEO_ALLOW_VIEW_ORIGINAL)
                                            ) {
                                                this.props.dispatchers.setInReduxState({ playVideoModalOpen: true });
                                            } else {
                                                this.props.dispatchers.setInReduxState({ showVideo: true, playBlurredVideo: AppMetaTempGlobals.appMetaInstance.hasPermission(VIDEO_ALLOW_VIEW_BLURRED) });
                                            }
                                        }
                                    }} />
                                </List.Item>
                            )
                        })
                            : null
                        }
                    </List>

                    <ModalExt open={this.props.playVideoModalOpen} size='mini' onClose={() => { this.props.dispatchers.setInReduxState({ playVideoModalOpen: false }) }} >
                        <Modal.Header as="h3">{_msg("Video.chooseToPlay")}</Modal.Header>
                        <Modal.Content className="wh100">
                            <Button icon="play" circular size="small" content={_msg("Video.playOriginal")} color="green" style={{ transform: 'translate(50%, -10%)' }} onClick={() => { this.props.dispatchers.setInReduxState({ showVideo: true, playBlurredVideo: false, playVideoModalOpen: false }); }}>
                            </Button>
                            <Divider />
                            <Button icon="play" circular size="small" content={_msg("Video.playBlurred")} color="blue" style={{ transform: 'translate(50%, -10%)' }} onClick={() => { this.props.dispatchers.setInReduxState({ showVideo: true, playBlurredVideo: true, playVideoModalOpen: false }); }}>
                            </Button>
                        </Modal.Content>
                    </ModalExt >
                    <ModalExt open={this.props.showVideo} style={{ width: '95%', height: '95%', background: 'black' }} onClose={() => this.props.dispatchers.setInReduxState({ showVideo: false })} >
                        <Modal.Content className="wh100">
                            <GalleryMedia data={processVideosToGalleryImagesData(this.props.videos, this.props.playBlurredVideo, this.props.currentEntityId)} loading={false} video={true}
                                extraInfo={this.props.videos && this.props.currentEntityId ?
                                    entityDescriptors["EquipmentResource"].getField(this.props.videos[this.props.currentEntityId][0].eventType).getLabel() + " " + moment(this.props.videos[this.props.currentEntityId][0].date).format(Utils.dateTimeWithSecFormat)
                                    : undefined} />
                        </Modal.Content>
                    </ModalExt>
                </Container>
                <div className="no-padding-margin flex-container flex-grow-shrink-no-overflow MapRealTime_topParent" >
                    <MapContainerLeafletRRC id={"mapContainerLeafletVideoTab"} ref={this.mapContainerRef}
                        pruneClusterMode={false} saveCenterZoomInStorage={true}
                        renderTooltipContent={this.renderTooltipContent} renderMarkerIcon={this.renderMarkerIcon}
                        bingAPIKey={this.props.mapSettings.bingAPIKey} mapId={"map-videos"}
                        layers={{ [VIDEO_TYPE]: { layerType: MARKER_TYPE } }}
                        onSelectedLayerChanged={() => this.onSelectedLayerChanged()}
                    />
                </div>
            </SplitPaneExt></>

    }
}
export class VideoElement extends React.Component<{ video: Video, onClickPlayButton: () => void }> {
    render() {
        const video = this.props.video;
        return (<>
            <Button icon circular size="medium" style={{ transform: 'translate(-5%, 70%)' }} className="right floated margin"
                onClick={() => this.props.onClickPlayButton()}>
                <Icon name="play" color="blue" />
            </Button>
            {
                AppMetaTempGlobals.appMetaInstance.hasPermission(VIDEO_ALLOW_VIEW_ORIGINAL) || AppMetaTempGlobals.appMetaInstance.hasPermission(VIDEO_ALLOW_VIEW_BLURRED) ? <Image src={FILES_PATH + video.thumbnailPath} size="small" rounded /> : null}
            <List.Content>
                <List.Header>{_msg("general.date")}: {moment(video.date).format(Utils.dateTimeWithSecFormat)}</List.Header>
                <List.Description>{_msg("DrivingEvent.type.label")}: {entityDescriptors["EquipmentResource"].getField(video.eventType).getLabel() || video.eventType}</List.Description>
                <List.Description>{_msg("Smartwitness.location")}: [{video.longitude}, {video.latitude}]</List.Description>
                <List.Description>{_msg("general.duration")}: {moment.duration(video.duration, "seconds").format()} </List.Description>
                <List.Description>
                    <NavLink to={entityDescriptors["DrivingEvent"].getEntityEditorUrl(video.entityId)}>
                        <Label icon size="small">
                            {_msg("Smartwitness.showEvent")}
                            &nbsp;&nbsp;
                            <Icon name="share" />
                        </Label>
                    </NavLink>
                </List.Description>
            </List.Content>
        </>
        )
    }
}

export function processVideosToGalleryImagesData(videos: { [key: string]: Video[]; }, playBlurredVideo: boolean, currentKey?: string): { [key: string]: string; } | undefined {
    if (!videos || !currentKey) {
        return
    }

    const currentVideoGroup = {} as { [key: string]: string; }
    videos[currentKey]?.forEach((video) => {
        currentVideoGroup[video.cameraChannel] = FILES_PATH + video.videoPath + "?blurred=" + (playBlurredVideo ? "true" : "false");
    })

    return currentVideoGroup
}